import { formatInTimeZone } from 'date-fns-tz'
import Pagination from '@ui/Pagination'

export const formatCurrency = (
	amount: number,
	currency: string,
	locale: string,
	precision: number,
): string => {
	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
		maximumFractionDigits: precision,
	})

	return formatter.format(amount / 10 ** precision)
}

export const formatDate = (
	date: string,
	includeTime: boolean,
	timezone = 'utc',
	format: string = undefined,
): string => {
	if (!date) {
		return '-'
	}

	return includeTime
		? formatInTimeZone(
				new Date(date),
				timezone,
				format ? format : 'do MMMM yyyy, HH:mm',
		  )
		: formatInTimeZone(
				new Date(date),
				timezone,
				format ? format : 'do MMMM yyyy',
		  )
}

export const formatDateUsingString = (
	date: string,
	timezone = 'utc',
	format: string,
): string => {
	return formatInTimeZone(new Date(date), timezone, format)
}

export const makePaginator = (): Pagination => {
	return new Pagination(1, {}, 1, 1, '', 25, '', 1, 1, [1])
}

export const debounceTime = 1000
