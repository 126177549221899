import { Component, EventEmitter, Output } from '@angular/core'
import Performer, { AccountRelationship } from '@account/Performer'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import HttpError, { FormErrors } from '@ui/HttpError'
import { FormBuilder, Validators } from '@angular/forms'
import { PerformerService } from '@account/Services/PerformerService'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
	selector: 'create-performer',
	templateUrl: './CreatePerformer.Component.html',
})
export class CreatePerformerComponent extends FormValidationComponent {
	@Output() private readonly performerCreated = new EventEmitter<Performer>()

	public formErrors: FormErrors = {
		first_name: '',
		last_name: '',
		school: '',
		dob: '',
		gender: '',
		account_relationship: '',
	}

	public genderItems: SelectItem[] = [
		{
			value: 'female',
			label: 'Female',
		},
		{
			value: 'male',
			label: 'Male',
		},
		{
			value: 'n/a',
			label: 'Prefer not to say',
		},
	]

	public relationshipItems: SelectItem[] = [
		{
			value: 'parent',
			label: 'Parent',
		},
		{
			value: 'grandparent',
			label: 'Grandparent',
		},
		{
			value: 'guardian',
			label: 'Guardian',
		},
		{
			value: 'other',
			label: 'Other',
		},
	]

	protected validationMessages: ValidationMessages = {
		first_name: {
			required: 'First name is required',
		},
		last_name: {
			required: 'Last name is required',
		},
		school: {
			required: 'School is required',
		},
		dob: {
			required: 'Date of birth is required',
		},
		gender: {
			required: 'Gender is required',
		},
		tos_accepted: {},
		account_relationship: {
			required: 'Relationship with performer is required',
		},
	}

	constructor(
		public systemConfigService: SystemConfigService,
		protected formBuilder: FormBuilder,
		private readonly performerService: PerformerService,
		private readonly snackbar: MatSnackBar,
	) {
		super(formBuilder)
	}

	public createPerformer = async (
		firstName: InputValue,
		lastName: InputValue,
		school: InputValue,
		dob: Date,
		gender: SelectValue,
		tos_accepted: boolean,
		relationship: InputValue,
	): Promise<void> => {
		this.validateForm()

		if (!this.form.valid) {
			return
		}

		try {
			const performer = await this.performerService.create(
				new Performer(
					undefined,
					firstName as string,
					lastName as string,
					school as string,
					dob,
					gender as string,
					tos_accepted,
					undefined,
					[],
					relationship as AccountRelationship,
					undefined,
				),
			)

			this.performerCreated.emit(performer)
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					this.formErrors = error.handleFormErrors(this.formErrors, 'performer')
					this.markInvalidFields()
				}
			} else {
				throw error
			}
		}
	}

	protected buildForm = () => {
		this.form = this.formBuilder.group({
			first_name: ['', [Validators.required]],
			last_name: ['', [Validators.required]],
			school: ['', [Validators.required]],
			dob: ['', [Validators.required]],
			gender: ['', [Validators.required]],
			tos_accepted: [false, []],
			account_relationship: ['', Validators.required],
		})
	}
}
