<div>
	<select-component
		[styles]="{width: '70px'}"
		[items]="hourOptions"
		[(value)]="formattedHour"
		(valueChange)="updateTimes()"
	></select-component>
	:
	<select-component
		[styles]="{width: '70px'}"
		[items]="minuteOptions"
		[(value)]="formattedMinute"
		(valueChange)="updateTimes()"
	></select-component>
</div>
