import Performer from '@account/Performer'
import Class from '@account/Class'

export default class CartItem {
	constructor(
		public id: number,
		public chosenClass: Class,
		public performers: Performer[],
		public amount: number,
		public vat: number,
	) {}
}
