<span [formGroup]="form" *ngIf="form">
	<mat-form-field [ngStyle]="styles" subscriptSizing="dynamic" appearance="fill">
		<span matPrefix>{{ prefix }}</span>
		<mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
		<mat-label>{{ placeHolder }}</mat-label>
		<input
			matInput
			[id]="inputId"
			[type]="type === 'password' && !hide ? 'text' : type"
			[placeholder]="placeHolder"
			[formControlName]="formControlName"
			[autocomplete]="autocomplete"
			[attr.disabled]="disabled"
			(keyup)="keyUpChange.emit()"
			ngDefaultControl
		/>
		<span matSuffix *ngIf="type !== 'password'">{{ suffix }}</span>
		<button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" *ngIf="type === 'password' && !disableShowPassword">
      <mat-icon color="accent">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
		<mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
		<mat-error [ngStyle]="{ margin: '10px 0' }" *ngIf="error">{{ error }}</mat-error>
	</mat-form-field>
</span>
<span *ngIf="!form">
	<mat-form-field [ngStyle]="styles" subscriptSizing="dynamic" appearance="fill">
		<span matPrefix>{{ prefix }}</span>
		<mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
		<mat-label>{{ placeHolder }}</mat-label>
		<input
			matInput
			[id]="inputId"
			[placeholder]="placeHolder"
			[type]="type === 'password' && !hide ? 'text' : type"
			[autocomplete]="autocomplete"
			[(ngModel)]="value"
			(ngModelChange)="basicOnChange($event)"
			(change)="blurOnChange($event.target)"
			[disabled]="disabled"
			(keyup)="keyUpChange.emit()"
			ngDefaultControl
		/>
		<span matSuffix *ngIf="type !== 'password'">{{ suffix }}</span>
		<button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" *ngIf="type === 'password' && !disableShowPassword">
      <mat-icon color="accent">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
	</mat-form-field>
	<mat-error [ngStyle]="{ margin: '10px 0' }" *ngIf="error">{{ error }}</mat-error>
</span>
