import { Component, Input } from '@angular/core'

@Component({
	selector: 'card-title',
	templateUrl: './CardTitle.Component.html',
})
export class CardTitleComponent {
	@Input() public icon: string

	@Input() public image: string

	@Input() public title: string

	@Input() public subtitle: string
}
