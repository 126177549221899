import { Component } from '@angular/core'
import { PermissionsService } from '@account/Services/PermissionsService'
import Account from '@account/Account'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'
import HttpError, { FormErrors } from '@ui/HttpError'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { AccountService } from '@account/Services/AccountService'
import Address, { ACCOUNT } from '@account/Address'
import { debounceTime } from 'rxjs/operators'
import { CustomValidators } from '@ui/Validators/CustomValidators'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ConfirmAccountDetailsDialogComponent } from '@account/Dialogs/Account/ConfirmAccountDetailsDialog.Component'

@Component({
	selector: 'my-account',
	templateUrl: './Dashboard.Component.html',
})
export class DashboardComponent extends FormValidationComponent {
	public account: Account

	public showConfirmAccount = false

	public formErrors: FormErrors = {
		first_name: '',
		last_name: '',
		email: '',
		address1: '',
		address2: '',
		city: '',
		county: '',
		postcode: '',
		contact_number: '',
	}

	protected validationMessages: ValidationMessages = {
		first_name: {
			required: 'First name is required',
		},
		last_name: {
			required: 'Last name is required',
		},
		email: {
			required: 'Email is required',
			invalidEmailAddress: 'Email address must be a valid address',
		},
		address1: {
			required: 'Address line 1 is required',
		},
		address2: {},
		city: {
			required: 'City is required',
		},
		county: {
			required: 'County is required',
		},
		postcode: {
			required: 'Postcode is required',
		},
		contact_number: {
			required: 'Contact number is required',
		},
	}

	private confirmAccountDetailsDialogComponentMatDialogRef: MatDialogRef<ConfirmAccountDetailsDialogComponent>

	constructor(
		protected formBuilder: FormBuilder,
		private readonly permissionsService: PermissionsService,
		private readonly accountService: AccountService,
		private readonly systemConfigService: SystemConfigService,
		private readonly matDialog: MatDialog,
	) {
		super(formBuilder)
		this.account = permissionsService.account

		if (this.systemConfigService.config.accountConfirmed) {
			if (!this.account.confirmed_at) {
				this.showConfirmAccount = true
			} else {
				const accountConfirmedSettingDate = new Date(
					this.systemConfigService.config.accountConfirmed,
				)

				const accountConfirmedDate = new Date(this.account.confirmed_at)

				this.showConfirmAccount =
					accountConfirmedDate.getTime() < accountConfirmedSettingDate.getTime()
			}
		}
	}

	public updateAccount = async (
		firstName: InputValue,
		lastName: InputValue,
		email: InputValue,
		address1: InputValue,
		address2: InputValue,
		city: InputValue,
		county: InputValue,
		postcode: InputValue,
		contactNumber: InputValue,
	) => {
		this.validateForm()

		if (!this.form.valid) {
			return
		}

		try {
			this.account = await this.accountService.update(
				new Account(
					this.account.id,
					firstName as string,
					lastName as string,
					email as string,
					new Address(
						this.account.address.id,
						address1 as string,
						(address2 as string) ? undefined : (address2 as string),
						city as string,
						county as string,
						postcode as string,
						ACCOUNT,
					),
					contactNumber as string,
				),
			)

			await this.permissionsService.getPermissions()
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					this.formErrors = error.handleFormErrors(this.formErrors, 'account')

					this.markInvalidFields()
				}
			} else {
				throw error
			}
		}
	}

	public openConfirmAccountModal = () => {
		this.confirmAccountDetailsDialogComponentMatDialogRef = this.matDialog.open(
			ConfirmAccountDetailsDialogComponent,
			{
				disableClose: true,
				maxWidth: '90%',
				width: '600px',
			},
		)

		this.confirmAccountDetailsDialogComponentMatDialogRef
			.afterClosed()
			.subscribe(async (confirmed: boolean) => {
				if (confirmed) {
					this.validateForm()

					if (!this.form.valid) {
						return
					}

					try {
						await this.accountService.update(this.account)
					} catch (error) {
						if (error instanceof HttpError) {
							if (error.isUnprocessableEntity()) {
								this.formErrors = error.handleFormErrors(
									this.formErrors,
									'account',
								)

								this.markInvalidFields()
							}

							return
						}

						throw error
					}

					try {
						await this.accountService.confirm()
						await this.permissionsService.getPermissions()
						this.showConfirmAccount = false
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}
			})
	}

	protected buildForm = () => {
		this.form = this.formBuilder.group({
			first_name: [
				this.permissionsService.account.first_name,
				[Validators.required],
			],
			last_name: [
				this.permissionsService.account.last_name,
				[Validators.required],
			],
			email: [
				this.permissionsService.account.email,
				[Validators.required, CustomValidators.emailValidator],
			],
			address1: [
				this.permissionsService.account.address.address1,
				[Validators.required],
			],
			address2: [this.permissionsService.account.address.address2, []],
			city: [
				this.permissionsService.account.address.city,
				[Validators.required],
			],
			county: [
				this.permissionsService.account.address.county,
				[Validators.required],
			],
			postcode: [
				this.permissionsService.account.address.postcode,
				[Validators.required],
			],
			contact_number: [
				this.permissionsService.account.contact_number,
				[Validators.required],
			],
		})

		this.form.valueChanges.pipe(debounceTime(500)).subscribe(async (data) => {
			await this.updateAccount(
				data.first_name,
				data.last_name,
				data.email,
				data.address1,
				data.address2,
				data.city,
				data.county,
				data.postcode,
				data.contact_number,
			)
		})
	}
}
