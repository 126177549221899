import Performer from '@account/Performer'
import Class from '@account/Class'
import Filterable from '@ui/Filterable'
import { createOrderItem } from '@account/utils'
import School from '@account/School'
import Upload from '@account/Upload'

export default class OrderItem implements Filterable {
	constructor(
		public id: number,
		public performance_class: Class,
		public performers: Performer[],
		public school: School,
		public amount: number,
		public cancelled: boolean,
		public class_category_reference: number,
		public class_reference: number,
		public upload: Upload,
		public notes: string,
		public vat: number,
	) {}

	public getFilterName = (): string => {
		return 'orderItem'
	}

	public reset = (): Filterable => {
		return createOrderItem()
	}

	public toFilter = (): any => ({
		performers: this.performers.map((performer: Performer) => ({
			id: performer.id,
		})),
		cancelled: this.cancelled ? 1 : 0,
		performanceClass: this.performance_class
			? this.performance_class.toFilter()
			: undefined,
	})
}
