import { Component, Input } from '@angular/core'

type AdditionalDataCallback = () => void | Promise<void>

type AdditionalCallbackCallback = (data: any) => void | Promise<void>

export interface Tab {
	label: string
	component: {
		instance: any
		additionalData?: { [key: string]: any | AdditionalDataCallback }
		additionalCallbacks?: { [key: string]: AdditionalCallbackCallback }
	}
}

@Component({
	selector: 'tabs',
	templateUrl: './Tabs.Component.html',
})
export class TabsComponent {
	@Input() public tabs: Tab[] = []

	public tabTracker = (index: number): number => index
}
