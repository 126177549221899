import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import Performer from '@account/Performer'

@Component({
	selector: 'create-performer-dialog',
	templateUrl: './CreatePerformerDialog.Component.html',
})
export class CreatePerformerDialogComponent {
	constructor(
		private readonly dialogRef: MatDialogRef<CreatePerformerDialogComponent>,
	) {}

	public closeDialog = (performer: Performer) => {
		this.dialogRef.close(performer)
	}
}
