import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import QueryModifiers, { OrderBy } from '@ui/QueryModifiers'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'
import { Sortable } from '@ui/Sortable'

@Component({
	selector: 'query-modifiers',
	templateUrl: './QueryModifiers.Component.html',
})
export class QueryModifiersComponent implements OnInit {
	@Input() public model: Sortable

	@Input() public queryModifiers: QueryModifiers

	@Output() private readonly queryModifiersChange =
		new EventEmitter<QueryModifiers>()

	public mappedSelectors: SelectItem[] = []

	public value: SelectValue

	public ngOnInit() {
		const directionsModifiers = this.model.getOptions()

		const mappedSelectors: SelectItem[] = [
			{
				label: 'Default',
				value: undefined,
			},
		]

		const orderByKeys = Object.keys(this.queryModifiers.orderBys)

		const orderByKey = orderByKeys.length > 0 ? orderByKeys[0] : false

		const orderByValue = orderByKey
			? this.queryModifiers.orderBys[orderByKey]
			: false

		Object.keys(directionsModifiers).forEach((key: string): void => {
			if (orderByKey === key) {
				this.value = `${orderByKey}&${orderByValue}`
			}

			directionsModifiers[key].forEach((item: SelectItem): void => {
				mappedSelectors.push({
					label: item.label,
					value: `${key}&${item.value}`,
				})
			})
		})

		this.mappedSelectors = mappedSelectors
	}

	public directionModifierChanged = (value: SelectValue) => {
		if (!value) {
			this.value = undefined

			this.queryModifiers.orderBys = {}

			this.queryModifiersChange.emit(this.queryModifiers)

			return
		}

		const parts = value.toString().split('&')

		this.queryModifiers.orderBys = {
			[parts[0] as string]: parts[1] as OrderBy,
		}

		this.value = value

		this.queryModifiersChange.emit(this.queryModifiers)
	}

	public trackBy = (key: number): number => key
}
