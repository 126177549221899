import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import WaitingList from '@account/WaitingList'
import { ClassService } from '@account/Services/ClassService'

@Injectable()
export class WaitingListService extends BaseService<WaitingList> {
	protected endpoint = '/api/account/waiting-lists'

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
		private readonly classService: ClassService,
	) {
		super(http, paginationService)
	}

	public create = async (waitingList: WaitingList): Promise<WaitingList> => {
		const response = await this.post('/api/account/waiting-lists', {
			class_waiting_list: { ...waitingList },
		})

		return this.mapToObject(response)
	}

	public deleteList = async (waitingList: WaitingList): Promise<void> => {
		await this.delete(`/api/account/waiting-lists/${waitingList.id}`)
	}

	public mapToObject = (json): WaitingList => {
		return new WaitingList(
			json.id,
			json.class_id,
			this.classService.mapToObject(json.performance_class),
			json.created_at,
		)
	}
}
