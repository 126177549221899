<form [formGroup]="form" (ngSubmit)="createPerformer(firstName.value, lastName.value, school.value, dob.value, gender.value, acceptTos.value, relationship.value)">
	<toolbar-component color="primary">Create Performer</toolbar-component>
	<text-input
		#firstName
		[form]="form"
		formControlName="first_name"
		placeHolder="First Name"
		[error]="formErrors.first_name"
	></text-input>
	<text-input
		#lastName
		[form]="form"
		formControlName="last_name"
		placeHolder="Last Name"
		[error]="formErrors.last_name"
	></text-input>
	<text-input
		#school
		[form]="form"
		formControlName="school"
		placeHolder="Academic School"
		[error]="formErrors.school"
	></text-input>
	<pick-a-date
		#dob
		[form]="form"
		formControlName="dob"
		placeHolder="DOB"
		[error]="formErrors.dob"
	></pick-a-date>
	<select-component
		#gender
		[form]="form"
		formControlName="gender"
		[items]="genderItems"
		placeholder="Gender"
		[error]="formErrors.gender"
	></select-component>
	<select-component
		#relationship
		[form]="form"
		formControlName="account_relationship"
		[items]="relationshipItems"
		placeholder="Relationship With Performer"
		[error]="formErrors.account_relationship"
	></select-component>
	<div [ngStyle]="{ 'margin': '10px 0' }">
		<p [style]="{ 'font-size': '12px' }">* {{ systemConfigService.config.performerTos }}</p>
		<slide-toggle
			#acceptTos
			formControlName="tos_accepted"
			innerText="*Accept TOS"
			[form]="form"
		></slide-toggle>
	</div>
	<raised-button color="primary" [styles]="{ width: '100%' }">Create Performer</raised-button>
</form>
