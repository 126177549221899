<account-page *ngIf="class" [title]="class.name" [subtitle]="class.description">
	<div class="flex-base">
		<div class="class-info">
			<card-component>
				<card-content>
					<class-info [class]="class"></class-info>
				</card-content>
			</card-component>
		</div>
		<div class="class-info" *ngIf="permissionService.account && systemConfigService.config.checkoutEnabled">
			<card-component>
				<card-content>
					<add-to-cart [class]="class"></add-to-cart>
				</card-content>
			</card-component>
		</div>
	</div>
	<div *ngIf="showOverflowButton">
		<raised-button (click)="createWaitingList()" color="primary" [styles]="{ width: '100%' }">Alert me when spaces become available</raised-button>
	</div>
</account-page>
