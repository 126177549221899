import Class from '@account/Class'
import Filterable from '@ui/Filterable'

export default class WaitingList implements Filterable {
	constructor(
		public id: number,
		public class_id: number,
		public performanceClass: Class,
		public created_at: string,
	) {}

	public getFilterName(): string {
		return ''
	}

	public reset(): Filterable {
		return undefined
	}

	public toFilter(): any {
		return {}
	}
}
