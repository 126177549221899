import { AfterViewInit, Component, Input } from '@angular/core'
import { Html5QrcodeScanner } from 'html5-qrcode'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
	selector: 'qr-code-reader',
	templateUrl: './QrCodeReader.Component.html',
})
export class QrCodeReaderComponent implements AfterViewInit {
	@Input() public readonly scanSuccessCallback: (
		decodedText: string,
	) => Promise<string>

	private reader: Html5QrcodeScanner

	constructor(private readonly snackBar: MatSnackBar) {}

	public ngAfterViewInit() {
		this.reader = new Html5QrcodeScanner(
			'reader',
			{
				fps: 10,
				qrbox: { width: 250, height: 250 },
				showTorchButtonIfSupported: true,
				showZoomSliderIfSupported: true,
			},
			process.env.NODE_ENV === 'development',
		)

		this.reader.render(this.onScanSuccess, this.onScanFailure)
	}

	private readonly onScanSuccess = async (
		decodedText: string,
	): Promise<void> => {
		this.reader.pause()

		try {
			const response = await this.scanSuccessCallback(decodedText)

			this.snackBar.open(response, 'Close')
		} finally {
			setTimeout(() => {
				this.reader.resume()
			}, 5000)
		}
	}

	private readonly onScanFailure = () => {
		// do nothing
	}
}
