import { Component, Input } from '@angular/core'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'client-currency',
	template:
		'<base-currency-component [currency]="currency" [value]="value" [locale]="locale" [precision]="precision"></base-currency-component>',
})
export class CurrencyComponent {
	@Input() public value: number

	public currency: string

	public locale: string

	public precision: number

	constructor(private readonly systemConfigService: SystemConfigService) {
		this.currency = systemConfigService.config.currency
		this.locale = systemConfigService.config.locale
		this.precision = systemConfigService.config.currencyDecimalPlaces
	}
}
