import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-loader',
	templateUrl: './Loader.Component.html',
})
export class LoaderComponent {
	@Input() public loading: boolean

	@Input() public height = '20px'
}
