import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http'
import HttpError from '@ui/HttpError'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ConfirmPasswordDialogComponent } from '@ui/Dialogs/ConfirmPassword/ConfirmPasswordDialog.Component'
import { PermissionsService } from '@account/Services/PermissionsService'

@Injectable()
export class AuthenticatedHttpService implements HttpInterceptor {
	private confirmPasswordDialogComponentMatDialogRef: MatDialogRef<ConfirmPasswordDialogComponent>

	constructor(
		private readonly snackBar: MatSnackBar,
		private readonly dialog: MatDialog,
		private readonly permissionsService: PermissionsService,
	) {}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(
				() => {}, // eslint-disable-line
				(error) => {
					if (error instanceof HttpErrorResponse) {
						switch (error.status) {
							case 401:
								const bar = this.snackBar.open(error.error.message, 'Log in', {
									politeness: 'assertive',
								})

								bar
									.afterDismissed()
									.subscribe(() => (window.location.href = '/login'))
								break
							case 429:
								this.snackBar.open(error.error.message, 'Api limit reached', {
									politeness: 'assertive',
								})
								break
							case 422:
								throw new HttpError(error)
							case 423:
								let isOpen = false

								this.dialog.openDialogs.forEach((dialog: MatDialogRef<any>) => {
									if (
										dialog.componentInstance instanceof
										ConfirmPasswordDialogComponent
									) {
										isOpen = true
									}
								})

								if (isOpen) {
									break
								}

								this.confirmPasswordDialogComponentMatDialogRef =
									this.dialog.open(ConfirmPasswordDialogComponent)

								this.confirmPasswordDialogComponentMatDialogRef.componentInstance.confirmPasswordCallback =
									this.permissionsService.confirmPassword

								break
							default:
								const message =
									error.error && error.error.message
										? error.error.message
										: 'Something went wrong'

								this.snackBar.open(message, 'Request Error', {
									politeness: 'assertive',
								})
						}

						throw new HttpError(error)
					}
				},
			),
		)
	}
}
