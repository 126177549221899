import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'

export type AutoCompleteOptionValue = string | number

export interface AutoCompleteOption {
	value: AutoCompleteOptionValue
	label: string
}

@Component({
	selector: 'autocomplete-component',
	templateUrl: './Autocomplete.Component.html',
})
export class AutocompleteComponent implements OnInit {
	@Input() public options: AutoCompleteOption[]

	@Input() public placeholder: string

	@Input() public prefixIcon: string

	@Input() public suffixIcon: string

	@Input() private readonly resetOnCompletion: boolean

	@Input() private readonly filter: (string) => Promise<void>

	@Output() private readonly optionSelected =
		new EventEmitter<AutoCompleteOptionValue>()

	public control = new FormControl()

	public ngOnInit(): void {
		this.control.valueChanges
			.pipe(debounceTime(500))
			.subscribe(async (value) => this.filter(value))
	}

	public selectOption = (event: MatAutocompleteSelectedEvent): void => {
		this.optionSelected.emit(event.option.value)

		if (this.resetOnCompletion) {
			this.control.setValue('')
		}
	}

	public itemTracker = (index: number) => index
}
