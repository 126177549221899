<account-page>
	<vip-paginator
		[paginator]="waitingListService.paginator"
		[requesting]="waitingListService.loading"
		(pageChanged)="setPaginator($event)"
	></vip-paginator>
	<cdk-table-component
		[displayColumns]="displayColumns"
		[entities]="tableEntities"
		[dataSource]="dataSource"
	></cdk-table-component>
</account-page>
