import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import HttpError, { FormErrors } from '@ui/HttpError'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'

@Component({
	selector: 'confirm-password-dialog',
	templateUrl: './ConfirmPasswordDialog.Component.html',
})
export class ConfirmPasswordDialogComponent extends FormValidationComponent {
	public confirmPasswordCallback: (password: string) => Promise<void>

	public formErrors: FormErrors = {
		password: '',
	}

	public validationMessages: ValidationMessages = {
		password: {
			required: 'Password is required',
		},
	}

	constructor(
		protected formBuilder: FormBuilder,
		private readonly dialogRef: MatDialogRef<ConfirmPasswordDialogComponent>,
	) {
		super(formBuilder)
	}

	public confirmPassword = async () => {
		this.validateForm()

		if (!this.form.valid) {
			return
		}

		try {
			await this.confirmPasswordCallback(this.form.get('password').value)
			this.closeDialog()
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					this.formErrors = error.handleFormErrors(this.formErrors, '')
					this.markInvalidFields()
				}
				return
			}
			throw error
		}
	}

	public closeDialog = () => {
		this.dialogRef.close()
	}

	protected buildForm() {
		this.form = this.formBuilder.group({
			password: this.formBuilder.control('', [Validators.required]),
		})
	}
}
