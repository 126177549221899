import { Component, OnInit } from '@angular/core'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'
import HttpError, { FormErrors } from '@ui/HttpError'
import { AccountService } from '@account/Services/AccountService'
import { ActivatedRoute, Params } from '@angular/router'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'

@Component({
	selector: 'reset-password',
	templateUrl: './ResetPassword.Component.html',
	styleUrls: ['../Auth.css'],
})
export class ResetPasswordComponent
	extends FormValidationComponent
	implements OnInit
{
	public formErrors: FormErrors = {
		email: '',
		password: '',
		password_confirmation: '',
	}

	protected validationMessages: ValidationMessages = {
		email: {
			required: 'Email is required',
		},
		password: {
			required: 'Password is required',
		},
		password_confirmation: {
			required: 'Password confirmation is required',
		},
	}

	private token: string

	constructor(
		protected formBuilder: FormBuilder,
		private readonly accountService: AccountService,
		private readonly route: ActivatedRoute,
	) {
		super(formBuilder)
	}

	public ngOnInit() {
		super.ngOnInit()

		this.route.params.subscribe((params: Params) => {
			this.token = params.token
		})
	}

	public resetPassword = async (
		email: InputValue,
		password: InputValue,
		confirmation: InputValue,
	): Promise<void> => {
		this.validateForm()

		if (this.form.valid) {
			try {
				await this.accountService.resetPassword(
					this.token,
					email as string,
					password as string,
					confirmation as string,
				)

				window.location.href = '/login'
			} catch (error) {
				if (error instanceof HttpError) {
					if (error.isUnprocessableEntity()) {
						this.formErrors = error.handleFormErrors(this.formErrors, '')
						this.markInvalidFields()
					}
				} else {
					throw error
				}
			}
		}
	}

	protected buildForm = (): void => {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required]],
			password: ['', [Validators.required]],
			password_confirmation: ['', [Validators.required]],
		})
	}
}
