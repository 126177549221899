import Filterable from '@ui/Filterable'
import { createClassCategory } from '@account/utils'

export default class ClassCategory implements Filterable {
	constructor(
		public id: number,
		public name: string,
		public description: string,
	) {}

	public getFilterName = (): string => {
		return 'category'
	}

	public reset = (): ClassCategory => {
		return createClassCategory()
	}

	public toFilter = (): any => {
		return {
			name: this.name,
		}
	}
}
