import { Component, Input } from '@angular/core'

@Component({
	selector: 'toolbar-component',
	templateUrl: './Toolbar.Component.html',
})
export class ToolbarComponent {
	@Input() public styles: { [key: string]: string | number }

	@Input() public color: 'primary' | 'warn'
}
