import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CdkTableModule } from '@angular/cdk/table'
import { CommonModule } from '@angular/common'

import { MatNativeDateModule } from '@angular/material/core'
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatButtonModule } from '@angular/material/button'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatCardModule } from '@angular/material/card'
import { MatRadioModule } from '@angular/material/radio'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSelectModule } from '@angular/material/select'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTableModule } from '@angular/material/table'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatTabsModule } from '@angular/material/tabs'

import { TextInputComponent } from '@ui/Components/Form/TextInput/TextInput.Component'
import { TextAreaComponent } from '@ui/Components/Form/TextArea/TextArea.Component'
import { PaginatorComponent } from '@ui/Components/Paginator/Paginator.Component'
import { ListComponent } from '@ui/Components/List/List.Component'
import { ListItemComponent } from 'ui/Components/List/ListItem.Component'
import { ListItemActionComponent } from '@ui/Components/List/ListItemAction.Component'
import { ListFilterComponent } from '@ui/Components/List/ListFilter.Component'
import { RaisedButtonComponent } from '@ui/Components/Form/Button/RaisedButton.Component'
import { FileUploaderComponent } from '@ui/Components/FileUploader/FileUploader.Component'
import { CardComponent } from '@ui/Components/Card/Card.Component'
import { CardContentComponent } from '@ui/Components/Card/CardContent.Component'
import { CardTitleComponent } from '@ui/Components/Card/CardTitle.Component'
import { CardActionsComponent } from '@ui/Components/Card/CardActions.Component'
import { DebouncedTextFilterComponent } from '@ui/Components/Filters/DebouncedTextFilter.Component'
import { SlideToggleComponent } from '@ui/Components/Form/SlideToggle/SlideToggle.Component'
import { PageComponent } from '@ui/Components/Page/Page.Component'
import { RadioGroupComponent } from '@ui/Components/Form/Radio/RadioGroup.Component'
import { DatePickerComponent } from '@ui/Components/Form/DatePicker/DatePicker.Component'
import { SiteNavComponent } from '@ui/Components/SiteNavigation/SiteNav.Component'
import { LoaderComponent } from '@ui/Components/Loader/Loader.Component'
import { SiteNavLinkComponent } from '@ui/Components/SiteNavigation/SiteNavLink.Component'
import { AutocompleteComponent } from '@ui/Components/Form/Autocomplete/Autocomplete.Component'
import { DateRangePickerComponent } from '@ui/Components/Form/DateRangePicker/DateRangePicker.Component'
import { TimeSelectorComponent } from '@ui/Components/Form/TimeSelector/TimeSelector.Component'
import { SelectComponent } from '@ui/Components/Form/Select/Select.Component'
import { ToolbarComponent } from '@ui/Components/Toolbar/Toolbar.Component'
import { CheckboxComponent } from '@ui/Components/Form/Checkbox/Checkbox.Component'
import { FlatButtonComponent } from '@ui/Components/Form/Button/FlatButton.Component'
import { DialogComponent } from '@ui/Components/Dialog/Dialog.Component'
import { CdkTableComponent } from '@ui/Components/Table/CdkTable.Component'
import { DateTimeComponent } from '@ui/Components/DateTime/DateTime.Component'
import { CdkTableCellComponent } from '@ui/Components/Table/CdkTableCell.Component'
import { TimeComponent } from '@ui/Components/DateTime/Time.Component'
import { BaseCurrencyComponent } from '@ui/Components/Currency/BaseCurrency.Component'
import { TableCellCheckboxComponent } from '@ui/Components/Table/Checkbox/TableCellCheckbox.Component'
import { BaseCalendarComponent } from '@ui/Components/Callendar/BaseCalendar.Component'
import { FormErrorComponent } from '@ui/Components/Form/Error/FormError.Component'
import { FormHintComponent } from '@ui/Components/Form/Hint/FormHint.Component'
import { ButtonToggleGroupComponent } from '@ui/Components/Form/Button/ButtonToggleGroup.Component'
import { ListActionComponent } from '@ui/Components/List/ListAction.Component'
import { UploadProgressComponent } from '@ui/Components/FileUploader/UploadProgress.Component'
import { FileUploadDialogComponent } from '@ui/Dialogs/FileUpload/FileUploadDialog.Component'
import { CdkTableHeaderComponent } from '@ui/Components/Table/CdkTableHeader.Component'
import { CdkTableExpandedDetailComponent } from '@ui/Components/Table/CdkTableExpandedDetail.Component'
import { QueryModifiersComponent } from '@ui/Components/QueryModifiers/QueryModifiers.Component'
import { AudioPlayerComponent } from '@ui/Components/AudioPlayer/AudioPlayer.Component'
import { TimePickerDialogComponent } from '@ui/Dialogs/TimePicker/TimePickerModal.Component'
import { TabsComponent } from '@ui/Components/Tabs/Tabs.Component'
import { TabComponent } from '@ui/Components/Tabs/Tab.Component'
import { QrCodeGeneratorComponent } from '@ui/Components/QrCode/QrCodeGenerator.Component'
import { QrCodeReaderComponent } from '@ui/Components/QrCode/QrCodeReader.Component'
import { ConfirmPasswordDialogComponent } from '@ui/Dialogs/ConfirmPassword/ConfirmPasswordDialog.Component'
import { DialogActionsComponent } from '@ui/Components/Dialog/DialogActions.Component'
import { ButtonComponent } from '@ui/Components/Form/Button/Button.Component'

import { AdDirective } from '@ui/Components/Table/Ad.Directive'

import { PaginationService } from '@ui/Services/PaginationService'
import { FileUploaderService } from '@ui/Services/FileUploaderService'
import { MatIconModule } from '@angular/material/icon'
import { RecaptchaService } from '@ui/Services/RecaptchaService'

const DECLARATIONS = [
	TextInputComponent,
	TextAreaComponent,
	PaginatorComponent,
	ListComponent,
	ListItemActionComponent,
	ListItemComponent,
	ListFilterComponent,
	RaisedButtonComponent,
	FileUploaderComponent,
	CardComponent,
	CardContentComponent,
	CardActionsComponent,
	CardTitleComponent,
	DebouncedTextFilterComponent,
	SlideToggleComponent,
	PageComponent,
	RadioGroupComponent,
	DatePickerComponent,
	SiteNavComponent,
	LoaderComponent,
	SiteNavLinkComponent,
	AutocompleteComponent,
	DateRangePickerComponent,
	TimeSelectorComponent,
	SelectComponent,
	ToolbarComponent,
	CheckboxComponent,
	FlatButtonComponent,
	DialogComponent,
	TimePickerDialogComponent,
	CdkTableComponent,
	DateTimeComponent,
	CdkTableCellComponent,
	TimeComponent,
	BaseCurrencyComponent,
	TableCellCheckboxComponent,
	BaseCalendarComponent,
	FormErrorComponent,
	FormHintComponent,
	ButtonToggleGroupComponent,
	ListActionComponent,
	UploadProgressComponent,
	FileUploadDialogComponent,
	CdkTableHeaderComponent,
	CdkTableExpandedDetailComponent,
	QueryModifiersComponent,
	AudioPlayerComponent,
	TabsComponent,
	TabComponent,
	QrCodeGeneratorComponent,
	QrCodeReaderComponent,
	ConfirmPasswordDialogComponent,
	ButtonComponent,
	DialogActionsComponent,
	AdDirective,
]

const EXPORTS = DECLARATIONS

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatNativeDateModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatPaginatorModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatProgressBarModule,
		MatCardModule,
		MatRadioModule,
		MatDatepickerModule,
		MatSidenavModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatToolbarModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatDialogModule,
		CdkTableModule,
		MatTableModule,
		MatButtonToggleModule,
		MatIconModule,
		DragDropModule,
		MatTabsModule,
	],
	providers: [PaginationService, FileUploaderService, RecaptchaService],
	declarations: DECLARATIONS,
	exports: EXPORTS,
})
export class UiModule {}
