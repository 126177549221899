import { Component, Input, OnInit } from '@angular/core'
import Class from '@account/Class'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'class-info',
	templateUrl: './ClassInfo.Component.html',
})
export class ClassInfoComponent implements OnInit {
	@Input() public class: Class

	public formattedType: string

	public formattedMinAge: string

	public formattedState: string

	public formattedEntryType: string

	constructor(private readonly systemConfigService: SystemConfigService) {}

	public ngOnInit() {
		this.formattedType = this.class.formattedType()
		this.formattedMinAge = this.class.getAgeRange()
		this.formattedState = this.class.getCurrentStatus(
			this.systemConfigService.config.timezone,
		)
		this.formattedEntryType = this.class.getFormattedEntryType()
	}
}
