<div class="flex-container">
	<div class="flex-item" class="form-outter">
		<card-component>
			<card-content>
				<div class="forgotten-password">
					<form [formGroup]="form">
						<text-input
							placeHolder="Email"
							formControlName="email"
							[form]="form"
							[error]="formErrors.email"
						></text-input>
						<raised-button [styles]="{ width: '100%' }" color="primary" (click)="resetPassword()">Reset Password</raised-button>
					</form>
				</div>
			</card-content>
		</card-component>
	</div>
</div>


