import { Component, OnInit } from '@angular/core'
import FileUpload from '@ui/FileUpload'
import { MatDialogRef } from '@angular/material/dialog'
import HttpError, { FormErrors } from '@ui/HttpError'
import { ThemePalette } from '@angular/material/core'

export type FileUploaded = (file: FileUpload) => void | Promise<void>

@Component({
	selector: 'file-upload-dialog',
	templateUrl: './FileUploadDialog.Component.html',
	styleUrls: ['./FileUploadDialog.Component.css'],
})
export class FileUploadDialogComponent implements OnInit {
	public fileUpload: FileUpload

	public fileUploaded: FileUploaded

	public buttonText: string

	public buttonColor: ThemePalette

	public showButton = false

	public error: string

	public formErrors: FormErrors

	constructor(
		private readonly dialogRef: MatDialogRef<FileUploadDialogComponent>,
	) {}

	public ngOnInit() {
		this.fileUpload.updated.subscribe((fileUpload: FileUpload) => {
			if (fileUpload.progress === 100) {
				const response = fileUpload.getResponse()

				if (!response) {
					return
				}

				if (response instanceof HttpError) {
					this.buttonText = 'Close and try again'
					this.buttonColor = 'warn'
					this.showButton = true

					this.error =
						fileUpload.getResponse().status === 413
							? 'File too large'
							: response.message

					if (response.status === 422) {
						this.formErrors = response.getFormErrors()
					}
				} else {
					this.buttonText = undefined
					this.buttonColor = undefined
					this.showButton = false
					this.formErrors = undefined
					this.error = undefined
					this.closeDialog()
				}

				if (typeof this.fileUploaded !== 'undefined') {
					this.fileUploaded(fileUpload)
				}
			}
		})
	}

	public closeDialog = () => {
		this.dialogRef.close()
	}

	public tracker = (index: number): number => index
}
