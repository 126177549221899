import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import { createOrderItem } from '@account/utils'
import OrderItem from '@account/OrderItem'
import Performer from '@account/Performer'
import { ClassService } from '@account/Services/ClassService'
import { PerformerService } from '@account/Services/PerformerService'
import { SchoolsService } from '@account/Services/SchoolsService'
import Upload from '@account/Upload'

@Injectable()
export class OrderItemService extends BaseService<OrderItem> {
	public filterable: OrderItem

	protected endpoint = '/api/account/order-items'

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
		private readonly classService: ClassService,
		private readonly performerService: PerformerService,
		private readonly schoolsService: SchoolsService,
	) {
		super(http, paginationService)

		this.filterable = createOrderItem()
	}

	public find = async (id: number): Promise<OrderItem> => {
		const response = await this.get({ url: `/api/account/order-items/${id}` })

		return this.mapToObject(response)
	}

	public update = async (orderItem: OrderItem): Promise<OrderItem> => {
		const response = await this.put(
			`/api/account/order-items/${orderItem.id}`,
			{
				item: orderItem,
			},
		)

		return this.mapToObject(response)
	}

	public mapToObject = (json: any): OrderItem => {
		let performers: Performer[]

		if (json.performers) {
			performers = json.performers.map((jsonPerformer: Performer) => {
				return this.performerService.mapToObject(jsonPerformer)
			})
		}

		return new OrderItem(
			json.id,
			json.performance_class
				? this.classService.mapToObject(json.performance_class)
				: undefined,
			performers,
			json.school ? this.schoolsService.mapToObject(json.school) : undefined,
			json.amount,
			json.cancelled,
			json.class_category_reference,
			json.class_reference,
			json.upload
				? new Upload(
						json.upload.id,
						json.upload.mime_type,
						json.upload.remote_path,
						json.upload.remote_service,
						json.upload.entity_type,
						json.upload.entity_id,
						json.upload.public_url,
				  )
				: undefined,
			json.notes,
			json.vat,
		)
	}
}
