<account-page>
	<vip-paginator
		[paginator]="categoryService.paginator"
		[requesting]="categoryService.loading"
		(pageChanged)="setPaginator($event)"
	></vip-paginator>
	<div class="flex-filters filters">
		<div class="flex-filter-item">
			<text-filter
				placeHolder="Name"
				prefixIcon="search"
				[text]="categoryService.filterable.name"
				(textChange)="filterName($event)"
			></text-filter>
		</div>
	</div>
	<cdk-table-component
		[displayColumns]="displayColumns"
		[entities]="tableEntities"
		[dataSource]="dataSource"
		(rowClickAction)="viewCategory($event)"
	></cdk-table-component>
</account-page>
