import { Component, Input, Output, EventEmitter } from '@angular/core'
import Pagination from '@ui/Pagination'
import { PageEvent } from '@angular/material/paginator'

@Component({
	selector: 'vip-paginator',
	templateUrl: './Paginator.Component.html',
})
export class PaginatorComponent {
	@Input() public paginator: Pagination

	@Input() public requesting = false

	@Output() public readonly pageChanged = new EventEmitter<Pagination>()

	public pageSizeOptions: number[] = [5, 10, 25, 100]

	public handleChange = (event: PageEvent) => {
		this.paginator.current_page = event.pageIndex + 1
		this.paginator.per_page = event.pageSize
		this.pageChanged.emit(this.paginator)
	}
}
