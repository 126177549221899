import {
	Component,
	OnChanges,
	Input,
	Output,
	EventEmitter,
} from '@angular/core'
import ListItem, { DescriptionInput } from '@ui/ListItem'
import { ListItemAction } from '@ui/Components/List/ListItemAction.Component'
import { SelectionModel } from '@angular/cdk/collections'

@Component({
	selector: 'list-item',
	templateUrl: './ListItem.Component.html',
	styleUrls: ['./ListItem.Component.css'],
})
export class ListItemComponent implements OnChanges {
	@Input() public item: ListItem

	@Input() public actions: ListItemAction[]

	@Input() public deleteSelection: SelectionModel<ListItem>

	@Input() private readonly descriptionParams: DescriptionInput = {}

	@Output() private readonly deleteSelectionChecked =
		new EventEmitter<ListItem>()

	public title: string

	public image: string

	public description: string | undefined

	public ngOnChanges(): void {
		this.title = this.item.getTitle()

		this.image = this.item.getImage()

		let description = this.item.getDescription(this.descriptionParams)

		if (description) {
			description = description.replace(/(?:\r\n|\r|\n)/g, '<br />')
			this.description = description
		} else {
			this.description = ''
		}
	}

	public actionTracker = (index: number) => index

	public handleToggle = (item: ListItem) => {
		this.deleteSelectionChecked.emit(item)
	}
}
