import { Component } from '@angular/core'

@Component({
	selector: 'register-component',
	templateUrl: './Register.Component.html',
	styleUrls: ['../Auth.css'],
})
export class RegisterComponent {
	public onRegisterSuccessful = () => {
		window.location.reload()
	}
}
