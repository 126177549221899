import { Component, Output, EventEmitter, Input } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { FormErrors } from '@ui/HttpError'

export type SelectValue = number | string

export interface SelectItem {
	value: SelectValue
	label: string
}

@Component({
	selector: 'select-component',
	templateUrl: './Select.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectComponent,
			multi: true,
		},
	],
})
export class SelectComponent implements ControlValueAccessor {
	@Input() public value: SelectValue

	@Input() public items: SelectItem[]

	@Input() public form: FormGroup

	@Input() public formControlName: string

	@Input() public error: string | FormErrors

	@Input() public placeholder: string

	@Input() public prefixIcon: string

	@Input() public styles: { [style: string]: string | number } = {
		width: '100%',
	}

	@Input() public disabled: boolean

	@Output() private readonly valueChange = new EventEmitter<SelectValue>()

	private onTouched: () => void

	private onChange: (value: string) => void

	public registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public writeValue(value: SelectValue): void {
		this.value = value
		this.valueChange.emit(this.value)
	}

	public emitEvent = (selected: MatSelectChange) => {
		this.valueChange.emit(selected.value)
	}

	public emitFormEvent = (selected: MatSelectChange) => {
		this.writeValue(selected.value)
	}

	public trackByItem = (index: number) => {
		return index
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled
	}
}
