import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import Class from '@account/Class'
import ClassCategory from '@account/ClassCategory'
import { createClass } from '@account/utils'

@Injectable()
export class ClassService extends BaseService<Class> {
	public filterable: Class

	protected endpoint = '/api/account/classes'

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(http, paginationService)

		this.filterable = createClass()
	}

	public find = async (id: number): Promise<Class> => {
		const response = await this.get({ url: `/api/account/classes/${id}` })

		return this.mapToObject(response)
	}

	public mapToObject = (json: any): Class => {
		return new Class(
			json.id,
			json.class_number,
			new ClassCategory(
				json.category.id,
				json.category.name,
				json.category.description,
			),
			json.name,
			json.description,
			json.amount,
			json.youngest_dob,
			json.eldest_dob,
			json.type,
			json.entry_type,
			json.group_max,
			json.max_entries,
			json.terms,
			json.starts,
			json.ends,
			json.bookings_open,
			json.bookings_close,
			json.total_entries,
			json.vat,
		)
	}
}
