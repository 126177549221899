import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'confirm-account-details-dialog',
	templateUrl: './ConfirmAccountDetailsDialog.Component.html',
})
export class ConfirmAccountDetailsDialogComponent {
	constructor(
		public readonly systemConfig: SystemConfigService,
		private readonly dialogRef: MatDialogRef<ConfirmAccountDetailsDialogComponent>,
	) {}

	public closeDialog = () => {
		this.dialogRef.close(false)
	}

	public confirmDetails = () => {
		this.dialogRef.close(true)
	}
}
