import Account from '@account/Account'
import OrderItem from '@account/OrderItem'
import Filterable from '@ui/Filterable'
import { createOrder } from '@account/utils'
import Address from '@account/Address'

export interface Payment {
	card_last4: string
	card_brand: string
	card_expiry: string
	amount: number
	amount_refunded: number
	failure_reason?: string
}

export default class Order implements Filterable {
	constructor(
		public id: number,
		public total: number,
		public account: Account,
		public items: OrderItem[],
		public client_secret: string,
		public payments: Payment[],
		public created_at: string,
		public status: string,
		public billingAddress: Address,
		public vat: number,
		public parent_id: number | null,
	) {}

	public getStatus = (): string => {
		switch (this.status) {
			case 'successful':
				return 'Successful'
			case 'pending_payment':
				return 'Pending Payment'
			case 'payment_failed':
				return 'Payment Failed'
			case 'refunded':
				return 'Refunded'
			case 'partially_refunded':
				return 'Partially Refunded'
			default:
				return 'Unknown'
		}
	}

	public getFilterName = (): string => {
		return 'order'
	}

	public reset = (): Filterable => {
		return createOrder()
	}

	public toFilter = (): any => {
		return {}
	}
}
