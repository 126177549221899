import { HttpResponse } from '@angular/common/http'
import HttpError from '@ui/HttpError'
import { Observable, Subscriber } from 'rxjs'

export default class FileUpload {
	public progress = 0

	public updated = new Observable<FileUpload>(
		(observer: Subscriber<FileUpload>): void => {
			this.interval = setInterval((): void => {
				this.handleUpdate(observer)
			}, 500)
		},
	)

	private interval

	private response: HttpResponse<any> | HttpError

	constructor(
		private readonly file: File,
		private endpoint?: string,
		private entityType?: string,
		private entityId?: number,
	) {}

	public setProgress = (progress: number): void => {
		this.progress = progress
	}

	public setEndpoint = (endpoint: string): void => {
		this.endpoint = endpoint
	}

	public setEntityType = (entityType: string): void => {
		this.entityType = entityType
	}

	public setEntityId = (entityId: number): void => {
		this.entityId = entityId
	}

	public getEndpoint = (): string => this.endpoint

	public getEntityType = (): string => this.entityType

	public getEntityId = (): number => this.entityId

	public getFile = (): File => this.file

	public getFileName = (): string => this.file.name

	public setResponse = (response: HttpResponse<any> | HttpError): void => {
		this.response = response
	}

	public getResponse = (): HttpResponse<any> | HttpError => this.response

	private readonly handleUpdate = (observer: Subscriber<FileUpload>) => {
		observer.next(this)

		if (typeof this.getResponse() !== 'undefined' && this.progress === 100) {
			clearInterval(this.interval)
		}
	}
}
