import {
	Component,
	HostListener,
	Input,
	OnChanges,
	OnInit,
} from '@angular/core'
import Performer from '@account/Performer'
import { Table } from '@ui/Table'
import Pagination from '@ui/Pagination'
import { Router } from '@angular/router'
import OrderItem from '@account/OrderItem'
import { OrderItemService } from '@account/Services/OrderItemService'
import { CdkTableRow } from '@ui/Components/Table/CdkTable.Component'
import { formatDate } from '@ui/utils'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'entries-table',
	templateUrl: './EntriesTable.Component.html',
})
export class EntriesTableComponent
	extends Table<OrderItem>
	implements OnInit, OnChanges
{
	@Input() public items: OrderItem[]

	@Input() public orderItemService: OrderItemService

	@Input() public includeCancelled: boolean

	@Input() public setPaginator: (paginator: Pagination) => Promise<void>

	@Input() public handleCancelFilter: (showCancelled: boolean) => Promise<void>

	public displayColumns: string[] = []

	public tableEntities: Array<CdkTableRow<OrderItem>> = []

	private desktopTableEntities: Array<CdkTableRow<OrderItem>> = []

	private mobileTableEntities: Array<CdkTableRow<OrderItem>> = []

	constructor(
		private readonly router: Router,
		private readonly systemConfigService: SystemConfigService,
	) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.desktopTableEntities = [
			{
				columnDef: 'class_reference',
				header: 'Class Number',
				accessorKey: 'class_reference',
			},
			{
				columnDef: 'category',
				header: 'Category',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.category.name
				},
			},
			{
				columnDef: 'class',
				header: 'Class',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.name
				},
			},
			{
				columnDef: 'school',
				header: 'School',
				accessorCallback(entity: OrderItem): string | number {
					return entity.school.name
				},
			},
			{
				columnDef: 'type',
				header: 'Type',
				accessorCallback(entity: OrderItem): string {
					return entity.performance_class.formattedType()
				},
			},
			{
				header: 'Entry Age',
				columnDef: 'age',
				accessorCallback(item: OrderItem): string {
					return item.performance_class.getAgeRange()
				},
			},
			{
				columnDef: 'performers',
				header: 'Performers',
				accessorCallback(item: OrderItem): string {
					return item.performers
						.map((performer: Performer): string => {
							return `${performer.first_name} ${performer.last_name}`
						})
						.join('<br />')
				},
			},
			{
				columnDef: 'date',
				type: 'datetime',
				header: 'Performance Date',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.starts
				},
				timezone: this.systemConfigService.config.timezone,
			},
		]

		this.mobileTableEntities = [
			{
				columnDef: 'category',
				header: 'Class',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.category.name
				},
			},
			{
				columnDef: 'class',
				header: 'Class',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.name
				},
			},
			{
				columnDef: 'school',
				header: 'School',
				accessorCallback(entity: OrderItem): string | number {
					return entity.school.name
				},
			},
			{
				columnDef: 'type',
				header: 'Type',
				accessorCallback(entity: OrderItem): string {
					return entity.performance_class.formattedType()
				},
			},
			{
				header: 'Entry Age',
				columnDef: 'age',
				accessorCallback(item: OrderItem): string {
					return item.performance_class.getAgeRange()
				},
			},
			{
				columnDef: 'performers',
				header: 'Performers',
				accessorCallback(item: OrderItem): string {
					return item.performers
						.map((performer: Performer): string => {
							return `${performer.first_name} ${performer.last_name}`
						})
						.join('<br />')
				},
			},
			{
				columnDef: 'date',
				type: 'datetime',
				header: 'Performance Date',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.starts
				},
				timezone: this.systemConfigService.config.timezone,
			},
			{
				columnDef: 'expandable',
				type: 'expansion',
				expansion: {
					renderHtml: this.renderRowExpansion,
					elementClick: this.viewEntry,
				},
			},
		]

		this.handleTable(window.innerWidth)
	}

	public ngOnChanges() {
		this.setTableData(this.items)
	}

	public viewEntry = async (item: OrderItem): Promise<void> => {
		await this.router.navigateByUrl(`/entries/${item.id}`)
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 1020) {
			this.tableEntities = this.desktopTableEntities
			this.displayColumns = [
				'class_reference',
				'category',
				'class',
				'school',
				'type',
				'age',
				'performers',
				'date',
			]
		} else if (windowWidth >= 700) {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns = ['category', 'class', 'performers']
		} else {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns = ['class']
		}
	}

	private readonly renderRowExpansion = (entity: OrderItem): string => {
		return `<ul class="class-table-list"><li>Class Number: ${
			entity.class_reference
		}</li><li>Category: ${
			entity.performance_class.category.name
		}</li><li>School: ${
			entity.school.name
		}</li><li>Type: ${entity.performance_class.formattedType()}</li><li>Performers: ${entity.performers
			.map((performer: Performer): string => {
				return `${performer.first_name} ${performer.last_name}`
			})
			.join('<br />')}</li><li>Date: ${formatDate(
			entity.performance_class.starts,
			true,
			this.systemConfigService.config.timezone,
		)}</li></ul>`
	}
}
