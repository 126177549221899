import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
	AutoCompleteOption,
	AutoCompleteOptionValue,
} from '@ui/Components/Form/Autocomplete/Autocomplete.Component'
import {
	PerformerDobFilter,
	PerformerService,
} from '@account/Services/PerformerService'
import Performer from '@account/Performer'
import HttpError from '@ui/HttpError'

@Component({
	selector: 'performer-autocomplete',
	templateUrl: './PerformersAutocomplete.Component.html',
})
export class PerformersAutocompleteComponent {
	@Input() private readonly dobFilter: PerformerDobFilter

	@Output() private readonly performerSelected = new EventEmitter<Performer>()

	public options: AutoCompleteOption[] = []

	private performers: Performer[]

	constructor(private readonly performerService: PerformerService) {
		this.performerService.clearLocalStorageKey().resetFilter()
	}

	public filter = async (value: string | number): Promise<void> => {
		if (typeof value === 'number') {
			return
		}

		const names = value.split(' ')

		this.performerService.filterable.first_name = names[0]

		if (names.length > 1) {
			this.performerService.filterable.last_name = names[names.length - 1]
		}

		try {
			this.performers = await this.performerService.filter({
				dob: this.dobFilter,
			})
			this.options = this.performers.map((performer: Performer) => ({
				value: performer.id,
				label: `${performer.first_name} ${performer.last_name}`,
			}))
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	public selectPerformer = (value: AutoCompleteOptionValue): void => {
		const performer = this.performers.find(
			(search: Performer) => search.id === value,
		)

		this.performerSelected.emit(performer)
	}
}
