import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'

interface Keys {
	stripe: string
}

interface Socials {
	facebook: string
	twitter: string
	instagram: string
}

interface Support {
	email: string
	phone: string
}

interface Vat {
	enabled: boolean
	rate: number
}

export interface SystemConfig {
	currency: string
	currencyDecimalPlaces: number
	locale: string
	name: string
	timezone: string
	keys: Keys
	country: string
	checkoutEnabled: boolean
	ordersEnabled: boolean
	performerTos: string
	socials: Socials
	support: Support
	sentryDsn: string
	sentryRelease: string
	waitingLists: boolean
	vat: Vat
	recaptcha: string
	analytics: string | null
	accountConfirmed: string | null
}

@Injectable()
export class SystemConfigService extends BaseService<any> {
	public config: SystemConfig

	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(httpClient, paginationService)
	}

	public getConfig = async () => {
		const response = await this.get({ url: '/api/account/config' })

		this.config = response
	}
}
