import { Component, Input, OnChanges } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'

export type RenderRowExpansion = (entity: any) => string

export type HandleRowExpansionClick = (entity: any) => void | Promise<void>

@Component({
	templateUrl: './CdkTableExpandedDetail.Component.html',
	selector: 'cdk-table-extended',
	animations: [
		trigger('detailExpand', [
			state(
				'collapsed',
				style({
					height: '0px',
					minHeight: '0',
					visibility: 'hidden',
					padding: '0',
				}),
			),
			state(
				'expanded',
				style({ height: '*', visibility: 'visible', padding: '20px' }),
			),
			transition(
				'expanded <=> collapsed',
				animate('10ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
			),
		]),
	],
})
export class CdkTableExpandedDetailComponent implements OnChanges {
	@Input() public entity: any

	@Input() public expandedEntity: any

	@Input() public renderRowExpansion: RenderRowExpansion

	@Input() public handleRowExpansionClick: HandleRowExpansionClick

	public rowExpansionHtml: string

	public ngOnChanges() {
		this.rowExpansionHtml = this.renderRowExpansion(this.entity)
	}
}
