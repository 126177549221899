<div>
	<dialog-container>
		&nbsp;
		<h5>By clicking 'Confirm Details' you are accepting all of the following:</h5>
		<hr />
		<ul>
			<li>All account details entered are correct should you need to be contacted, most importantly the contact number is up to date</li>
			<li>Every associated performer has the correct academic school saved</li>
			<li>We '{{ systemConfig.config.name }}' may contact you using the given information for any performer you have entered, should we feel it required</li>
		</ul>
	</dialog-container>
	<dialog-actions-container [styles]="{ display: 'block', 'margin-bottom': '10px' }">
		<div [ngStyle]="{ float: 'right' }">
			<default-button color="warn" (click)="closeDialog()">Cancel</default-button>
			<default-button color="primary" (click)="confirmDetails()">Confirm Details</default-button>
		</div>
	</dialog-actions-container>
</div>
