import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import PaymentMethod from '@account/PaymentMethod'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import { createPaymentMethod, mapCardBrandToLabel } from '@account/utils'

@Injectable()
export class PaymentMethodService extends BaseService<PaymentMethod> {
	public filterable: PaymentMethod

	protected endpoint = '/api/account/payment-methods'

	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(httpClient, paginationService)

		this.filterable = createPaymentMethod()
	}

	public deleteMethod = async (paymentMethod: PaymentMethod): Promise<void> => {
		await this.delete(`${this.endpoint}/${paymentMethod.id}`)
	}

	public mapToObject = (json): PaymentMethod => {
		return new PaymentMethod(
			json.id,
			json.remote_id,
			mapCardBrandToLabel(json.brand),
			json.last4,
			json.expiry,
		)
	}
}
