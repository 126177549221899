import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { AudioPlayable } from '@ui/AudioPlayable'

type AudioSource = { src: string; type: string }

@Component({
	selector: 'audio-player',
	templateUrl: './AudioPlayer.Component.html',
})
export class AudioPlayerComponent implements OnInit, OnChanges {
	@Input() private readonly audioItems: AudioPlayable[] = []

	public audioSources: AudioSource[] = []

	public ngOnInit() {
		this.mapInputs()
	}

	public ngOnChanges() {
		this.mapInputs()
	}

	public audioSourceTracker = (index: number) => index

	private readonly mapInputs = () => {
		this.audioSources = this.audioItems.map((item: AudioPlayable) => ({
			src: item.getPublicUrl(),
			type: item.getMimeType(),
		}))
	}
}
