import { FormControl } from '@angular/forms'

const emailRegex = new RegExp(
	/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*/.source +
		/@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
			.source,
)

export class CustomValidators {
	public static emailValidator(control: FormControl) {
		const value: string = control.value.toString()

		if (value === '') {
			return undefined
		}

		if (!value.match(emailRegex)) {
			return {
				invalidEmailAddress: true,
			}
		}
	}

	public static numberValidator(control: FormControl) {
		const value: string = control.value.toString()

		if (value === '') {
			return undefined
		}

		try {
			if (!value.match(/^\d+$/)) {
				return { invalidNumber: true }
			}
		} catch (error) {
			console.warn(error)
		}
	}

	public static currencyValidator(control: FormControl) {
		const value: string = control.value.toString()

		if (value === '') {
			return undefined
		}

		try {
			if (!value.match(/^[0-9]+(\.[0-9]{1,2})?$/)) {
				return { invalidCurrency: true }
			}
		} catch (error) {
			console.warn(error)
		}
	}

	public static percentValidator(control: FormControl) {
		const value: string = control.value.toString()

		if (value === '') {
			return undefined
		}

		try {
			if (!value.match(/^[1-9][0-9]?$|^100$/)) {
				return { invalidPercent: true }
			}
		} catch (error) {
			console.warn(error)
		}
	}

	public static mobileValidator(control: FormControl) {
		const value: string = control.value.toString()

		if (value === '') {
			return undefined
		}

		try {
			if (!value.match(/^07\d{9}$/)) {
				return { invalidMob: true }
			}
		} catch (error) {
			console.warn(error)
		}
	}
}
