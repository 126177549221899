import { Component, Input } from '@angular/core'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'date-time',
	template:
		'<date-time-component-inner [date]="date" [includeTime]="includeTime" [timezone]="timezone"></date-time-component-inner>',
})
export class DateTimeComponent {
	@Input() public date: string

	@Input() public includeTime = false

	public timezone: string

	constructor(private readonly systemConfigService: SystemConfigService) {
		this.timezone = systemConfigService.config.timezone
	}
}
