import { ApiDatabaseMocker } from '@ui/Table/ApiDatabaseMocker'
import { ApiDataSource } from '@ui/Table/ApiDataSource'
import { CdkTableRow } from '@ui/Components/Table/CdkTable.Component'
import { CdkDragDrop } from '@angular/cdk/drag-drop'

export class Table<T> {
	public displayColumns: string[]

	public dataSource: ApiDataSource<T>

	public tableEntities: Array<CdkTableRow<T>>

	protected apiDatabase = new ApiDatabaseMocker<T>()

	private tableData: T[]

	protected readonly setTableData = (data: T[]) => {
		this.tableData = data
		this.dataSource = new ApiDataSource(this.apiDatabase)
		this.apiDatabase.assignData(this.tableData)
	}

	protected readonly reorderRows = async (
		event: CdkDragDrop<T>,
		callback?: (items: Array<T>) => Promise<Array<T>>,
	): Promise<void> => {
		await this.apiDatabase.reorderData(event, callback)
	}
}
