import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import Account from '@account/Account'
import { AccountService } from '@account/Services/AccountService'
import BaseService from '@ui/Services/BaseService'
import { PaginationService } from '@ui/Services/PaginationService'

export interface TwoFactorQrCode {
	svg: string
	url: string
}

export interface TwoFactorSecret {
	secretKey: string
}

@Injectable()
export class PermissionsService extends BaseService<Account> {
	public account: Account

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
		private readonly accountService: AccountService,
	) {
		super(http, paginationService)
	}

	public getPermissions = async (): Promise<void> => {
		const response: any = await this.get({ url: '/api/account/me' })

		this.account = response.id
			? this.accountService.mapToObject(response)
			: undefined
	}

	public confirmPassword = async (password: string): Promise<void> => {
		await this.post('/api/account/user/confirm-password', { password })
	}

	public enableTwoFactor = async (): Promise<void> => {
		await this.post('/api/account/user/two-factor-authentication', {})
	}

	public getTwoFactorQrCode = async (): Promise<TwoFactorQrCode> => {
		return await this.get({ url: '/api/account/user/two-factor-qr-code' })
	}

	public getTwoFactorSecret = async (): Promise<TwoFactorSecret> => {
		return await this.get({ url: '/api/account/user/two-factor-secret-key' })
	}

	public getTwoFactorRecoveryCodes = async (): Promise<string[]> => {
		return await this.get({
			url: '/api/account/user/two-factor-recovery-codes',
		})
	}

	public confirmTwoFactor = async (code: string): Promise<void> => {
		await this.post('/api/account/user/confirmed-two-factor-authentication', {
			code,
		})
	}

	public async disableTwoFactor(): Promise<void> {
		await this.delete('/api/account/user/two-factor-authentication')
	}

	public async regenerateTwoFactorRecoveryCodes(): Promise<void> {
		return await this.post('/api/account/user/two-factor-recovery-codes', {})
	}
}
