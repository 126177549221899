<account-page title="Security">
	<card-component>
		<card-title title="Two Factor Authentication"></card-title>
		<card-content>
			<div *ngIf="!permissionsService.account.twoFactorEnabled && !qrCode">
				<p>
					<i class="fa fa-shield"></i>
					We recommend securing your account using 2FA.
					This will reduce the risk of unauthorized access to your account by prompting fot a 6 digit code when you sign in.
				</p>
				<raised-button color="primary" (click)="enableTwoFactor()">Enable 2FA</raised-button>
			</div>
			<div *ngIf="!permissionsService.account.twoFactorEnabled && qrCode">
				<p>
					<i class="fa fa-shield"></i>
					First you will need to download a <a target="_blank" href="https://help.insightful.io/en/articles/4294106-supported-authenticator-apps-2fa">Two Factor Authentication App</a>.
				</p>
				<p>
					<i class="fa fa-camera"></i>
					Once completed you will need to scan the below QR code using the app. This will then generate a 6 digit code that you will need to enter below.
				</p>
				<div [innerHTML]="transform(qrCode)"></div>
				<br />
				<p>
					<i class="fa fa-pencil"></i>
					If you are unable to scan the QR code then enter the below bit of text into your authentication app instead.
				</p>
				<pre class="security">{{ secret }}</pre>
				<br />
				<form [formGroup]="form">
					<text-input placeHolder="2FA code" [form]="form" formControlName="code" [error]="formErrors.code"></text-input>
					<raised-button color="primary" (click)="confirmTwoFactor()">Confirm 2FA</raised-button>
				</form>
				<br />
				<p>
					<i class="fa fa-eye"></i>
					Finally keep the below recovery codes safe. These can be used to access your account if you lose access to your 2FA device.
				</p>
				<pre class="security" *ngIf="recoveryCodes">
					<span *ngFor="let code of recoveryCodes; trackBy: codeTracker;"><br />{{ code }}</span>
				</pre>
			</div>
			<div *ngIf="permissionsService.account.twoFactorEnabled">
				<p>
					<i class="fa fa-shield"></i>
					Two factor authentication is enabled.
				</p>
				<raised-button color="accent" (click)="regenerateRecoveryCodes()">Regenerate Recovery Codes</raised-button>
				<span class="spacer"></span>
				<raised-button color="warn" (click)="disableTwoFactor()">Disable 2FA</raised-button>
				<div *ngIf="recoveryCodes">
					<br />
					<p>
						<i class="fa fa-recycle"></i>
						Recovery codes have been regenerated, please discard the old codes and keep these ones safe.
					</p>
					<pre class="security">
						<span *ngFor="let code of recoveryCodes; trackBy: codeTracker;"><br />{{ code }}</span>
					</pre>
				</div>
			</div>
		</card-content>
	</card-component>
</account-page>
