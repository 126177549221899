<div>
	<dialog-container>
		<h4>Are you sure you wish to perform this action?</h4>
	</dialog-container>
	<dialog-actions-container [styles]="{ display: 'block', 'margin-bottom': '10px' }">
		<div [ngStyle]="{float: 'right', 'text-align': 'right'}">
			<default-button color="warn" (click)="confirmAndClose()">Yes</default-button>
			<default-button color="primary" (click)="closeDialog()">No</default-button>
		</div>
	</dialog-actions-container>
</div>
