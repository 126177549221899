<span [formGroup]="form" [ngStyle]="{ width: '100%' }">
	<mat-form-field [ngStyle]="{ width: '100%', float: 'right', 'z-index': 30 }" subscriptSizing="dynamic" appearance="fill">
		<mat-label>{{ placeHolder }}</mat-label>
		<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
		<mat-datepicker #dp (closed)="outputDate()"></mat-datepicker>
		<input
			matInput
			[matDatepicker]="dp"
			[placeholder]="placeHolder"
			[formControlName]="formControlName"
			(click)="dp.open()"
			ngDefaultControl
		/>
		<mat-error>{{ error }}</mat-error>
	</mat-form-field>
</span>
