import Filterable from '@ui/Filterable'
import { createAddress } from '@account/utils'

export const BILLING = 'billing'

export const ACCOUNT = 'account'

export default class Address implements Filterable {
	constructor(
		public id: number,
		public address1: string,
		public address2: string,
		public city: string,
		public county: string,
		public postcode: string,
		public type: string,
	) {}

	public getFilterName = (): string => {
		return 'address'
	}

	public reset = (): Filterable => {
		return createAddress()
	}

	public toFilter = (): any => {
		return { ...this }
	}
}
