import { Component, EventEmitter, Input, Output } from '@angular/core'
import Pagination from '@ui/Pagination'
import ListItem, { DescriptionInput } from '@ui/ListItem'
import { ListItemAction } from '@ui/Components/List/ListItemAction.Component'
import { ListFilter } from '@ui/Components/List/ListFilter.Component'
import { ListAction } from '@ui/Components/List/ListAction.Component'
import { SelectionModel } from '@angular/cdk/collections'
import QueryModifiers from '@ui/QueryModifiers'
import { Sortable } from '@ui/Sortable'

@Component({
	selector: 'list-component',
	templateUrl: './List.Component.html',
})
export class ListComponent {
	@Input() public items: ListItem[]

	@Input() public paginator: Pagination

	@Input() public queryModifiers: QueryModifiers

	@Input() public model: Sortable

	@Input() public itemTracker: (index: number, item: ListItem) => number

	@Input() public setPaginator: (paginator: Pagination) => void

	@Input() public setQueryModifiers: (queryModifiers: QueryModifiers) => void

	@Input() public requesting: boolean

	@Input() public actions: ListAction[]

	@Input() public itemActions: ListItemAction[]

	@Input() public filters: ListFilter<any>[]

	@Input() public totalIcon: string

	@Input() public descriptionParams: DescriptionInput = {}

	@Input() public deleteSelection: SelectionModel<ListItem>

	@Output() private readonly deleteSelectionChecked =
		new EventEmitter<ListItem>()

	public filterTracker = (index: number): number => index

	public actionTracker = (index: number): number => index

	public handleToggle = (item: ListItem) => {
		this.deleteSelectionChecked.emit(item)
	}
}
