import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import {
	loadStripe,
	Stripe,
	StripeCardCvcElement,
	StripeCardExpiryElement,
	StripeCardNumberElement,
} from '@stripe/stripe-js'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'
import HttpError, { FormErrors } from '@ui/HttpError'
import { CartService } from '@account/Services/CartService'
import { OrderService } from '@account/Services/OrderService'
import { Router } from '@angular/router'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'
import { PaymentMethodService } from '@account/Services/PaymentMethodService'
import PaymentMethod from '@account/PaymentMethod'
import Address from '@account/Address'

@Component({
	selector: 'checkout-payment-form',
	templateUrl: './CheckoutPaymentForm.Component.html',
	styleUrls: ['./CheckoutPaymentForm.Component.css'],
})
export class CheckoutPaymentFormComponent
	extends FormValidationComponent
	implements OnInit
{
	@ViewChild('cardNumber') private readonly cardNumber: ElementRef

	@ViewChild('cardBaseLine') private readonly cardBaseLine: ElementRef

	@ViewChild('cardExpiry') private readonly cardExpiry: ElementRef

	@ViewChild('expiryBaseLine') private readonly expiryBaseLine: ElementRef

	@ViewChild('cardCvc') private readonly cardCvc: ElementRef

	@ViewChild('cvcBaseLine') private readonly cvcBaseLine: ElementRef

	@Input() private readonly billingAddress: Address

	public formErrors: FormErrors = {
		name: '',
	}

	public error: string

	public loading = false

	public saveCard = false

	public paymentMethods: SelectItem[] = []

	public paymentMethod: SelectValue

	protected validationMessages: ValidationMessages = {
		name: {
			required: 'Name on card is required',
		},
	}

	private stripe: Stripe

	private card: StripeCardNumberElement

	private expiry: StripeCardExpiryElement

	private cvc: StripeCardCvcElement

	constructor(
		protected formBuilder: FormBuilder,
		private readonly systemConfigService: SystemConfigService,
		private readonly cartService: CartService,
		private readonly orderService: OrderService,
		private readonly router: Router,
		private readonly paymentMethodService: PaymentMethodService,
	) {
		super(formBuilder)
	}

	public ngOnInit() {
		super.ngOnInit()

		this.paymentMethodService.clearLocalStorageKey().resetFilter()

		this.configureStripe()

		this.fetchPaymentMethods()
	}

	public handleSubmit = async (name: InputValue) => {
		if (this.loading) {
			return
		}

		this.loading = true

		this.error = ''

		if (this.paymentMethod) {
			await this.createOrder(this.paymentMethod as string, false)
		} else {
			this.validateForm()

			if (this.form.valid) {
				const response = await this.stripe.createPaymentMethod({
					type: 'card',
					card: this.card,
					billing_details: {
						name: name as string,
						address: {
							line1: this.billingAddress.address1,
							postal_code: this.billingAddress.postcode,
						},
					},
				})

				if (response.paymentMethod) {
					await this.createOrder(response.paymentMethod.id, this.saveCard)
				} else if (response.error) {
					this.error = response.error.message
				}
			}
		}

		this.loading = false
	}

	protected buildForm = () => {
		this.form = this.formBuilder.group({
			name: ['', [Validators.required]],
		})
	}

	private readonly configureStripe = async () => {
		this.stripe = await loadStripe(this.systemConfigService.config.keys.stripe)

		const elementClasses = {
			focus: 'focused',
			empty: 'empty',
			invalid: 'invalid',
		}

		const elements = this.stripe.elements()

		// https://github.com/stripe/elements-examples/tree/7cb00ab571c98a68c72bb67243d35cff3e2b560e

		const style = {
			base: {
				color: 'rgba(0, 0, 0, 0.87)',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#5b7784',
				},
				padding: '24px 0 8px 0',
				backgroundColor: '#ececec',
			},
			invalid: {
				color: '#ff0000',
				iconColor: '#ff0000',
			},
		}

		this.card = elements.create('cardNumber', {
			showIcon: true,
			style,
			classes: elementClasses,
		})

		this.card.mount('#cardNumber')

		this.card.on('focus', () => {
			this.cardNumber.nativeElement.classList.add('focused')

			return true
		})

		this.card.on('blur', () => {
			this.cardNumber.nativeElement.classList.remove('focused')

			return true
		})

		this.card.on('change', (event) => {
			if (event.empty) {
				this.cardNumber.nativeElement.classList.add('empty')
			} else {
				this.cardNumber.nativeElement.classList.remove('empty')
			}

			if (event.error) {
				this.cardBaseLine.nativeElement.classList.add('card-error')
			} else {
				this.cardBaseLine.nativeElement.classList.remove('card-error')
			}

			return true
		})

		this.expiry = elements.create('cardExpiry', {
			style,
			classes: elementClasses,
		})

		this.expiry.mount('#cardExpiry')

		this.expiry.on('focus', () => {
			this.cardExpiry.nativeElement.classList.add('focused')

			return true
		})

		this.expiry.on('blur', () => {
			this.cardExpiry.nativeElement.classList.remove('focused')

			return true
		})

		this.expiry.on('change', (event) => {
			if (event.empty) {
				this.cardExpiry.nativeElement.classList.add('empty')
			} else {
				this.cardExpiry.nativeElement.classList.remove('empty')
			}

			if (event.error) {
				this.expiryBaseLine.nativeElement.classList.add('card-error')
			} else {
				this.expiryBaseLine.nativeElement.classList.remove('card-error')
			}

			return true
		})

		this.cvc = elements.create('cardCvc', {
			style,
			classes: elementClasses,
		})

		this.cvc.mount('#cardCvc')

		this.cvc.on('focus', () => {
			this.cardCvc.nativeElement.classList.add('focused')

			return true
		})

		this.cvc.on('blur', () => {
			this.cardCvc.nativeElement.classList.remove('focused')

			return true
		})

		this.cvc.on('change', (event) => {
			if (event.empty) {
				this.cardCvc.nativeElement.classList.add('empty')
			} else {
				this.cardCvc.nativeElement.classList.remove('empty')
			}

			if (event.error) {
				this.cvcBaseLine.nativeElement.classList.add('card-error')
			} else {
				this.cvcBaseLine.nativeElement.classList.remove('card-error')
			}

			return true
		})
	}

	private readonly fetchPaymentMethods = async (): Promise<void> => {
		try {
			const paymentMethods = await this.paymentMethodService.filter()

			this.paymentMethods = paymentMethods.map(
				(paymentMethod: PaymentMethod): SelectItem => ({
					value: paymentMethod.remote_id,
					label: `${paymentMethod.brand} ending in ${paymentMethod.last4}`,
				}),
			)

			this.paymentMethods.unshift({ value: undefined, label: 'Use new card' })
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	private readonly createOrder = async (
		paymentMethod: string,
		saveCard: boolean,
	): Promise<void> => {
		try {
			const order = await this.orderService.create(
				this.cartService.cart,
				paymentMethod,
				undefined,
				saveCard,
			)

			await this.router.navigateByUrl(`/orders/${order.id}`)
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.status === 402) {
					const scaResponse = await this.stripe.confirmCardPayment(
						error.scaErrors.secret,
						{
							payment_method: error.scaErrors.token,
						},
					)

					if (scaResponse.error) {
						this.error = scaResponse.error.message
					} else {
						await this.router.navigateByUrl(
							`/orders/${error.scaErrors.order.id}`,
						)
					}
				}
			} else {
				throw error
			}
		}
	}
}
