<account-page>
	<vip-paginator
		[paginator]="classService.paginator"
		[requesting]="classService.loading"
		(pageChanged)="setPaginator($event)"
	></vip-paginator>
	<div class="flex-filters filters">
		<div class="flex-filter-item">
			<text-filter
				prefixIcon="search"
				placeHolder="Category"
				[text]="classService.filterable.category.name"
				(textChange)="filterCategoryName($event)"
			></text-filter>
		</div>
		<div class="flex-filter-item">
			<text-filter
				prefixIcon="search"
				placeHolder="Class"
				[text]="classService.filterable.name"
				(textChange)="filterClassName($event)"
			></text-filter>
		</div>
		<div class="flex-filter-item">
			<text-filter
				prefixIcon="search"
				placeHolder="Class Number"
				[text]="classService.filterable.class_number"
				(textChange)="filterNumber($event)"
			></text-filter>
		</div>
		<div class="flex-filter-item">
			<select-component
				prefixIcon="search"
				placeholder="Class Type"
				[items]="types"
				[value]="classService.filterable.type"
				(valueChange)="filterClassType($event)"
			></select-component>
		</div>
	</div>
	<classes-table [classes]="classes"></classes-table>
</account-page>
