import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { formatDate } from '@ui/utils'

@Component({
	selector: 'date-time-component-inner',
	template: `
		<div [ngStyle]="{ display: 'inline-block' }">{{ formattedTime }}</div>
	`,
})
export class DateTimeComponent implements OnInit, OnChanges {
	@Input() public date: string

	@Input() public timezone = 'utc'

	@Input() public includeTime = false

	public formattedTime: string

	public ngOnInit() {
		this.setUpComponent()
	}

	public ngOnChanges(): void {
		this.setUpComponent()
	}

	private readonly setUpComponent = () => {
		this.formattedTime = formatDate(this.date, this.includeTime, this.timezone)
	}
}
