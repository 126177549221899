<form [formGroup]="form" *ngIf="form">
	<select-component
		[styles]="{ 'margin-top': '20px', 'width': '100%' }"
		[items]="paymentMethods"
		[(value)]="paymentMethod"
		placeholder="Select Card..."
	></select-component>
	<div [ngStyle]="{ display: paymentMethod ? 'none' : 'block' }">
		<text-input
			#name
			formControlName="name"
			placeHolder="Name on card"
			[form]="form"
			[error]="formErrors.name"
		></text-input>
		<div class="card-container">
			<div class="row" style="margin: 0">
				<div class="field">
					<div id="cardNumber" class="input empty" #cardNumber></div>
					<label for="cardNumber">Card number *</label>
					<div class="baseline" #cardBaseLine></div>
				</div>
			</div>
			<div class="row" style="margin: 0">
				<div class="field half-width">
					<div id="cardExpiry" class="input empty" #cardExpiry></div>
					<label for="cardExpiry">Expiry *</label>
					<div class="baseline" #expiryBaseLine></div>
				</div>
				<div class="field half-width">
					<div id="cardCvc" class="input empty" #cardCvc></div>
					<label for="cardExpiry">CVC *</label>
					<div class="baseline" #cvcBaseLine></div>
				</div>
			</div>
		</div>
		<form-checkbox
			id="saveCard"
			label="Save Card"
			[styles]="{ 'margin-top': '10px' }"
			[(checked)]="saveCard"
		></form-checkbox>
	</div>
	<div [ngStyle]="{ 'margin-top': '20px' }">
		<form-error [error]="error"></form-error>
	</div>
	<div [ngStyle]="{ 'margin-top': '20px' }">
		<raised-button color="primary" [styles]="{ width: '100%' }" (click)="handleSubmit(name.value)" [disabled]="loading">Pay now</raised-button>
	</div>
</form>
