import { Injectable } from '@angular/core'
import {
	HttpClient,
	HttpEventType,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http'
import FileUpload from '@ui/FileUpload'
import HttpError from '@ui/HttpError'

@Injectable()
export class FileUploaderService {
	constructor(private readonly http: HttpClient) {}

	public upload = (fileUpload: FileUpload): FileUpload => {
		const form = new FormData()

		form.append('upload', fileUpload.getFile(), fileUpload.getFileName())

		if (fileUpload.getEntityType()) {
			form.append('type', fileUpload.getEntityType())
		}

		if (fileUpload.getEntityId()) {
			form.append('entity_id', fileUpload.getEntityId().toString())
		}

		const req = new HttpRequest('POST', fileUpload.getEndpoint(), form, {
			reportProgress: true,
		})

		this.http.request(req).subscribe(
			(event: any) => {
				if (event.type === HttpEventType.UploadProgress) {
					fileUpload.setProgress((event.loaded / event.total) * 100)
				} else if (event instanceof HttpResponse) {
					fileUpload.setResponse(event)
					this.uploadComplete(fileUpload)
				}
			},
			(error: HttpError) => {
				fileUpload.setResponse(error)
				this.uploadComplete(fileUpload)
			},
		)

		return fileUpload
	}

	public uploadComplete = (fileUpload: FileUpload): FileUpload => fileUpload
}
