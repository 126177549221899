import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import ClassCategory from '@account/ClassCategory'
import { createClassCategory } from '@account/utils'

@Injectable()
export class CategoryService extends BaseService<ClassCategory> {
	public category: ClassCategory

	public filterable: ClassCategory

	protected endpoint = '/api/account/categories'

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(http, paginationService)

		this.filterable = createClassCategory()
	}

	public find = async (id: number): Promise<ClassCategory> => {
		const response = await this.get({ url: `/api/account/categories/${id}` })

		return this.mapToObject(response)
	}

	public mapToObject = (json: any): ClassCategory => {
		return new ClassCategory(json.id, json.name, json.description)
	}
}
