import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import Address from '@account/Address'
import { createAddress } from '@account/utils'

@Injectable()
export class AddressService extends BaseService<Address> {
	public filterable: Address

	protected endpoint = '/api/account/addresses'

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(http, paginationService)
		this.filterable = createAddress()
	}

	public create = async (address: Address): Promise<Address> => {
		const response = await this.post('/api/account/addresses', {
			address,
		})

		return this.mapToObject(response)
	}

	public deleteAddress = async (address: Address): Promise<void> => {
		await this.delete(`/api/account/addresses/${address.id}`)
	}

	public mapToObject = (json): Address => {
		return new Address(
			json.id,
			json.address1,
			json.address2,
			json.city,
			json.county,
			json.postcode,
			json.type,
		)
	}
}
