export default class Pagination {
	constructor(
		public current_page: number,
		public data: object,
		public from: number,
		public last_page: number,
		public next_page_url: string,
		public per_page: number,
		public prev_page_url: string,
		public to: number,
		public total: number,
		public pages: number[],
	) {}

	public incrementTotal = (): void => {
		this.total++
	}

	public decrementTotal = (): void => {
		this.total--
	}
}
