<div class="flex-container">
	<div class="flex-item" [ngStyle]="{ 'max-width': '800px', 'width': '90%' }">
		<card-component>
			<card-content>
				<div class="reset-password">
					<register-form-component
						[registerSuccessful]="onRegisterSuccessful"
					></register-form-component>
				</div>
			</card-content>
		</card-component>
	</div>
</div>
