import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { MatRadioChange } from '@angular/material/radio'

export type RadioOptionValue = string | number

export interface RadioOption {
	label: string
	value: RadioOptionValue
}

@Component({
	selector: 'radio-group',
	templateUrl: './RadioGroup.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: RadioGroupComponent,
			multi: true,
		},
	],
})
export class RadioGroupComponent implements ControlValueAccessor {
	@Input() public value: RadioOptionValue

	@Input() public readonly form: FormGroup

	@Input() public readonly id: string

	@Input() public readonly error: string

	@Input() public readonly formControlName: string

	@Input() public readonly options: RadioOption[]

	@Output() public readonly valueChange = new EventEmitter<RadioOptionValue>()

	private onTouched: () => void

	private onChange: (value: string) => void

	public handleChange = (event: MatRadioChange): void => {
		this.value = event.value
		this.valueChange.emit(this.value)
	}

	public registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public writeValue(value: string): void {
		this.value = value
		this.valueChange.emit(this.value)
	}

	public optionTracker = (index: number) => index
}
