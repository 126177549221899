import { Injectable } from '@angular/core'
import Pagination from '@ui/Pagination'
import { makePaginator } from '@ui/utils'

@Injectable()
export class PaginationService {
	public newUp = (
		localStorageKey: string | undefined,
		bypassStorage = false,
	): Pagination => {
		if (bypassStorage) {
			return makePaginator()
		}

		const paginator = localStorage.getItem(`${localStorageKey}-paginator`)

		return paginator ? this.mapToObject(JSON.parse(paginator)) : makePaginator()
	}

	public countPages = (to: number): number[] => {
		const pages = []

		for (let count = 1; count <= to; count++) {
			pages.push(count)
		}

		return pages
	}

	public mapToObject = (paginator): Pagination => {
		return new Pagination(
			paginator.current_page,
			paginator.data,
			paginator.from,
			paginator.last_page,
			paginator.next_page_url,
			paginator.per_page,
			paginator.prev_page_url,
			paginator.to,
			paginator.total,
			this.countPages(paginator.last_page),
		)
	}
}
