<span *ngIf="form" [formGroup]="form">
	<mat-checkbox
		[id]="id"
		[formControlName]="formControlName"
		[disabled]="disabled"
		[ngStyle]="styles"
		(change)="handleChange($event)"
		ngDefaultControl
	>
		<span [innerHTML]="label"></span>
	</mat-checkbox>
	<mat-error>{{ error }}</mat-error>
</span>
<span *ngIf="!form">
	<mat-checkbox
		[id]="id"
		(change)="handleChange($event)"
		[checked]="checked"
		[disabled]="disabled"
		[ngStyle]="styles"
		ngDefaultControl
	>
		<span [innerHTML]="label"></span>
	</mat-checkbox>
	<mat-error>{{ error }}</mat-error>
</span>
