import { Observable } from 'rxjs/index'
import { DataSource } from '@angular/cdk/collections'
import { ApiDatabaseMocker } from '@ui/Table/index'

export class ApiDataSource<T> extends DataSource<T> {
	constructor(private readonly _exampleDatabase: ApiDatabaseMocker<T>) {
		super()
	}

	public get length(): number {
		return this.getTotalRows()
	}

	public connect(): Observable<T[]> {
		return this._exampleDatabase.dataChange
	}

	public disconnect() {
		return
	}

	public getTotalRows = (): number => {
		return this._exampleDatabase.data.length
	}
}
