import ClassCategory from '@account/ClassCategory'
import Filterable from '@ui/Filterable'
import { createClass } from '@account/utils'
import { differenceInYears, isAfter, isWithinInterval } from 'date-fns'
import { formatDate } from '@ui/utils'

export const SOLO = 'solo'

export const DUET = 'duet'

export const GROUP = 'group'

export const MINI_GROUP = 'mini_group'

export const TRIO_QUARTET = 'trio_quartet'

export const ENTRY_LOCAL = 'local'

export const ENTRY_OPEN = 'open'

export default class Class implements Filterable {
	constructor(
		public id: number,
		public class_number: string,
		public category: ClassCategory,
		public name: string,
		public description: string,
		public amount: number,
		public youngest_dob: string,
		public eldest_dob: string,
		public type: string,
		public entry_type: string,
		public group_max: number,
		public max_entries: number,
		public terms: string,
		public starts: string,
		public ends: string,
		public bookings_open: string,
		public bookings_close: string,
		public total_entries: number,
		public vat: number,
	) {}

	public formattedType = (): string => {
		switch (this.type) {
			case SOLO:
				return 'Solo'
			case DUET:
				return 'Duet'
			case TRIO_QUARTET:
				return 'Trio/Quartet'
			case MINI_GROUP:
				return 'Mini Group'
			case GROUP:
				return 'Group'
			default:
				return 'Unknown'
		}
	}

	public getFormattedEntryType = (): string => {
		switch (this.entry_type) {
			case ENTRY_LOCAL:
				return 'Local'
			case ENTRY_OPEN:
				return 'Open'
			default:
				return 'None'
		}
	}

	public getAgeRange = (): string => {
		const now = new Date()

		const eldestDob = new Date(this.eldest_dob)

		const youngestDob = new Date(this.youngest_dob)

		return `${differenceInYears(now, youngestDob)} - ${differenceInYears(
			now,
			eldestDob,
		)}`
	}

	public getCurrentStatus = (timezone: string): string => {
		const isBetween = isWithinInterval(new Date(), {
			start: new Date(this.bookings_open),
			end: new Date(this.bookings_close),
		})

		if (isAfter(new Date(), new Date(this.bookings_close))) {
			return 'Closed'
		}

		return isBetween ? 'Now' : formatDate(this.bookings_open, true, timezone)
	}

	public getFilterName = (): string => {
		return 'class'
	}

	public reset = (): Filterable => {
		return createClass()
	}

	public toFilter(): any {
		return {
			name: this.name,
			category: {
				id: this.category.id,
				name: this.category.name,
			},
			type: this.type,
			class_number: this.class_number,
			starts: this.starts ?? undefined,
		}
	}
}
