import { Component } from '@angular/core'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'index-component',
	templateUrl: './Index.Component.html',
})
export class IndexComponent {
	constructor(public systemConfigService: SystemConfigService) {}
}
