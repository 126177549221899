import Filterable from '@ui/Filterable'
import { createPerformer } from '@account/utils'
import School from '@account/School'

export type AccountRelationship =
	| 'parent'
	| 'guardian'
	| 'grandparent'
	| 'other'

export default class Performer implements Filterable {
	constructor(
		public id: number,
		public first_name: string,
		public last_name: string,
		public school: string,
		public dob: Date,
		public gender: string,
		public tos_accepted: boolean,
		public created_at: string,
		public schools: School[],
		public account_relationship: AccountRelationship,
		public uuid: string,
	) {}

	public getFilterName = (): string => {
		return 'performer'
	}

	public reset = (): Filterable => {
		return createPerformer()
	}

	public toFilter = (): any => {
		return {
			first_name: this.first_name,
			last_name: this.last_name,
		}
	}
}
