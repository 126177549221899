<div *ngIf="selectedCategory">
	<div [ngStyle]="{ 'overflow': 'scroll' }">
		<button-toggle-group
			[options]="classCategoryOptions"
			[value]="selectedCategory.id"
			(valueChange)="selectCategory($event)"
		></button-toggle-group>
	</div>
	<div [ngStyle]="{ 'margin-top': '15px' }" class="filters">
		<text-filter
			prefixIcon="search"
			placeHolder="School name"
			[text]="schoolsService.filterable.name"
			(textChange)="filterSchoolName($event)"
		></text-filter>
	</div>
	<h4 *ngIf="selectedSchool">Current: {{ selectedSchool.name }}</h4>
	<vip-paginator
		[paginator]="schoolsService.paginator"
		[requesting]="schoolsService.loading"
		(pageChanged)="setPaginator($event)"
	></vip-paginator>
	<div [ngStyle]="{ 'margin-top': '15px' }">
		<app-loader [loading]="schoolsService.loading" height="30px"></app-loader>
		<radio-group
			[options]="schoolOptions"
			[value]="selectedSchoolId"
			(valueChange)="handleSchoolSelected($event)"
		></radio-group>
	</div>
	<div [ngStyle]="{ 'margin-top': '15px' }">
		<raised-button color="warn" (click)="removeSchool()">Remove School</raised-button>
	</div>
</div>
