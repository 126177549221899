import { Component, Input, OnChanges } from '@angular/core'

@Component({
	selector: 'base-page',
	templateUrl: './Page.Component.html',
})
export class PageComponent implements OnChanges {
	@Input() public title: string

	@Input() public styles: { [key: string]: string | number } = {
		clear: 'both',
		width: '100%',
		padding: '10px',
	}

	public ngOnChanges(): void {
		document.title = this.title
	}
}
