<div class="flex-container">
	<div class="form-outter">
		<card-component>
			<card-content>
				<div class="login">
					<login-form [loginSuccessful]="redirect" [displayRegister]="true" [showChallenge]="displayChallengeScreen" *ngIf="!showChallenge"></login-form>
					<two-factor-challenge *ngIf="showChallenge" [loginSuccessful]="redirect"></two-factor-challenge>
				</div>
			</card-content>
		</card-component>
	</div>
</div>


