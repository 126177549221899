import { Component, OnInit } from '@angular/core'
import Order from '@account/Order'
import { OrderService } from '@account/Services/OrderService'
import { Table } from '@ui/Table'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import HttpError from '@ui/HttpError'
import Pagination from '@ui/Pagination'
import { Router } from '@angular/router'

@Component({
	selector: 'orders-component',
	templateUrl: './Orders.Component.html',
})
export class OrdersComponent extends Table<Order> implements OnInit {
	public displayColumns = ['date', 'amount', 'status']

	private orders: Order[]

	constructor(
		public ordersService: OrderService,
		private readonly systemConfigService: SystemConfigService,
		private readonly router: Router,
	) {
		super()
	}

	public ngOnInit() {
		this.tableEntities = [
			{
				header: 'Date',
				columnDef: 'date',
				accessorKey: 'created_at',
				type: 'datetime',
				timezone: this.systemConfigService.config.timezone,
			},
			{
				header: 'Amount',
				columnDef: 'amount',
				accessorKey: 'total',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Status',
				columnDef: 'status',
				accessorCallback(entity: Order): string {
					return entity.getStatus()
				},
			},
		]

		this.ordersService.setLocalStorageKey('a-orders-page').resetFilter()

		this.fetchOrders()
	}

	public setPaginator = async (paginator: Pagination) => {
		this.ordersService.setPaginator(paginator)
		await this.fetchOrders()
	}

	public viewOrder = (order: Order) => {
		this.router.navigateByUrl(`/orders/${order.id}`)
	}

	private readonly fetchOrders = async () => {
		try {
			this.orders = await this.ordersService.filter()

			this.setTableData(this.orders)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
