import { Component, OnInit } from '@angular/core'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'
import { OrderItemService } from '@account/Services/OrderItemService'
import OrderItem from '@account/OrderItem'
import HttpError from '@ui/HttpError'
import Pagination from '@ui/Pagination'

@Component({
	selector: 'entries-page',
	templateUrl: './Entries.Component.html',
})
export class EntriesComponent implements OnInit {
	public years: SelectItem[] = [
		{
			label: 'Reset',
			value: undefined,
		},
	]

	public starts: number

	public orderItems: OrderItem[] = []

	constructor(public orderItemService: OrderItemService) {
		this.orderItemService.setLocalStorageKey('a-entries-page').resetFilter()

		if (
			this.orderItemService.filterable.performance_class &&
			this.orderItemService.filterable.performance_class.starts
		) {
			this.starts = parseInt(
				orderItemService.filterable.performance_class.starts,
				10,
			)
		}
	}

	public ngOnInit() {
		const date = new Date()

		for (
			let year = date.getFullYear() - 5;
			year <= date.getFullYear() + 1;
			year++
		) {
			this.years.push({
				value: year,
				label: year.toString(),
			})
		}

		this.getOrderItems(false)
	}

	public chooseYear = async (year: SelectValue) => {
		this.starts = typeof year === 'number' ? year : undefined

		this.orderItemService.filterable.performance_class.starts = year as string

		await this.getOrderItems()
	}

	public filterCancelledEntries = async (cancelled: boolean): Promise<void> => {
		this.orderItemService.filterable.cancelled = cancelled

		await this.getOrderItems()
	}

	public setOrderItemPaginator = async (
		paginator: Pagination,
	): Promise<void> => {
		this.orderItemService.setPaginator(paginator)

		await this.getOrderItems(false)
	}

	private readonly getOrderItems = async (resetPaginator = true) => {
		try {
			if (resetPaginator) {
				this.orderItemService.paginatorInit(true)
			}

			this.orderItems = await this.orderItemService.filter()
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
