import { HttpErrorResponse } from '@angular/common/http'
import Order from '@account/Order'

export interface FormErrors {
	[key: string]: string | FormErrors
}

interface HttpFormErrors {
	[key: string]: string[]
}

interface HttpErrorType {
	errors?: HttpFormErrors
	message: string
	status?: number
	trace?: string[]
	secret?: string
	token?: string
	order?: Order
}

interface ScaErrors {
	secret: string
	token: string
	order: Order
}

export default class HttpError implements Error {
	public name = 'HttpError'

	public errors: FormErrors | null

	public message: string

	public status: number | null

	public trace: string[] | null

	public scaErrors: ScaErrors

	constructor(httpError: HttpErrorResponse) {
		const vipError: HttpErrorType = httpError.error

		this.errors = {}

		if (vipError.errors) {
			Object.keys(vipError.errors).forEach((key: string) => {
				this.errors[key] = vipError.errors[key].join(' ')
			})
		}

		this.status = httpError.status
		this.message = vipError.message
		this.trace = vipError.trace
		this.scaErrors = {
			secret: vipError.secret,
			token: vipError.token,
			order: vipError.order,
		}
	}

	public getFormErrors = (): FormErrors | null => {
		return this.errors
	}

	public getMessage = (): string => {
		return this.message
	}

	public getStatus = (): number | null => {
		return this.status
	}

	public getTrace = (): string[] | null => {
		return this.trace
	}

	public isUnprocessableEntity = () => this.getStatus() === 422

	public handleFormErrors = (
		formErrors: FormErrors,
		replace?: string,
	): FormErrors => {
		const serverErrors = this.getFormErrors()

		if (!serverErrors) {
			return {}
		}

		Object.keys(formErrors).forEach((key: string) => {
			formErrors[key] = ''
		})

		Object.keys(serverErrors).forEach((key: string) => {
			let formErrorKey = key

			if (typeof replace !== 'undefined') {
				formErrorKey = key.replace(`${replace}.`, '')
			}

			formErrors[formErrorKey] = serverErrors[key]
		})

		return formErrors
	}
}
