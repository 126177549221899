import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
	selector: 'delete-dialog',
	templateUrl: './ConfirmDeleteDialog.Component.html',
})
export class ConfirmDeleteDialogComponent {
	constructor(
		private readonly dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
	) {}

	public closeDialog = () => {
		this.dialogRef.close(false)
	}

	public confirmAndClose = () => {
		this.dialogRef.close(true)
	}
}
