import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router'
import { PermissionsService } from '@account/Services/PermissionsService'
import Account from '@account/Account'

@Injectable()
export class LoggedInGuard implements CanActivate {
	public account: Account

	constructor(
		public permissionService: PermissionsService,
		public router: Router,
	) {
		this.account = permissionService.account
	}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree {
		if (this.account) {
			return true
		}

		return this.router.parseUrl(`/login?redirect=${state.url}`)
	}
}
