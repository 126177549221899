import ClassCategory from '@account/ClassCategory'
import Filterable from '@ui/Filterable'
import { createSchool } from '@account/utils'

export interface SchoolPivot {
	school_id: number
	performer_id: number
	class_category_id: number
}

export default class School implements Filterable {
	constructor(
		public id: number,
		public name: string,
		public email: string,
		public address1: string,
		public address2: string,
		public city: string,
		public county: string,
		public postcode: string,
		public contact_name: string,
		public contact_number: string,
		public categories: ClassCategory[],
		public pivot: SchoolPivot,
	) {}

	public getFilterName = (): string => {
		return 'school'
	}

	public reset = (): School => {
		return createSchool()
	}

	public toFilter = (): any => {
		return {
			id: this.id,
			name: this.name,
			email: this.email,
			contact_name: this.contact_name,
			contact_number: this.contact_number,
			postcode: this.postcode,
			categories: this.categories.map((category: ClassCategory) => ({
				id: category.id,
			})),
		}
	}
}
