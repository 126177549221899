<div>
	<h3>Billing Address</h3>
	<checkout-list-addresses-component
		[(selectedAddress)]="billingAddress"
	></checkout-list-addresses-component>
	<raised-button
		color="primary"
		*ngIf="billingAddress"
		(click)="selectBillingAddress()"
		[styles]="{ width: '100%' }"
	>Enter card details</raised-button>
	<form
		*ngIf="!billingAddress"
		[formGroup]="form"
		(ngSubmit)="handleAddress(address1.value, address2.value, city.value, county.value, postcode.value)"
	>
		<text-input
			#address1
			placeHolder="Address line 1"
			formControlName="address1"
			[form]="form"
			[error]="formErrors.address1"
		></text-input>
		<text-input
			#address2
			placeHolder="Address line 2"
			formControlName="address2"
			[form]="form"
			[error]="formErrors.address2"
		></text-input>
		<text-input
			#city
			placeHolder="City"
			formControlName="city"
			[form]="form"
			[error]="formErrors.city"
		></text-input>
		<text-input
			#county
			placeHolder="County"
			formControlName="county"
			[form]="form"
			[error]="formErrors.county"
		></text-input>
		<text-input
			#postcode
			placeHolder="Postcode"
			formControlName="postcode"
			[form]="form"
			[error]="formErrors.postcode"
		></text-input>
		<raised-button [styles]="{ width: '100%' }" color="primary">Enter card details</raised-button>
	</form>
</div>
