import { Component, HostListener, Input, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import Performer from '@account/Performer'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import Order from '@account/Order'
import OrderItem from '@account/OrderItem'
import { Router } from '@angular/router'
import {
	CdkTableFooter,
	CdkTableRow,
} from '@ui/Components/Table/CdkTable.Component'
import { formatCurrency } from '@ui/utils'

@Component({
	selector: 'order-items',
	templateUrl: './OrderItems.Component.html',
})
export class OrderItemsComponent extends Table<OrderItem> implements OnInit {
	@Input() private readonly order: Order

	public displayColumns: string[] = []

	public tableEntities: Array<CdkTableRow<OrderItem>> = []

	public footer: CdkTableFooter<OrderItem>

	private desktopTableEntities: Array<CdkTableRow<OrderItem>> = []

	private mobileTableEntities: Array<CdkTableRow<OrderItem>> = []

	constructor(
		private readonly systemConfigService: SystemConfigService,
		private readonly router: Router,
	) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.desktopTableEntities = [
			{
				header: 'Class',
				columnDef: 'class',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.name
				},
			},
			{
				header: 'Perfomers',
				columnDef: 'performers',
				accessorCallback(entity: OrderItem): string {
					return entity.performers
						.map(
							(performer: Performer): string =>
								`${performer.first_name} ${performer.last_name}`,
						)
						.join('<br />')
				},
			},
			{
				header: 'Subtotal',
				columnDef: 'price_ex',
				accessorCallback(entity: OrderItem): string | number {
					return entity.amount - entity.vat
				},
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'VAT',
				columnDef: 'vat',
				accessorKey: 'vat',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Total',
				columnDef: 'price_inc',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
		]

		this.mobileTableEntities = [
			{
				header: 'Class',
				columnDef: 'class',
				accessorCallback(entity: OrderItem): string | number {
					return entity.performance_class.name
				},
			},
			{
				header: 'Subtotal',
				columnDef: 'price_ex',
				accessorCallback(entity: OrderItem): string | number {
					return entity.amount - entity.vat
				},
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'VAT',
				columnDef: 'vat',
				accessorKey: 'vat',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Total',
				columnDef: 'price_inc',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				columnDef: 'expandable',
				type: 'expansion',
				expansion: {
					elementClick: this.viewEntry,
					renderHtml: (entity: OrderItem): string => {
						return `Performers: ${entity.performers
							.map(
								(performer: Performer): string =>
									`${performer.first_name} ${performer.last_name}`,
							)
							.join('<br />')}`
					},
				},
			},
		]

		this.footer = {
			label: 'Total',
			value: formatCurrency(
				this.order.total,
				this.systemConfigService.config.currency,
				this.systemConfigService.config.locale,
				this.systemConfigService.config.currencyDecimalPlaces,
			),
		}

		this.handleTable(window.innerWidth)

		this.setTableData(this.order.items)
	}

	public viewEntry = (entry: OrderItem) => {
		this.router.navigateByUrl(`/entries/${entry.id}`)
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 800) {
			this.tableEntities = this.desktopTableEntities
			this.displayColumns =
				this.order.vat > 0
					? ['class', 'performers', 'price_ex', 'vat', 'price_inc']
					: ['class', 'performers', 'price_inc']
		} else {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns =
				this.order.vat > 0
					? ['class', 'price_ex', 'vat', 'price_inc']
					: ['class', 'price_inc']
		}
	}
}
