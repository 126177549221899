import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import HttpError from '@ui/HttpError'
import ClassCategory from '@account/ClassCategory'
import { CategoryService } from '@account/Services/CategoryService'
import { ClassService } from '@account/Services/ClassService'
import Class from '@account/Class'
import Pagination from '@ui/Pagination'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'

@Component({
	selector: 'category-component',
	templateUrl: './Category.Component.html',
	styleUrls: ['./Category.Component.css'],
})
export class CategoryComponent implements OnInit {
	public category: ClassCategory

	public classes: Class[]

	constructor(
		public classService: ClassService,
		private readonly categoryService: CategoryService,
		private readonly route: ActivatedRoute,
	) {}

	public ngOnInit() {
		this.route.paramMap
			.pipe(
				switchMap(async (params: ParamMap) => {
					try {
						return await this.categoryService.find(
							parseInt(params.get('id'), 10),
						)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}),
			)
			.subscribe((category: ClassCategory) => {
				if (category instanceof ClassCategory) {
					this.category = category

					this.classService
						.setLocalStorageKey('a-category-classes-page')
						.resetFilter()

					this.fetchClasses(false)
				}
			})
	}

	public setPaginator = (paginator: Pagination) => {
		this.classService.paginator = paginator
		this.fetchClasses(false)
	}

	public filterNumber = async (number: InputValue) => {
		this.classService.filterable.class_number = number as string

		await this.fetchClasses()
	}

	public filterName = async (value: InputValue) => {
		this.classService.filterable.name = value as string

		await this.fetchClasses()
	}

	private readonly fetchClasses = async (resetPaginator = true) => {
		this.classService.filterable.category.id = this.category.id

		try {
			if (resetPaginator) {
				this.classService.paginatorInit(true)
			}

			this.classes = await this.classService.filter()
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
