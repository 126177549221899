import { Component, Input } from '@angular/core'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import HttpError, { FormErrors } from '@ui/HttpError'
import { FormBuilder, Validators } from '@angular/forms'
import { AccountService } from '@account/Services/AccountService'
import { CustomValidators } from '@ui/Validators/CustomValidators'
import Account from '@account/Account'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import Address, { ACCOUNT } from '@account/Address'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { RecaptchaService } from '@ui/Services/RecaptchaService'

@Component({
	selector: 'register-form-component',
	templateUrl: './RegisterForm.Component.html',
})
export class RegisterFormComponent extends FormValidationComponent {
	@Input() private readonly registerSuccessful: () => void | Promise<void>

	public formErrors: FormErrors = {
		first_name: '',
		last_name: '',
		email: '',
		address1: '',
		address2: '',
		city: '',
		county: '',
		postcode: '',
		contact_number: '',
	}

	protected validationMessages: ValidationMessages = {
		first_name: {
			required: 'First name is required',
			minlength: 'First name must be at least three characters',
		},
		last_name: {
			required: 'Last name is required',
			minlength: 'Last name must be at least three characters',
		},
		email: {
			required: 'Email is required',
			invalidEmailAddress: 'Email address must be valid',
		},
		address1: {
			required: 'Address line 1 is required',
		},
		address2: {},
		city: {
			required: 'City is required',
		},
		county: {
			required: 'County is required',
		},
		postcode: {
			required: 'Postcode is required',
		},
		contact_number: {
			required: 'Contact number is required',
		},
	}

	constructor(
		protected formBuilder: FormBuilder,
		private readonly accountService: AccountService,
		private readonly systemConfigService: SystemConfigService,
		private readonly recaptchaService: RecaptchaService,
	) {
		super(formBuilder)
	}

	public register = async (
		firstName: InputValue,
		lastName: InputValue,
		email: InputValue,
		address1: InputValue,
		address2: InputValue,
		city: InputValue,
		county: InputValue,
		postcode: InputValue,
		contactNumber: InputValue,
	): Promise<void> => {
		this.validateForm()

		if (this.form.valid) {
			const token = await this.recaptchaService.validate(
				this.systemConfigService.config.recaptcha,
			)

			try {
				await this.accountService.create(
					new Account(
						undefined,
						firstName as string,
						lastName as string,
						email as string,
						new Address(
							0,
							address1 as string,
							address2 as string,
							city as string,
							county as string,
							postcode as string,
							ACCOUNT,
						),
						contactNumber as string,
					),
					token,
				)

				this.registerSuccessful()
			} catch (error) {
				if (error instanceof HttpError) {
					if (error.isUnprocessableEntity()) {
						this.formErrors = error.handleFormErrors(this.formErrors, 'account')

						this.markInvalidFields()
					}
				} else {
					throw error
				}
			}
		}
	}

	protected buildForm = (): void => {
		this.form = this.formBuilder.group({
			first_name: ['', [Validators.required, Validators.minLength(3)]],
			last_name: ['', [Validators.required, Validators.minLength(3)]],
			email: ['', [Validators.required, CustomValidators.emailValidator]],
			address1: ['', [Validators.required]],
			address2: ['', []],
			city: ['', [Validators.required]],
			county: ['', [Validators.required]],
			postcode: ['', [Validators.required]],
			contact_number: ['', [Validators.required]],
		})
	}
}
