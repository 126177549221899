import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core'
import School from '@account/School'
import { SchoolsService } from '@account/Services/SchoolsService'
import { CategoryService } from '@account/Services/CategoryService'
import ClassCategory from '@account/ClassCategory'
import HttpError from '@ui/HttpError'
import {
	ButtonOption,
	ButtonOptionValue,
} from '@ui/Components/Form/Button/ButtonToggleGroup.Component'
import {
	RadioOption,
	RadioOptionValue,
} from '@ui/Components/Form/Radio/RadioGroup.Component'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import Pagination from '@ui/Pagination'

export interface SchoolSelected {
	school: School
	category: ClassCategory
}

@Component({
	selector: 'schools-radio-list',
	templateUrl: './SchoolsRadioList.Component.html',
})
export class SchoolsRadioListComponent implements OnInit, OnChanges {
	@Input() public readonly selectedSchool: School

	@Output() private readonly schoolSelected = new EventEmitter<SchoolSelected>()

	@Output() private readonly categorySelected =
		new EventEmitter<ClassCategory>()

	public schoolOptions: RadioOption[]

	public selectedCategory: ClassCategory

	public classCategoryOptions: ButtonOption[] = []

	public selectedSchoolId: number

	private schools: School[]

	private categories: ClassCategory[]

	constructor(
		public schoolsService: SchoolsService,
		private readonly categoryService: CategoryService,
	) {}

	public ngOnInit() {
		this.schoolsService.clearLocalStorageKey().resetFilter()

		this.getCategories()
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedSchool) {
			const school: School = changes.selectedSchool.currentValue

			this.selectedSchoolId = school ? school.id : undefined
		}
	}

	public setPaginator = async (paginator: Pagination) => {
		this.schoolsService.setPaginator(paginator)

		await this.getSchools(false)
	}

	public selectCategory = async (id: ButtonOptionValue): Promise<void> => {
		this.selectedCategory = this.categories.find((category: ClassCategory) => {
			return category.id === id
		})

		this.categorySelected.emit(this.selectedCategory)

		this.schoolsService.filterable.categories = [this.selectedCategory]

		await this.getSchools()
	}

	public handleSchoolSelected = (id: RadioOptionValue) => {
		const selected = this.schools.find((school: School): boolean => {
			return school.id === id
		})

		this.schoolSelected.emit({
			school: selected,
			category: this.selectedCategory,
		})
	}

	public removeSchool = () => {
		this.schoolSelected.emit({
			school: undefined,
			category: this.selectedCategory,
		})
	}

	public filterSchoolName = async (name: InputValue) => {
		this.schoolsService.filterable.name = name as string

		await this.getSchools()
	}

	private readonly getCategories = async (): Promise<void> => {
		try {
			this.categories = await this.categoryService.filter()

			this.classCategoryOptions = this.categories.map(
				(category: ClassCategory): ButtonOption => ({
					label: category.name,
					value: category.id,
				}),
			)

			if (this.categories.length > 0) {
				await this.selectCategory(this.categories[0].id)
			}
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	private readonly getSchools = async (
		resetPaginator = false,
	): Promise<void> => {
		try {
			this.schoolOptions = []

			if (resetPaginator) {
				this.schoolsService.paginatorInit(true)
			}

			this.schools = await this.schoolsService.filter()

			this.schoolOptions = this.schools.map(
				(school: School): RadioOption => ({
					label: school.name,
					value: school.id,
				}),
			)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
