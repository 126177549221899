import { Component, OnInit } from '@angular/core'
import { ClassService } from '@account/Services/ClassService'
import Class from '@account/Class'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import HttpError from '@ui/HttpError'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { PermissionsService } from '@account/Services/PermissionsService'
import { WaitingListService } from '@account/Services/WaitingListService'
import WaitingList from '@account/WaitingList'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
	selector: 'class-component',
	templateUrl: './Class.Component.html',
	styleUrls: ['./Class.Component.css'],
})
export class ClassComponent implements OnInit {
	public class: Class

	public showOverflowButton = false

	constructor(
		public systemConfigService: SystemConfigService,
		public permissionService: PermissionsService,
		private readonly classService: ClassService,
		private readonly route: ActivatedRoute,
		private readonly waitingListService: WaitingListService,
		private readonly snackbar: MatSnackBar,
	) {}

	public ngOnInit() {
		this.route.paramMap
			.pipe(
				switchMap(async (params: ParamMap) => {
					try {
						return await this.classService.find(parseInt(params.get('id'), 10))
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}),
			)
			.subscribe((currentClass: Class) => {
				if (currentClass instanceof Class) {
					this.class = currentClass

					this.showOverflowButton =
						this.class.total_entries === this.class.max_entries &&
						this.systemConfigService.config.waitingLists
				}
			})
	}

	public createWaitingList = async () => {
		try {
			await this.waitingListService.create(
				new WaitingList(undefined, this.class.id, this.class, undefined),
			)

			this.snackbar.open('Added to waiting list', 'Okay')
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
