<div class="flex-container">
	<div class="flex-item" class="form-outter">
		<card-component>
			<card-content>
				<div class="reset-password">
					<form [formGroup]="form" (ngSubmit)="resetPassword(email.value, password.value, password_confirmation.value)">
						<div>
							<text-input
								#email
								placeHolder="Email"
								formControlName="email"
								[error]="formErrors.email"
								[form]="form"
							></text-input>
						</div>
						<div>
							<text-input
								#password
								placeHolder="Password"
								formControlName="password"
								type="password"
								[error]="formErrors.password"
								[form]="form"
								[disableShowPassword]="true"
							></text-input>
						</div>
						<div>
							<text-input
								#password_confirmation
								placeHolder="Password Confirmation"
								formControlName="password_confirmation"
								type="password"
								[error]="formErrors.password_confirmation"
								[form]="form"
								[disableShowPassword]="true"
							></text-input>
						</div>
						<div>
							<flat-button [styles]="{ width: '100%' }" color="primary">Reset Password</flat-button>
						</div>
					</form>
				</div>
			</card-content>
		</card-component>
	</div>
</div>

