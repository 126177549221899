<account-page title="Welcome {{ account.first_name }} {{ account.last_name }}">
	<card-component *ngIf="form">
		<card-content>
			<form [formGroup]="form">
				<text-input
					placeHolder="First Name"
					formControlName="first_name"
					[form]="form"
					[error]="formErrors.first_name"
				></text-input>
				<text-input
					placeHolder="Last Name"
					formControlName="last_name"
					[form]="form"
					[error]="formErrors.last_name"
				></text-input>
				<text-input
					placeHolder="Email"
					formControlName="email"
					[form]="form"
					[error]="formErrors.email"
				></text-input>
				<text-input
					placeHolder="Contact Number"
					formControlName="contact_number"
					[form]="form"
					[error]="formErrors.contact_number"
				></text-input>
				<text-input
					placeHolder="Address Line 1"
					formControlName="address1"
					[form]="form"
					[error]="formErrors.address1"
				></text-input>
				<text-input
					placeHolder="Address Line 2"
					formControlName="address2"
					[form]="form"
					[error]="formErrors.address2"
				></text-input>
				<text-input
					placeHolder="City"
					formControlName="city"
					[form]="form"
					[error]="formErrors.city"
				></text-input>
				<text-input
					placeHolder="County"
					formControlName="county"
					[form]="form"
					[error]="formErrors.county"
				></text-input>
				<text-input
					placeHolder="Postcode"
					formControlName="postcode"
					[form]="form"
					[error]="formErrors.postcode"
				></text-input>
			</form>
			<div *ngIf="showConfirmAccount">
				<hr />
				<raised-button
					color="primary"
					(click)="openConfirmAccountModal()"
				>
					Confirm all account details are correct
				</raised-button>
			</div>
		</card-content>
	</card-component>
</account-page>
