import { Component, Input } from '@angular/core'
import Performer from '@account/Performer'

@Component({
	selector: 'performer-quick-add-component',
	templateUrl: './PerformerQuickAdd.Component.html',
})
export class PerformerQuickAddComponent {
	@Input() public performer: Performer
}
