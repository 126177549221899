<div>
	<time-selector
		[hour]="hour"
		[minute]="minute"
		(timeSelected)="setTime($event)"
	>
	</time-selector>
	<raised-button
		(click)="closeDialog()"
		color="primary"
		[styles]="{'margin-top': '20px', width: '100%'}"
	>
		<i class="far fa-clock-o"></i> Select Time
	</raised-button>
</div>
