import { Component, Input } from '@angular/core'
import { ProgressBarMode } from '@angular/material/progress-bar'
import FileUpload from '@ui/FileUpload'

@Component({
	selector: 'upload-progress',
	templateUrl: './UploadProgress.Component.html',
})
export class UploadProgressComponent {
	@Input() public mode: ProgressBarMode = 'determinate'

	@Input() public fileUpload: FileUpload

	@Input() public color: 'primary' | 'warn' | 'accent' = 'primary'
}
