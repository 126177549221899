<dialog-container>
	<div class="container">
		<h4>Uploading...</h4>
		<upload-progress [fileUpload]="fileUpload"></upload-progress>
		<div
			*ngIf="error"
			class="error"
		>
			{{ error }}
		</div>
		<div *ngIf="formErrors">
			<table class="table">
				<tr *ngFor="let item of formErrors | keyvalue; trackBy: tracker;">
					<td>{{ item.value }}</td>
				</tr>
			</table>
		</div>
		<flat-button
			*ngIf="showButton"
			[color]="buttonColor"
			[styles]="{ width: '100%', 'margin-top': '15px' }"
			(click)="closeDialog()"
		>
			{{ buttonText }}
		</flat-button>
	</div>
</dialog-container>
