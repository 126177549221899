import {
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import { Tab } from '@ui/Components/Tabs/Tabs.Component'
import { AdDirective } from '@ui/Components/Table/Ad.Directive'

@Component({
	selector: 'tab',
	templateUrl: './Tab.Component.html',
})
export class TabComponent implements OnChanges {
	@Input() public tab: Tab

	@ViewChild(AdDirective, { static: true }) public adHost: AdDirective

	public componentRef: ComponentRef<any>

	constructor(private readonly resolver: ComponentFactoryResolver) {}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.tab && changes.tab.currentValue.component) {
			if (!this.componentRef) {
				const factory = this.resolver.resolveComponentFactory(
					changes.tab.currentValue.component.instance,
				)

				const viewContainerRef = this.adHost.viewContainerRef

				viewContainerRef.clear()

				this.componentRef = viewContainerRef.createComponent(factory)
			}

			if (changes.tab.currentValue.component.additionalData) {
				Object.keys(changes.tab.currentValue.component.additionalData).forEach(
					(key: string) => {
						this.componentRef.instance[key] =
							changes.tab.currentValue.component.additionalData[key]
					},
				)
			}

			if (changes.tab.currentValue.component.additionalCallbacks) {
				Object.keys(
					changes.tab.currentValue.component.additionalCallbacks,
				).forEach((key: string) => {
					this.componentRef.instance[key] =
						changes.tab.currentValue.component.additionalCallbacks[key]
				})
			}
		}
	}
}
