import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { PermissionsService } from '@account/Services/PermissionsService'
import Account from '@account/Account'

@Injectable()
export class LoggedOutGuard implements CanActivate {
	public account: Account

	constructor(
		public permissionService: PermissionsService,
		public router: Router,
	) {
		this.account = permissionService.account
	}

	public canActivate(): boolean {
		if (this.account) {
			this.router.navigate(['/'])

			return false
		}

		return true
	}
}
