import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnChanges,
} from '@angular/core'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'

export interface TimeSelectedEvent {
	minute: string
	hour: string
}

@Component({
	selector: 'time-selector',
	templateUrl: './TimeSelector.Component.html',
})
export class TimeSelectorComponent implements OnInit, OnChanges {
	@Input() public hour: SelectValue

	@Input() public minute: SelectValue

	@Output() private readonly timeSelected =
		new EventEmitter<TimeSelectedEvent>()

	public hourOptions: SelectItem[] = []

	public minuteOptions: SelectItem[] = []

	public formattedHour: SelectValue

	public formattedMinute: SelectValue

	public ngOnInit() {
		const hours = this.createRange(24)
		this.hourOptions = hours.map((hour: number) => ({
			label: hour.toString(),
			value: hour,
		}))
		const minutes = this.createRange(60)
		this.minuteOptions = minutes.map((minute: number) => ({
			label: this.appendZero(minute),
			value: minute,
		}))

		this.handleTimes()
	}

	public ngOnChanges() {
		this.handleTimes()
	}

	public appendZero = (value: SelectValue): string => {
		return value.toString().length > 1 ? value.toString() : `0${value}`
	}

	public createRange = (number: number): number[] => {
		const items: number[] = []

		for (let i = 0; i < number; i++) {
			items.push(i)
		}

		return items
	}

	public updateTimes = () => {
		this.timeSelected.emit({
			hour: this.appendZero(this.formattedHour),
			minute: this.appendZero(this.formattedMinute),
		})
	}

	private readonly handleTimes = () => {
		this.formattedHour =
			typeof this.hour === 'string' ? parseInt(this.hour, 10) : this.hour

		this.formattedMinute =
			typeof this.minute === 'string' ? parseInt(this.minute, 10) : this.minute
	}
}
