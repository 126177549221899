import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatButtonToggleChange } from '@angular/material/button-toggle'

export type ButtonOptionValue = string | number

export interface ButtonOption {
	label: string
	value: ButtonOptionValue
}

@Component({
	selector: 'button-toggle-group',
	templateUrl: './ButtonToggleGroup.Component.html',
})
export class ButtonToggleGroupComponent {
	@Input() public value: ButtonOptionValue

	@Input() public options: ButtonOption[]

	@Output() private readonly valueChange = new EventEmitter<ButtonOptionValue>()

	public optionTracker = (index: number) => index

	public handleChange = (event: MatButtonToggleChange) => {
		this.valueChange.emit(event.value)
	}
}
