import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import BaseService from '@ui/Services/BaseService'
import { PaginationService } from '@ui/Services/PaginationService'
import School from '@account/School'
import ClassCategory from '@account/ClassCategory'
import { createSchool } from '@account/utils'
import { CategoryService } from '@account/Services/CategoryService'

@Injectable()
export class SchoolsService extends BaseService<School> {
	public filterable: School

	protected endpoint = '/api/account/schools'

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
		private readonly categoryService: CategoryService,
	) {
		super(http, paginationService)
		this.filterable = createSchool()
	}

	public mapToObject = (json): School => {
		let cats: ClassCategory[] = []

		if (json.categories) {
			cats = json.categories.map((jsonCategory): ClassCategory => {
				return this.categoryService.mapToObject(jsonCategory)
			})
		}

		return new School(
			json.id,
			json.name,
			json.email,
			json.address1,
			json.address2,
			json.city,
			json.county,
			json.postcode,
			json.contact_name,
			json.contact_number,
			cats,
			json.pivot,
		)
	}
}
