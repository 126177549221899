<account-page>
	<vip-paginator
		[paginator]="performerService.paginator"
		[requesting]="performerService.loading"
		(pageChanged)="setPaginator($event)"
	></vip-paginator>
	<div class="flex-filters filters">
		<div class="flex-filter-item">
			<text-filter
				prefixIcon="search"
				placeHolder="First Name"
				[text]="performerService.filterable.first_name"
				(textChange)="filterFirstName($event)"
			></text-filter>
		</div>
		<div class="flex-filter-item">
			<text-filter
				prefixIcon="search"
				placeHolder="Last Name"
				[text]="performerService.filterable.last_name"
				(textChange)="filterLastName($event)"
			></text-filter>
		</div>
	</div>
	<div [ngStyle]="{ 'margin-bottom': '15px' }">
		<raised-button [styles]="{ width: '100%' }" color="primary" (click)="createPerformer()">Create Performer</raised-button>
	</div>
	<cdk-table-component
		[displayColumns]="displayColumns"
		[entities]="tableEntities"
		[dataSource]="dataSource"
		(rowClickAction)="viewPerformer($event)"
	></cdk-table-component>
</account-page>
