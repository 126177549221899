import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { formatDateUsingString } from '@ui/utils'

@Component({
	selector: 'time-component-inner',
	template: `
		<div [ngStyle]="{ display: 'inline-block' }">{{ formattedTime }}</div>
	`,
})
export class TimeComponent implements OnInit, OnChanges {
	@Input() private readonly date: string

	@Input() private readonly timezone: string = 'utc'

	public formattedTime: string

	public ngOnInit() {
		this.setUpComponent()
	}

	public ngOnChanges() {
		this.setUpComponent()
	}

	private readonly setUpComponent = () => {
		this.formattedTime = formatDateUsingString(
			this.date,
			this.timezone,
			'HH:mm',
		)
	}
}
