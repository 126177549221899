import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TimeSelectedEvent } from '@ui/Components/Form/TimeSelector/TimeSelector.Component'

interface Time {
	hour: number
	minute: number
}

@Component({
	selector: 'time-picker-dialog',
	templateUrl: './TimePickerModal.Component.html',
})
export class TimePickerDialogComponent {
	public hour = 9
	public minute = 0

	public time: Time = {
		hour: 9,
		minute: 0,
	}

	constructor(
		private readonly dialogRef: MatDialogRef<TimePickerDialogComponent>,
	) {}

	public closeDialog = () => {
		this.dialogRef.close(this.time)
	}

	public setTime = (time: TimeSelectedEvent) => {
		this.time = {
			hour: parseInt(time.hour, 10),
			minute: parseInt(time.minute, 10),
		}
	}
}
