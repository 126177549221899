import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core'
import QRCode from 'qrcode'
import html2canvas from 'html2canvas'

@Component({
	selector: 'qr-code-generator',
	templateUrl: './QrCodeGenerator.Component.html',
})
export class QrCodeGeneratorComponent implements AfterViewInit {
	@Input() public data: string

	@Input() public name: string

	@ViewChild('qrCanvas') public readonly qrCanvas: ElementRef

	public ngAfterViewInit(): void {
		QRCode.toCanvas(
			this.data,
			{ errorCorrectionLevel: 'H', width: 300 },
			(err: Error, canvas: HTMLElement) => {
				if (err) throw err

				const container = this.qrCanvas.nativeElement
				container.appendChild(canvas)
			},
		)
	}

	public exportQrCode(): void {
		html2canvas(this.qrCanvas.nativeElement).then(
			(canvas: HTMLCanvasElement) => {
				const link = document.createElement('a')
				link.download = `${this.name}.png`
				link.href = canvas.toDataURL('image/png')
				link.click()
			},
		)
	}
}
