import {
	Component,
	Output,
	EventEmitter,
	ElementRef,
	Input,
} from '@angular/core'
import { fromEvent, timer } from 'rxjs'
import { debounce, map } from 'rxjs/operators'
import { debounceTime } from '@ui/utils'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'

@Component({
	selector: 'text-filter',
	templateUrl: './DebouncedTextFilter.Component.html',
})
export class DebouncedTextFilterComponent {
	@Input() public placeHolder: string
	@Input() public text: InputValue = ''
	@Input() public prefix: string
	@Input() public prefixIcon: string
	@Output() private readonly textChange = new EventEmitter<InputValue>()

	constructor(private readonly elementRef: ElementRef) {
		const eventStream = fromEvent(elementRef.nativeElement, 'keyup')
			.pipe(debounce(() => timer(debounceTime)))
			.pipe(map(() => this.text))

		eventStream.subscribe((value: InputValue) => {
			this.textChange.emit(value)
		})
	}
}
