import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { FormErrors } from '@ui/HttpError'

@Component({
	selector: 'slide-toggle',
	templateUrl: './SlideToggle.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SlideToggleComponent,
			multi: true,
		},
	],
})
export class SlideToggleComponent implements ControlValueAccessor {
	@Input() public value: boolean

	@Input() public color: 'primary' | 'warn' = 'primary'

	@Input() public innerText: string

	@Input() public error: string | FormErrors

	@Input() public form: FormGroup

	@Input() public formControlName: string

	@Output() private readonly valueChange = new EventEmitter<boolean>()

	private onTouched: () => void

	private onChange: (value: string) => void

	public registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public writeValue(value: boolean): void {
		this.value = value
		this.valueChange.emit(this.value)
	}

	public handleChange = (event: MatSlideToggleChange) => {
		this.value = event.checked
		this.valueChange.emit(this.value)
	}
}
