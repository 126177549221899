import { Component, OnInit } from '@angular/core'
import Order from '@account/Order'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import HttpError from '@ui/HttpError'
import { OrderService } from '@account/Services/OrderService'

@Component({
	selector: 'order-component',
	templateUrl: './Order.Component.html',
})
export class OrderComponent implements OnInit {
	public order: Order

	constructor(
		private readonly ordersService: OrderService,
		private readonly route: ActivatedRoute,
	) {}

	public ngOnInit() {
		this.route.paramMap
			.pipe(
				switchMap(async (params: ParamMap) => {
					try {
						return await this.ordersService.find(parseInt(params.get('id'), 10))
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}),
			)
			.subscribe((currentOrder: Order) => {
				if (currentOrder instanceof Order) {
					this.order = currentOrder
				}
			})
	}
}
