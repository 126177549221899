import ClassCategory from '@account/ClassCategory'
import Class from '@account/Class'
import Performer from '@account/Performer'
import Cart from '@account/Cart'
import Address from '@account/Address'
import Order from '@account/Order'
import OrderItem from '@account/OrderItem'
import School from '@account/School'
import CartItem from '@account/CartItem'
import PaymentMethod from '@account/PaymentMethod'

export const createClassCategory = (): ClassCategory => {
	return new ClassCategory(undefined, undefined, undefined)
}

export const createClass = (): Class => {
	return new Class(
		undefined,
		undefined,
		createClassCategory(),
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	)
}

export const createPerformer = (): Performer => {
	return new Performer(
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		[],
		undefined,
		undefined,
	)
}

export const createAddress = (): Address => {
	return new Address(
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	)
}

export const createCart = (): Cart => {
	return new Cart(undefined, [], undefined)
}

export const createCartItem = (): CartItem => {
	return new CartItem(undefined, undefined, [], undefined, undefined)
}

export const createOrderItem = (): OrderItem => {
	return new OrderItem(
		undefined,
		createClass(),
		[createPerformer()],
		createSchool(),
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	)
}

export const createOrder = (): Order => {
	return new Order(
		undefined,
		undefined,
		undefined,
		[],
		undefined,
		[],
		undefined,
		undefined,
		createAddress(),
		undefined,
		undefined,
	)
}

export const createSchool = (): School => {
	return new School(
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		[],
		undefined,
	)
}

export const createPaymentMethod = (): PaymentMethod => {
	return new PaymentMethod(
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
	)
}

export const mapCardBrandToLabel = (brand: string): string => {
	switch (brand) {
		case 'visa':
			return 'Visa'
		case 'amex':
			return 'American Express'
		case 'mastercard':
			return 'MasterCard'
		default:
			return brand
	}
}
