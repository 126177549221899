<account-page *ngIf="order" title="Order #{{ order.id }}">
	<card-component *ngIf="order.billingAddress">
		<card-title title="Billing Address"></card-title>
		<card-content>
			<span>{{ order.billingAddress.address1 }}<br /></span>
			<span *ngIf="order.billingAddress.address2">{{ order.billingAddress.address2 }}<br /></span>
			<span>{{ order.billingAddress.city }}<br /></span>
			<span>{{ order.billingAddress.county }}<br /></span>
			<span>{{ order.billingAddress.postcode }}<br /></span>
		</card-content>
	</card-component>
	<card-component [styles]="{ 'margin-top': '20px' }" *ngIf="!order.parent_id">
		<card-title title="Entries"></card-title>
		<card-content>
			<order-items [order]="order"></order-items>
		</card-content>
	</card-component>
	<card-component [styles]="{ 'margin-top': '20px' }">
		<card-title title="Payments"></card-title>
		<card-content>
			<order-payments [order]="order"></order-payments>
		</card-content>
	</card-component>
</account-page>
