import { Component, HostListener, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import Performer from '@account/Performer'
import { PerformerService } from '@account/Services/PerformerService'
import Pagination from '@ui/Pagination'
import HttpError from '@ui/HttpError'
import { Router } from '@angular/router'
import { CreatePerformerDialogComponent } from '@account/Dialogs/Performers/CreatePerformerDialog.Component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ConfirmDeleteDialogComponent } from '@account/Dialogs/ConfirmDeleteDialog.Component'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { SystemConfigService } from '@account/Services/SystemConfigService'

@Component({
	selector: 'performers-component',
	templateUrl: './Performers.Component.html',
	styleUrls: ['./Performers.Component.css'],
})
export class PerformersComponent extends Table<Performer> implements OnInit {
	public displayColumns: string[] = ['name', 'dob', 'delete']

	private createPerformerDialogComponentMatDialogRef: MatDialogRef<CreatePerformerDialogComponent>

	private confirmDeleteDialogComponentMatDialogRef: MatDialogRef<ConfirmDeleteDialogComponent>

	private performers: Performer[] = []

	constructor(
		public performerService: PerformerService,
		private readonly router: Router,
		private readonly dialog: MatDialog,
		private readonly systemConfigService: SystemConfigService,
	) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.tableEntities = [
			{
				columnDef: 'name',
				header: 'Name',
				accessorKeys: ['first_name', 'last_name'],
			},
			{
				columnDef: 'dob',
				header: 'DOB',
				accessorKey: 'dob',
				type: 'date',
				timezone: this.systemConfigService.config.timezone,
			},
			{
				columnDef: 'delete',
				header: 'Delete',
				type: 'action',
				action: {
					title: 'Delete',
					color: 'warn',
					output: this.deletePerformer,
				},
			},
		]

		this.performerService.setLocalStorageKey('a-performers-page').resetFilter()

		this.fetchPerformers(false)

		this.handleTable(window.innerWidth)
	}

	public setPaginator = async (paginator: Pagination) => {
		this.performerService.setPaginator(paginator)

		await this.fetchPerformers(false)
	}

	public viewPerformer = async (performer: Performer) => {
		await this.router.navigateByUrl(`performers/${performer.id}`)
	}

	public createPerformer = () => {
		this.createPerformerDialogComponentMatDialogRef = this.dialog.open(
			CreatePerformerDialogComponent,
		)

		this.createPerformerDialogComponentMatDialogRef
			.afterClosed()
			.subscribe(async (performer: Performer) => {
				if (performer) {
					this.performers.push(performer)
					this.setTableData(this.performers)
				}
			})
	}

	public filterFirstName = async (input: InputValue) => {
		this.performerService.filterable.first_name = input as string

		await this.fetchPerformers()
	}

	public filterLastName = async (input: InputValue) => {
		this.performerService.filterable.last_name = input as string

		await this.fetchPerformers()
	}

	private readonly fetchPerformers = async (
		resetPaginator = false,
	): Promise<void> => {
		try {
			if (resetPaginator) {
				this.performerService.paginatorInit(true)
			}

			this.performers = await this.performerService.filter()

			this.setTableData(this.performers)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	private readonly deletePerformer = (performer: Performer): void => {
		this.confirmDeleteDialogComponentMatDialogRef = this.dialog.open(
			ConfirmDeleteDialogComponent,
		)

		this.confirmDeleteDialogComponentMatDialogRef
			.afterClosed()
			.subscribe(async (confirm: boolean) => {
				if (confirm) {
					try {
						await this.performerService.deletePerformer(performer)

						this.performers = this.performers.filter((filter: Performer) => {
							return performer.id !== filter.id
						})

						this.setTableData(this.performers)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}
			})
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 799) {
			this.displayColumns = ['name', 'dob', 'delete']
		} else {
			this.displayColumns = ['name', 'delete']
		}
	}
}
