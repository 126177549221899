import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	SimpleChanges,
	OnChanges,
	ViewChild,
	OnDestroy,
} from '@angular/core'
import { Calendar, EventApi } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import View from '@fullcalendar/core/View'

export interface DateSelectedEvent {
	date: Date
	view: string
}

export interface DateClick {
	date: Date
	view: View
}

export interface EventClick {
	el: HTMLElement
	event: EventApi
	jsEvent: MouseEvent
	view: View
}

@Component({
	selector: 'base-full-calendar',
	templateUrl: './BaseCalendar.Component.html',
})
export class BaseCalendarComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public timezone: string

	@Input() public updateCalendar: boolean

	@Input() public endPoint: string

	@Input() public height: number

	@Input() public dayViewType: 'timeGridDay' | 'dayGridDay' = 'timeGridDay'

	@Input() public weekViewType: 'timeGridWeek' | 'dayGridWeek' = 'timeGridWeek'

	@Output() public readonly dateSelected = new EventEmitter<DateSelectedEvent>()

	@Output() public readonly calendarUpdated = new EventEmitter<boolean>()

	@Output() public readonly eventClicked = new EventEmitter<string>()

	@ViewChild('calendarNode', { static: true }) private readonly calendarNode

	private calendar: Calendar

	public ngOnInit() {
		this.calendar = new Calendar(this.calendarNode.nativeElement, {
			plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
			events: this.endPoint,
			header: {
				center: `dayGridMonth,${this.weekViewType},${this.dayViewType}`,
			},
			eventLimit: true,
			dateClick: (interaction: DateClick) => {
				this.dateSelected.emit({
					date: interaction.date,
					view: interaction.view.type,
				})
			},
			eventClick: (arg: EventClick): boolean | void => {
				if (arg.event.extendedProps.ref) {
					this.eventClicked.emit(arg.event.extendedProps.ref)
				}
			},
			eventTimeFormat: {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false,
			},
			height: this.height,
			timeZone: this.timezone ? this.timezone : 'local',
		})

		this.calendar.render()
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.calendar) {
			if (typeof changes.updateCalendar !== 'undefined') {
				this.updateCalendar = changes.updateCalendar.currentValue

				if (this.updateCalendar) {
					this.calendar.refetchEvents()

					setTimeout(() => {
						this.calendarUpdated.emit(true)
					}, 500)
				}

				this.updateCalendar = false
			}

			if (typeof changes.endPoint !== 'undefined') {
				if (typeof changes.endPoint.previousValue !== 'undefined') {
					this.calendar.removeAllEventSources()
				}

				this.calendar.addEventSource(changes.endPoint.currentValue)
			}
		}
	}

	public ngOnDestroy(): void {
		this.calendar.destroy()
	}
}
