import CartItem from '@account/CartItem'
import Address from '@account/Address'
import Filterable from '@ui/Filterable'

export default class Cart implements Filterable {
	constructor(
		public id: number,
		public items: CartItem[],
		public billingAddress: Address,
	) {}

	public getTotal = (): number => {
		return this.items.reduce(
			(total: number, item: CartItem) => total + item.amount,
			0,
		)
	}

	public getFilterName(): string {
		return ''
	}

	public reset(): Filterable {
		return undefined
	}

	public toFilter(): any {
		return {}
	}
}
