import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import HttpError from '@ui/HttpError'
import { PerformerService } from '@account/Services/PerformerService'
import Performer from '@account/Performer'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { Tab } from '@ui/Components/Tabs/Tabs.Component'
import { PerformerInfoComponent } from '@account/Components/Performers/Performer/PerformerInfo.Component'
import { PerformerSchoolsComponent } from '@account/Components/Performers/Performer/PerformerSchools.Component'
import { PerformerEntriesComponent } from '@account/Components/Performers/Performer/PerformerEntries.Component'
import { PerformerQuickAddComponent } from '@account/Components/Performers/Performer/PerformerQuickAdd.Component'

@Component({
	selector: 'performer-component',
	templateUrl: './Performer.Component.html',
})
export class PerformerComponent implements OnInit {
	public performer: Performer

	public tabs: Tab[] = []

	constructor(
		private readonly performerService: PerformerService,
		private readonly route: ActivatedRoute,
		private readonly systemConfigService: SystemConfigService,
	) {}

	public ngOnInit() {
		this.route.paramMap
			.pipe(
				switchMap(async (params: ParamMap) => {
					try {
						return await this.performerService.find(
							parseInt(params.get('id'), 10),
						)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}),
			)
			.subscribe((currentPerformer: Performer) => {
				if (currentPerformer instanceof Performer) {
					this.performer = currentPerformer

					this.tabs = [
						{
							label: 'Performer Info',
							component: {
								instance: PerformerInfoComponent,
								additionalData: {
									performer: this.performer,
									performerUpdated: (performer: Performer): void => {
										this.performer = performer
									},
								},
							},
						},
						{
							label: 'Schools',
							component: {
								instance: PerformerSchoolsComponent,
								additionalData: {
									performer: this.performer,
									schoolUpdated: (performer: Performer): void => {
										this.performer = performer
									},
								},
							},
						},
						{
							label: 'Entries',
							component: {
								instance: PerformerEntriesComponent,
								additionalData: {
									performer: this.performer,
								},
							},
						},
					]

					if (this.systemConfigService.config.checkoutEnabled) {
						this.tabs.push({
							label: 'Quick Add',
							component: {
								instance: PerformerQuickAddComponent,
								additionalData: {
									performer: this.performer,
								},
							},
						})
					}
				}
			})
	}
}
