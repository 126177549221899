import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { formatCurrency } from '@ui/utils'

@Component({
	selector: 'base-currency-component',
	template: `
		<div [ngStyle]="{ display: 'inline-block' }">{{ formattedValue }}</div>
	`,
})
export class BaseCurrencyComponent implements OnInit, OnChanges {
	@Input() private readonly value: number

	@Input() private readonly currency: string

	@Input() private readonly locale: string

	@Input() private readonly precision: number

	public formattedValue: string

	public ngOnInit() {
		this.setUpComponent()
	}

	public ngOnChanges() {
		this.setUpComponent()
	}

	public setUpComponent = () => {
		this.formattedValue = formatCurrency(
			this.value,
			this.currency,
			this.locale,
			this.precision,
		)
	}
}
