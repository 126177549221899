import { Component, EventEmitter, Output } from '@angular/core'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'
import HttpError, { FormErrors } from '@ui/HttpError'
import Address, { BILLING } from '@account/Address'
import { CartService } from '@account/Services/CartService'
import { AddressService } from '@account/Services/AddressService'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'

@Component({
	selector: 'checkout-address',
	templateUrl: './CheckoutAddress.Component.html',
})
export class CheckoutAddressComponent extends FormValidationComponent {
	@Output() private readonly addressSelected = new EventEmitter<Address>()

	public formErrors: FormErrors = {
		address1: '',
		address2: '',
		city: '',
		county: '',
		postcode: '',
	}

	public billingAddress: Address

	protected validationMessages: ValidationMessages = {
		address1: {
			required: 'Billing address line 1 is required',
		},
		address2: {
			required: 'Billing address line 2 is required',
		},
		city: {
			required: 'Billing address city is required',
		},
		county: {
			required: 'Billing address county is required',
		},
		postcode: {
			required: 'Billing address postcode is required',
		},
	}

	constructor(
		protected formBuilder: FormBuilder,
		private readonly cartService: CartService,
		private readonly billingAddressService: AddressService,
	) {
		super(formBuilder)
	}

	public selectBillingAddress = () => {
		this.addressSelected.emit(this.billingAddress)
	}

	public handleAddress = async (
		address1: InputValue,
		address2: InputValue,
		city: InputValue,
		county: InputValue,
		postcode: InputValue,
	) => {
		this.validateForm()

		if (this.form.valid) {
			try {
				const billingAddress = await this.billingAddressService.create(
					new Address(
						undefined,
						address1 as string,
						address2 as string,
						city as string,
						county as string,
						postcode as string,
						BILLING,
					),
				)

				this.addressSelected.emit(billingAddress)
			} catch (error) {
				if (error instanceof HttpError) {
					if (error.isUnprocessableEntity()) {
						this.formErrors = error.handleFormErrors(this.formErrors, 'address')
					}
				} else {
					throw error
				}
			}
		}
	}

	protected buildForm = () => {
		if (
			this.cartService.cart &&
			this.cartService.cart.billingAddress &&
			this.cartService.cart.billingAddress.id
		) {
			this.billingAddress = this.cartService.cart.billingAddress
		}

		this.form = this.formBuilder.group({
			address1: [
				this.cartService.cart && this.cartService.cart.billingAddress
					? this.cartService.cart.billingAddress.address1
					: '',
				[Validators.required],
			],
			address2: [
				this.cartService.cart && this.cartService.cart.billingAddress
					? this.cartService.cart.billingAddress.address2
					: '',
				[],
			],
			city: [
				this.cartService.cart && this.cartService.cart.billingAddress
					? this.cartService.cart.billingAddress.city
					: '',
				[Validators.required],
			],
			county: [
				this.cartService.cart && this.cartService.cart.billingAddress
					? this.cartService.cart.billingAddress.county
					: '',
				[Validators.required],
			],
			postcode: [
				this.cartService.cart && this.cartService.cart.billingAddress
					? this.cartService.cart.billingAddress.postcode
					: '',
				[Validators.required],
			],
		})
	}
}
