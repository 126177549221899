<span *ngIf="!form">
	<mat-form-field [ngStyle]="styles" *ngIf="!form" subscriptSizing="dynamic" appearance="fill">
		<mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
		<mat-label>{{ placeholder }}</mat-label>
		<mat-select [value]="value" [placeholder]="placeholder" (selectionChange)="emitEvent($event)" [disabled]="disabled">
			<mat-option *ngFor="let item of items; trackBy: trackByItem;" [value]="item.value">
				{{ item.label }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<form-error [error]="error" [ngStyle]="{ margin: '10px 0' }" *ngIf="error"></form-error>
</span>

<span *ngIf="form" [formGroup]="form">
	<mat-form-field [ngStyle]="styles" subscriptSizing="dynamic" appearance="fill">
		<mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
		<mat-label>{{ placeholder }}</mat-label>
		<mat-select [placeholder]="placeholder" [formControlName]="formControlName" (selectionChange)="emitFormEvent($event)" [disabled]="disabled">
			<mat-option *ngFor="let item of items; trackBy: trackByItem;" [value]="item.value">
				{{ item.label }}
			</mat-option>
		</mat-select>
		<mat-error [ngStyle]="{ margin: '10px 0' }" *ngIf="error">{{ error }}</mat-error>
	</mat-form-field>
</span>
