import { Component, Input } from '@angular/core'
import { FormErrors } from '@ui/HttpError'

@Component({
	selector: 'form-error',
	template: ` <mat-error [ngStyle]="styles">{{ error }}</mat-error> `,
})
export class FormErrorComponent {
	@Input() public error: string | FormErrors

	@Input() public styles: { [style: string]: string | number }
}
