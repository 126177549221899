<div *ngIf="dataSource">
	<table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="expansionColumnNames.length > 0" *ngIf="!draggable">
		<ng-container [cdkColumnDef]="entityAccessor.columnDef" *ngFor="let entityAccessor of entities; trackBy: trackBy; let ii = index;">
			<ng-container *ngIf="entityAccessor.header">
				<th mat-header-cell *cdkHeaderCellDef><cdk-table-header [header]="entityAccessor.header"></cdk-table-header></th>
			</ng-container>
			<ng-container *ngIf="entityAccessor.type !== 'expansion'">
				<td mat-cell class="mat-table-td" *cdkCellDef="let entity; let i = index;" (click)="handleRowClick(entityAccessor, entity)">
					<cdk-table-cell
						[entity]="entity"
						[entityAccessor]="entityAccessor"
						(componentEventTriggered)="handleComponentEvent($event, i)"
					></cdk-table-cell>
				</td>
			</ng-container>
			<ng-container *ngIf="entityAccessor.type === 'expansion'">
				<td mat-cell *matCellDef="let entity;" [attr.colspan]="displayColumns.length">
					<cdk-table-extended
						[entity]="entity"
						[expandedEntity]="expandedEntity"
						[renderRowExpansion]="entityAccessor.expansion.renderHtml"
						[handleRowExpansionClick]="entityAccessor.expansion.elementClick"
					></cdk-table-extended>
				</td>
			</ng-container>
			<span *ngIf="footer">
				<td mat-footer-cell *matFooterCellDef>
					<div *ngIf="ii === 0">{{ footer.label }}</div>
					<div *ngIf="ii === displayColumns.length - headerDiff">{{ footer.value }}</div>
				</td>
			</span>
		</ng-container>
		<tr mat-header-row *cdkHeaderRowDef="displayColumns"></tr>
		<tr
			mat-row
			[class.element-row]="expansionColumnNames.length > 0"
			[ngStyle]="{ cursor: cursor }"
			*cdkRowDef="let entity; columns: displayColumns"
			(click)="toggleRowExpansion(entity)"
		></tr>
		<ng-container *ngIf="expansionColumnNames.length > 0">
			<tr mat-row *matRowDef="let row; columns: expansionColumnNames" class="detail-row"></tr>
		</ng-container>
		<span *ngIf="footer">
			<tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
		</span>
	</table>

	<table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="expansionColumnNames.length > 0" *ngIf="draggable" cdkDropList [cdkDropListData]="dataSource" (cdkDropListDropped)="draggable.dropItem($event)">
		<ng-container [cdkColumnDef]="entityAccessor.columnDef" *ngFor="let entityAccessor of entities; trackBy: trackBy; let ii = index;">
			<ng-container *ngIf="entityAccessor.header">
				<th mat-header-cell *cdkHeaderCellDef>{{ entityAccessor.header }}</th>
			</ng-container>
			<ng-container *ngIf="entityAccessor.type !== 'expansion'">
				<td mat-cell class="mat-table-td" *cdkCellDef="let entity; let i = index;" (click)="handleRowClick(entityAccessor, entity)">
					<cdk-table-cell
						[entity]="entity"
						[entityAccessor]="entityAccessor"
						(componentEventTriggered)="handleComponentEvent($event, i)"
					></cdk-table-cell>
				</td>
			</ng-container>
			<ng-container *ngIf="entityAccessor.type === 'expansion'">
				<td mat-cell *matCellDef="let entity;" [attr.colspan]="displayColumns.length">
					<cdk-table-extended
						[entity]="entity"
						[expandedEntity]="expandedEntity"
						[renderRowExpansion]="entityAccessor.expansion.renderHtml"
						[handleRowExpansionClick]="entityAccessor.expansion.elementClick"
					></cdk-table-extended>
				</td>
			</ng-container>
			<span *ngIf="footer">
				<td mat-footer-cell *matFooterCellDef>
					<div *ngIf="ii === 0">{{ footer.label }}</div>
					<div *ngIf="ii === displayColumns.length - 1">{{ footer.value }}</div>
				</td>
			</span>
		</ng-container>
		<tr mat-header-row *cdkHeaderRowDef="displayColumns"></tr>
		<tr
			mat-row
			cdkDrag
			[class.element-row]="expansionColumnNames.length > 0"
			[ngStyle]="{ cursor: cursor }"
			*cdkRowDef="let entity; columns: displayColumns"
			(click)="toggleRowExpansion(entity)"
			[cdkDragData]="entity"
		></tr>
		<ng-container *ngIf="expansionColumnNames.length > 0">
			<tr mat-row *matRowDef="let row; columns: expansionColumnNames" class="detail-row"></tr>
		</ng-container>
		<span *ngIf="footer">
			<tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
		</span>
	</table>
</div>
