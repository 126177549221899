<raised-button *ngIf="!hidden && !showUploader" [color]="action.actionColor" (click)="handleAction()" [styles]="{ width: '100%' }">
	{{ action.actionText }}
</raised-button>

<file-uploader
	*ngIf="!hidden && showUploader"
	[buttonText]="action.actionText"
	[buttonColor]="action.actionColor"
	[fileSelected]="action.upload.fileSelected"
	[fileUploaded]="action.upload.fileUploaded"
	[showIcon]="false"
></file-uploader>
