import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { SiteNavLink } from '@ui/Components/SiteNavigation/SiteNavLink.Component'
import { Router } from '@angular/router'
import { MatDrawerMode } from '@angular/material/sidenav'

export interface User {
	first_name: string
	last_name: string
	level?: number
}

@Component({
	selector: 'site-nav',
	templateUrl: './SiteNav.Component.html',
})
export class SiteNavComponent implements OnInit {
	@Input() public appName: string

	@Input() public loggingOut: boolean

	@Input() public user: User

	@Input() public router: Router

	@Input() public links: SiteNavLink[]

	@Input() public logout: () => Promise<void>

	@ViewChild('nav', { static: true }) private readonly nav

	public mode: MatDrawerMode

	public windowSize: number

	public maxWindowSize = 998

	public ngOnInit(): void {
		this.resizeWindow()
	}

	public resizeWindow = () => {
		this.windowSize = window.innerWidth

		if (this.windowSize < this.maxWindowSize) {
			this.nav.close()
			this.mode = 'over'
		} else {
			this.nav.open()
			this.mode = 'side'
		}
	}

	public toggleNav = () => {
		if (this.windowSize < this.maxWindowSize) {
			this.nav.toggle()
		}
	}

	public closeNav = () => {
		if (this.windowSize < this.maxWindowSize) {
			this.nav.close()
		}
	}

	public linkTracker = (index: number): number => index
}
