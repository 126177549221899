import { Component, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import PaymentMethod from '@account/PaymentMethod'
import { PaymentMethodService } from '@account/Services/PaymentMethodService'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ConfirmDeleteDialogComponent } from '@account/Dialogs/ConfirmDeleteDialog.Component'
import HttpError from '@ui/HttpError'

@Component({
	selector: 'payment-methods',
	templateUrl: './PaymentMethods.Component.html',
})
export class PaymentMethodsComponent
	extends Table<PaymentMethod>
	implements OnInit
{
	public displayColumns: string[] = ['card', 'expiry', 'delete']

	private confirmDeleteDialogComponentMatDialogRef: MatDialogRef<ConfirmDeleteDialogComponent>

	constructor(
		private readonly paymentMethodService: PaymentMethodService,
		private readonly dialog: MatDialog,
	) {
		super()
	}

	public ngOnInit() {
		this.tableEntities = [
			{
				columnDef: 'card',
				header: 'Type',
				accessorCallback(entity: PaymentMethod): string {
					return `${entity.brand} ending in ${entity.last4}`
				},
			},
			{
				columnDef: 'expiry',
				header: 'Expiry',
				accessorKey: 'expiry',
			},
			{
				columnDef: 'delete',
				header: 'Delete',
				type: 'action',
				action: {
					color: 'warn',
					title: 'Delete',
					output: async (entity: PaymentMethod) => {
						this.confirmDeleteDialogComponentMatDialogRef = this.dialog.open(
							ConfirmDeleteDialogComponent,
						)

						this.confirmDeleteDialogComponentMatDialogRef
							.afterClosed()
							.subscribe(async (confirm: boolean) => {
								if (confirm) {
									try {
										await this.paymentMethodService.deleteMethod(entity)

										await this.fetchCards()
									} catch (error) {
										if (!(error instanceof HttpError)) {
											throw error
										}
									}
								}
							})
					},
				},
			},
		]

		this.fetchCards()
	}

	private readonly fetchCards = async () => {
		try {
			const methods = await this.paymentMethodService.filter()

			this.setTableData(methods)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
