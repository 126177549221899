import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'

@Injectable()
export class AppService extends BaseService<any> {
	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
	) {
		super(httpClient, paginationService)
	}
}
