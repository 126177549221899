import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AddressService } from '@account/Services/AddressService'
import HttpError from '@ui/HttpError'
import {
	SelectItem,
	SelectValue,
} from '@ui/Components/Form/Select/Select.Component'
import Address, { BILLING } from '@account/Address'

@Component({
	selector: 'checkout-list-addresses-component',
	templateUrl: './CheckoutListAddresses.Component.html',
})
export class CheckoutListAddressesComponent implements OnInit {
	@Input() public selectedAddress: Address

	@Output() private readonly selectedAddressChange = new EventEmitter<Address>()

	public items: SelectItem[] = []

	private addresses: Address[]

	constructor(private readonly billingAddressesService: AddressService) {}

	public ngOnInit() {
		this.billingAddressesService.clearLocalStorageKey().resetFilter()

		this.getBillingAddresses()
	}

	public handleAddressChange = (event: SelectValue) => {
		this.selectedAddressChange.emit(
			this.addresses.find(
				(billingAddress: Address) => event === billingAddress.id,
			),
		)
	}

	private readonly getBillingAddresses = async (): Promise<void> => {
		try {
			this.billingAddressesService.filterable.type = BILLING

			this.addresses = await this.billingAddressesService.filter()

			this.items = this.addresses.map((billingAddress: Address) => ({
				value: billingAddress.id,
				label: `${billingAddress.address1}, ${billingAddress.postcode}`,
			}))

			this.items.unshift({ label: 'Enter new address', value: 0 })
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
