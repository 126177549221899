<ng-container *ngIf="form" [formGroup]="form">
	<mat-radio-group [id]="id" [formControlName]="formControlName">
		<mat-radio-button
			*ngFor="let option of options; trackBy: optionTracker;"
			[ngStyle]="{ 'padding-right': '20px' }"
			[value]="option.value"
		>
			{{ option.label }}
		</mat-radio-button>
	</mat-radio-group>
</ng-container>
<ng-container *ngIf="!form">
	<mat-radio-group [id]="id" (change)="handleChange($event)" [value]="value">
		<mat-radio-button
			*ngFor="let option of options; trackBy: optionTracker;"
			[ngStyle]="{ 'padding-right': '20px' }"
			[value]="option.value"
		>
			{{ option.label }}
		</mat-radio-button>
	</mat-radio-group>
</ng-container>
