import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'account-page',
	templateUrl: './Page.Component.html',
})
export class PageComponent implements OnInit {
	@Input() public title: string

	@Input() public subtitle: string

	public ngOnInit(): void {
		if (this.title) {
			document.title = this.title
		}
	}
}
