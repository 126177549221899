<nav class="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
	<div class="container-fluid">
		<a class="navbar-brand" routerLink="/">
			<div class="navbar-brand-inner">
				<img src="/images/logo.png" />
			</div>
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0">
				<li class="nav-item" [class.active]="activeRoute === 'categories'">
					<a class="nav-link" routerLink="/categories" data-toggle="collapse" data-target=".navbar-collapse.show">Categories</a>
				</li>
				<li class="nav-item" [class.active]="activeRoute === 'classes'">
					<a class="nav-link" routerLink="/classes" data-toggle="collapse" data-target=".navbar-collapse.show">Classes</a>
				</li>
				<li class="nav-item" [class.active]="activeRoute === 'entries'" *ngIf="permissionsService.account">
					<a class="nav-link" routerLink="/entries" data-toggle="collapse" data-target=".navbar-collapse.show">Entries</a>
				</li>
				<li class="nav-item" [class.active]="activeRoute === 'performers'" *ngIf="permissionsService.account">
					<a class="nav-link" routerLink="/performers" data-toggle="collapse" data-target=".navbar-collapse.show">Performers</a>
				</li>
				<li class="nav-item" [class.active]="activeRoute === 'orders'" *ngIf="permissionsService.account && systemConfigService.config.ordersEnabled">
					<a class="nav-link" routerLink="/orders" data-toggle="collapse" data-target=".navbar-collapse.show">Orders</a>
				</li>
			</ul>
			<div class="nav" [class.active]="activeRoute === 'my-account'">
				<ul class="navbar-nav nav">
					<li [ngStyle]="{ 'padding-right': '10px', 'padding-top': '10px' }" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" *ngIf="permissionsService.account && systemConfigService.config.checkoutEnabled">
						<a routerLink="/cart" class="nav-link">
							<mat-icon [matBadge]="cartService.cart.items.length" matBadgeColor="warn">shopping_basket</mat-icon>
						</a>
					</li>
					<li class="nav-item" [class.active]="activeRoute === 'login'" data-toggle="collapse" data-target=".navbar-collapse.show" *ngIf="!permissionsService.account">
						<a class="nav-link" routerLink="/login">Login</a>
					</li>
					<li class="nav-item dropdown" *ngIf="permissionsService.account">
						<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Account
						</a>
						<ul class="dropdown-menu">
							<li><a class="dropdown-item" routerLink="/my-account" data-toggle="collapse" data-target=".navbar-collapse.show">My Account</a></li>
							<li><a class="dropdown-item" routerLink="/payment-methods" data-toggle="collapse" data-target=".navbar-collapse.show">My Cards</a></li>
							<li><a class="dropdown-item" routerLink="/addresses" data-toggle="collapse" data-target=".navbar-collapse.show">Addresses</a></li>
							<li><a *ngIf="systemConfigService.config.waitingLists" class="dropdown-item" routerLink="/my-account/waiting-lists" data-toggle="collapse" data-target=".navbar-collapse.show">Waiting Lists</a></li>
							<li><hr class="dropdown-divider"></li>
							<li><a class="dropdown-item" routerLink="/my-account/security" data-toggle="collapse" data-target=".navbar-collapse.show">Security</a></li>
							<li><hr class="dropdown-divider"></li>
							<li><a class="dropdown-item" [ngStyle]="{ cursor: 'pointer' }" (click)="logout()">Logout</a></li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>
