import { Component, Input, OnInit } from '@angular/core'
import { CdkTableHeaderCheckbox } from '@ui/Components/Table/CdkTable.Component'
import { SelectionChange } from '@angular/cdk/collections'

@Component({
	selector: 'cdk-table-header',
	templateUrl: './CdkTableHeader.Component.html',
})
export class CdkTableHeaderComponent implements OnInit {
	@Input() public header: string | CdkTableHeaderCheckbox

	public isCheckbox = false

	public checked = false

	public disabled = false

	public ngOnInit() {
		this.isCheckbox = typeof this.header !== 'string'

		if (typeof this.header !== 'string') {
			this.disabled = this.header.disabled

			this.header.selection.changed.subscribe((event: SelectionChange<any>) => {
				if (typeof this.header !== 'string') {
					this.checked = event.source.selected.length === this.header.totalItems
				}
			})
		}
	}

	public handleCheckChange = () => {
		if (typeof this.header !== 'string') {
			this.header.toggled()
		}
	}
}
