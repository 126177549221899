import { Component } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'

@Component({
	selector: 'login-component',
	templateUrl: './Login.Component.html',
	styleUrls: ['../Auth.css'],
})
export class LoginComponent {
	public showChallenge = false

	private redirectEndpoint: string | null = null

	constructor(private readonly route: ActivatedRoute) {
		this.route.queryParams.subscribe((params: Params): void => {
			this.redirectEndpoint = params.redirect
				? `${params.redirect}`
				: '/my-account'
		})
	}

	public displayChallengeScreen = () => {
		this.showChallenge = true
	}

	public redirect = () => {
		window.location.href = this.redirectEndpoint
	}
}
