<account-page>
	<div>
		<cdk-table-component
			[displayColumns]="displayColumns"
			[entities]="tableEntities"
			[dataSource]="dataSource"
			(rowClickAction)="goToClass($event)"
			[footer]="footer"
		></cdk-table-component>
	</div>
	<div [ngStyle]="{ 'text-align': 'right', 'padding-top': '20px' }">
		<div class="checkout-actions">
			<cart-quick-pay *ngIf="permissionsService.account && cartService.cart.items.length > 0" [cart]="cartService.cart" [ngStyle]="{ display: 'block', 'margin-bottom': '15px' }"></cart-quick-pay>
			<raised-button [disabled]="cartService.cart.items.length === 0" color="primary" [styles]="{ width: '100%' }" (click)="initiateCheckout()">Checkout</raised-button>
		</div>
	</div>
</account-page>
