import { Component, OnInit } from '@angular/core'
import { debounceTime, switchMap } from 'rxjs/operators'
import { ActivatedRoute, ParamMap } from '@angular/router'
import HttpError, { FormErrors } from '@ui/HttpError'
import OrderItem from '@account/OrderItem'
import { OrderItemService } from '@account/Services/OrderItemService'
import Performer from '@account/Performer'
import FileUpload from '@ui/FileUpload'
import { FileUploaderService } from '@ui/Services/FileUploaderService'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'

@Component({
	selector: 'entry-page',
	templateUrl: './Entry.Component.html',
	styleUrls: ['./Entry.Component.css'],
})
export class EntryComponent extends FormValidationComponent implements OnInit {
	public entry: OrderItem

	public performers: string

	public classEntryAge: string

	public formErrors: FormErrors = {
		notes: '',
	}

	protected validationMessages: ValidationMessages = {
		notes: {
			maxlength: 'Notes should not exceed 255 characters',
		},
	}

	constructor(
		protected formBuilder: FormBuilder,
		private readonly orderItemService: OrderItemService,
		private readonly route: ActivatedRoute,
		private readonly fileUploadService: FileUploaderService,
	) {
		super(formBuilder)
	}

	public ngOnInit() {
		this.route.paramMap
			.pipe(
				switchMap(async (params: ParamMap) => {
					try {
						return await this.orderItemService.find(
							parseInt(params.get('id'), 10),
						)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}),
			)
			.subscribe((entry: OrderItem) => {
				if (entry instanceof OrderItem) {
					this.entry = entry

					this.performers = entry.performers
						.map((performer: Performer) => {
							return `${performer.first_name} ${performer.last_name}`
						})
						.join('<br />')

					this.classEntryAge = entry.performance_class.getAgeRange()

					this.buildForm()
				}
			})
	}

	public handleUpload = (upload: FileUpload): FileUpload => {
		this.entry.upload = undefined

		upload.setEndpoint('/api/account/uploads')

		upload.setEntityId(this.entry.id)

		upload.setEntityType('entry')

		return this.fileUploadService.upload(upload)
	}

	public uploaded = async () => {
		this.ngOnInit()
	}

	protected buildForm = () => {
		if (!this.entry) {
			return
		}

		this.form = this.formBuilder.group({
			notes: [this.entry.notes, [Validators.maxLength(255)]],
		})

		this.form.valueChanges.pipe(debounceTime(500)).subscribe(async (data) => {
			this.validateForm()

			if (this.form.valid) {
				this.entry.notes = data.notes

				try {
					this.entry = await this.orderItemService.update(this.entry)
				} catch (error) {
					if (!(error instanceof HttpError)) {
						throw error
					}
				}
			}
		})
	}
}
