import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'
import HttpError from '@ui/HttpError'
import { PermissionsService } from '@account/Services/PermissionsService'
import Account from '@account/Account'

@Injectable()
export default class AppErrorHandler implements ErrorHandler {
	constructor(private readonly permissionService: PermissionsService) {}

	public handleError(error: any): void {
		if (!(error instanceof HttpError)) {
			if (process.env.NODE_ENV === 'production') {
				if (this.permissionService.account instanceof Account) {
					this.setUserContext()
				}
				Sentry.captureException(error.originalError || error)
			} else {
				console.error(error)
			}
		}
	}

	private readonly setUserContext = (): void => {
		Sentry.configureScope((scope) => {
			scope.setUser({
				email: this.permissionService.account.email,
				id: this.permissionService.account.id.toString(),
				name: `${this.permissionService.account.first_name} ${this.permissionService.account.last_name}`,
				scope: 'account',
			})
		})
	}
}
