import { Injectable } from '@angular/core'

declare global {
	interface Window {
		grecaptcha: any
	}
}

@Injectable()
export class RecaptchaService {
	public validate = async (key: string): Promise<string> => {
		return await window.grecaptcha.execute(key, { action: 'submit' })
	}
}
