<ng-container *ngIf="form" [formGroup]="form">
	<mat-slide-toggle [color]="color" [formControlName]="formControlName" [checked]="value" (change)="handleChange($event)">
		{{ innerText }}
	</mat-slide-toggle>
	<mat-error>{{ error }}</mat-error>
</ng-container>
<ng-container *ngIf="!form">
	<mat-slide-toggle [color]="color" [checked]="value" (change)="handleChange($event)">
		{{ innerText }}
	</mat-slide-toggle>
</ng-container>
