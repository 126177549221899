import { Component } from '@angular/core'
import { AccountService } from '@account/Services/AccountService'
import HttpError, { FormErrors } from '@ui/HttpError'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import { FormBuilder, Validators } from '@angular/forms'

@Component({
	selector: 'forgotten-password',
	templateUrl: './ForgottenPassword.Component.html',
	styleUrls: ['../Auth.css'],
})
export class ForgottenPasswordComponent extends FormValidationComponent {
	public formErrors: FormErrors = {
		email: '',
	}

	protected validationMessages: ValidationMessages = {
		email: {
			required: 'Email is required',
		},
	}

	constructor(
		protected formBuilder: FormBuilder,
		private readonly accountService: AccountService,
		private readonly matSnackBar: MatSnackBar,
	) {
		super(formBuilder)
	}

	public resetPassword = async (): Promise<void> => {
		this.validateForm()

		if (!this.form.valid) {
			return
		}

		try {
			await this.accountService.sendResetPasswordEmail(
				this.form.controls.email.value as string,
			)

			this.matSnackBar.open(
				'Password reset link has been emailed to you',
				'Okay',
			)
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					this.formErrors = error.handleFormErrors(this.formErrors, 'school')
					this.markInvalidFields()
				}
				return
			}

			throw error
		}
	}

	protected buildForm = (): void => {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required]],
		})
	}
}
