import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import BaseService from '@ui/Services/BaseService'
import { PaginationService } from '@ui/Services/PaginationService'
import Cart from '@account/Cart'
import CartItem from '@account/CartItem'
import Performer from '@account/Performer'
import { createCart } from '@account/utils'
import { ClassService } from '@account/Services/ClassService'
import Address from '@account/Address'
import { AddressService } from '@account/Services/AddressService'

@Injectable()
export class CartService extends BaseService<Cart> {
	public cart: Cart

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
		private readonly classService: ClassService,
		private readonly billingAddressesService: AddressService,
	) {
		super(http, paginationService)
	}

	public getCart = async (): Promise<void> => {
		const response: any = await this.get({ url: '/api/account/cart' })

		this.cart = this.mapToObject(response)
	}

	public associateBillingAddress = async (
		billingAddress: Address,
	): Promise<Cart> => {
		const response = await this.post('/api/account/cart', {
			cart: {
				...this.cart,
				billing_address: billingAddress,
			},
		})

		this.cart = this.mapToObject(response)

		return this.cart
	}

	public addItem = async (item: CartItem): Promise<Cart> => {
		const response = await this.post('/api/account/cart/items', {
			item,
		})

		this.cart = this.mapToObject(response)

		return this.cart
	}

	public removeItem = async (item: CartItem): Promise<Cart> => {
		const response = await this.delete(`/api/account/cart/items/${item.id}`)

		this.cart = this.mapToObject(response)

		return this.cart
	}

	public mapToObject = (json): Cart => {
		if (json.length === 0) {
			return createCart()
		}

		return new Cart(
			json.id,
			json.items.map((item): CartItem => {
				return new CartItem(
					item.id,
					this.classService.mapToObject(item.class),
					item.performers.map((performer): Performer => {
						return new Performer(
							performer.id,
							performer.first_name,
							performer.last_name,
							performer.school,
							new Date(performer.dob),
							performer.gender,
							Boolean(performer.tos_accepted),
							performer.created_at,
							[],
							performer.account_relationship,
							performer.uuid,
						)
					}),
					item.amount,
					item.vat,
				)
			}),
			json.billing_address
				? this.billingAddressesService.mapToObject(json.billing_address)
				: undefined,
		)
	}
}
