import { FormErrors } from '@ui/HttpError'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Component, OnInit } from '@angular/core'

interface Validation {
	required?: string
	minlength?: string
	invalidEmailAddress?: string
	invalidMob?: string
	invalidCurrency?: string
	invalidNumber?: string
	maxlength?: string
}

export interface ValidationMessages {
	[field: string]: Validation
}

@Component({
	selector: 'form-validator',
	template: '',
})
export abstract class FormValidationComponent implements OnInit {
	public formErrors: FormErrors

	public form: FormGroup

	protected validationMessages: ValidationMessages

	protected constructor(protected formBuilder: FormBuilder) {}

	public ngOnInit(): void {
		this.buildForm()
	}

	protected validateForm = () => {
		if (!this.form) {
			return
		}

		this.formErrors = this.validateGivenForm(this.form)
	}

	protected validateGivenForm = (form: FormGroup): FormErrors => {
		const formErrors: FormErrors = {}

		Object.keys(form.controls).forEach((field: string) => {
			formErrors[field] = ''

			const control = form.controls[field]

			control.markAsTouched()

			if (control && !control.valid && !control.disabled) {
				const messages = this.validationMessages[field]

				let hasError = false

				Object.keys(control.errors).forEach((key: string) => {
					if (messages[key]) {
						hasError = true
						formErrors[field] += `${messages[key]} `
					}
				})

				if (!hasError) {
					control.setErrors({})
					control.updateValueAndValidity({ emitEvent: false })
				}
			}
		})

		return formErrors
	}

	protected markInvalidFieldsForForm = (
		form: FormGroup,
		formErrors: FormErrors,
	) => {
		Object.keys(formErrors).forEach((key: string) => {
			if (formErrors[key] && formErrors[key] !== '' && form.controls[key]) {
				form.controls[key].setErrors(
					{ custom: true, formErrors: { [key]: formErrors[key] } },
					{ emitEvent: true },
				)
			}
		})
	}

	protected markInvalidFields = () => {
		this.markInvalidFieldsForForm(this.form, this.formErrors)
	}

	protected abstract buildForm(): void
}
