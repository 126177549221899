import 'reflect-metadata'
import 'rxjs'
import 'zone.js'
import 'hammerjs'
import 'bootstrap'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from '@account/config/module'

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => {
		console.error(err)
	})
