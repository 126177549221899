import { Component, OnInit } from '@angular/core'
import { PermissionsService } from '@account/Services/PermissionsService'
import {
	ActivatedRoute,
	Event,
	NavigationEnd,
	ParamMap,
	Router,
} from '@angular/router'
import { switchMap } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material/snack-bar'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { Angulartics2GoogleAnalytics } from 'angulartics2'

@Component({
	selector: 'my-app',
	templateUrl: './App.Component.html',
})
export class AppComponent implements OnInit {
	public isHomepage = false

	public year: number

	constructor(
		public permissionsService: PermissionsService,
		public systemConfigService: SystemConfigService,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly matSnackBar: MatSnackBar,
		private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
	) {
		router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.isHomepage = event.url === '/'
			}
		})

		this.year = new Date().getFullYear()

		if (systemConfigService.config.analytics) {
			if (permissionsService.account) {
				angulartics2GoogleAnalytics.setUsername(
					permissionsService.account.email,
				)
			}

			angulartics2GoogleAnalytics.startTracking()
		}
	}

	public ngOnInit() {
		this.activatedRoute.queryParamMap
			.pipe(
				switchMap(async (params: ParamMap) => {
					return Boolean(params.get('email-confirmed'))
				}),
			)
			.subscribe((emailConfirmed: boolean) => {
				if (emailConfirmed) {
					this.isHomepage = true
					this.matSnackBar.open('Email address successfully confirmed', 'Okay')
				}
			})
	}
}
