import {
	Component,
	Output,
	EventEmitter,
	Input,
	OnInit,
	OnChanges,
	SimpleChanges,
} from '@angular/core'
import {
	MatDateFormats,
	provideNativeDateAdapter,
	MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core'
import {
	ControlValueAccessor,
	FormBuilder,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { FormErrors } from '@ui/HttpError'

const DATE_FORMAT: MatDateFormats = {
	...MAT_NATIVE_DATE_FORMATS,
	display: {
		...MAT_NATIVE_DATE_FORMATS.display,
		dateInput: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		} as Intl.DateTimeFormatOptions,
	},
}

@Component({
	selector: 'pick-a-date',
	templateUrl: './DatePicker.Component.html',
	providers: [
		provideNativeDateAdapter(DATE_FORMAT),
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DatePickerComponent,
			multi: true,
		},
	],
})
export class DatePickerComponent
	implements OnInit, OnChanges, ControlValueAccessor
{
	@Input() public value: Date

	@Input() public placeHolder: string

	@Input() public error: string | FormErrors

	@Input() public form: FormGroup

	@Input() public formControlName: string

	@Input() public disabled: boolean

	@Output() private readonly valueChange = new EventEmitter<Date>()

	private onTouched: () => void

	private onChange: (value: string) => void

	private loadedWithForm = false

	constructor(private readonly formBuilder: FormBuilder) {}

	public ngOnInit(): void {
		if (this.form) {
			this.loadedWithForm = true
		} else {
			this.form = this.formBuilder.group({
				date: [
					{ value: this.value ? this.value : '', disabled: this.disabled },
				],
			})
			this.formControlName = 'date'
		}
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.value && !changes.value.firstChange) {
			this.form.controls.date.setValue(changes.value.currentValue)
		}
	}

	public outputDate = () => {
		const control = this.form.get(this.formControlName)
		this.value = control.value ? control.value : undefined
		this.valueChange.emit(this.value)
	}

	public registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public writeValue(value: Date): void {
		this.value = value
		this.valueChange.emit(this.value)
	}
}
