import Filterable from '@ui/Filterable'
import { createPaymentMethod } from '@account/utils'

export default class PaymentMethod implements Filterable {
	constructor(
		public id: number,
		public remote_id: string,
		public brand: string,
		public last4: string,
		public expiry: string,
	) {}

	public getFilterName(): string {
		return 'paymentMethod'
	}

	public reset(): Filterable {
		return createPaymentMethod()
	}

	public toFilter(): any {
		return {}
	}
}
