import Address from '@account/Address'
import Filterable from '@ui/Filterable'

export default class Account implements Filterable {
	constructor(
		public id: number,
		public first_name: string,
		public last_name: string,
		public email: string,
		public address: Address,
		public contact_number: string,
		public twoFactorEnabled: boolean = false,
		public confirmed_at: string | null = null,
	) {}

	public getFilterName(): string {
		return ''
	}

	public reset(): Filterable {
		return undefined
	}

	public toFilter(): any {
		return {}
	}
}
