<mat-form-field [ngStyle]="{width: '100%'}" appearance="fill">
	<mat-icon *ngIf="prefixIcon" matPrefix>{{ prefixIcon }}</mat-icon>
	<mat-label>{{ placeholder }}</mat-label>
	<input type="text" [placeholder]="placeholder" matInput [formControl]="control" [matAutocomplete]="auto">
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectOption($event)">
		<mat-option
			*ngFor="let option of options; trackBy: itemTracker"
			[value]="option.value"
		>
			{{ option.label }}
		</mat-option>
	</mat-autocomplete>
	<mat-icon *ngIf="prefixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
</mat-form-field>
