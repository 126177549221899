import {
	Component,
	HostListener,
	Input,
	OnChanges,
	OnInit,
} from '@angular/core'
import Class from '@account/Class'
import { Table } from '@ui/Table'
import { CdkTableRow } from '@ui/Components/Table/CdkTable.Component'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { Router } from '@angular/router'

@Component({
	selector: 'classes-table',
	templateUrl: './ClassesTable.Component.html',
})
export class ClassesTableComponent
	extends Table<Class>
	implements OnInit, OnChanges
{
	@Input() private readonly classes: Class[]

	public displayColumns: string[] = []

	public tableEntities: Array<CdkTableRow<Class>> = []

	private desktopTableEntities: Array<CdkTableRow<Class>> = []

	private mobileTableEntities: Array<CdkTableRow<Class>> = []

	constructor(
		private readonly configService: SystemConfigService,
		private readonly router: Router,
	) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.desktopTableEntities = [
			{
				header: 'Category',
				columnDef: 'category',
				accessorCallback(entity: Class): string | number {
					return entity.category.name
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Class',
				columnDef: 'class',
				accessorKey: 'name',
				getRowColor: this.getRowColor,
			},
			{
				header: 'Type',
				columnDef: 'type',
				accessorCallback(entity: Class): string {
					return entity.formattedType()
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Amount',
				columnDef: 'amount',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.configService.config.currency,
					locale: this.configService.config.locale,
					currencyPrecision: this.configService.config.currencyDecimalPlaces,
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Entry Age',
				columnDef: 'age',
				accessorCallback(entity: Class): string {
					return entity.getAgeRange()
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Opens',
				columnDef: 'opens',
				accessorCallback: (entity: Class): string => {
					return entity.getCurrentStatus(this.configService.config.timezone)
				},
				getRowColor: this.getRowColor,
			},
		]

		this.mobileTableEntities = [
			{
				header: 'Category',
				columnDef: 'category',
				accessorCallback(entity: Class): string | number {
					return entity.category.name
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Class',
				columnDef: 'class',
				accessorKey: 'name',
				getRowColor: this.getRowColor,
			},
			{
				header: 'Type',
				columnDef: 'type',
				accessorCallback(entity: Class): string {
					return entity.formattedType()
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Amount',
				columnDef: 'amount',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.configService.config.currency,
					locale: this.configService.config.locale,
					currencyPrecision: this.configService.config.currencyDecimalPlaces,
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Entry Age',
				columnDef: 'age',
				accessorCallback(entity: Class): string {
					return entity.getAgeRange()
				},
				getRowColor: this.getRowColor,
			},
			{
				header: 'Opens',
				columnDef: 'opens',
				accessorCallback: (entity: Class): string => {
					return entity.getCurrentStatus(this.configService.config.timezone)
				},
				getRowColor: this.getRowColor,
			},
			{
				columnDef: 'expandable',
				type: 'expansion',
				expansion: {
					renderHtml: this.renderRowExpansion,
					elementClick: this.viewClass,
				},
			},
		]

		this.handleTable(window.innerWidth)
	}

	public ngOnChanges() {
		this.setTableData(this.classes)
	}

	public viewClass = (selectedClass: Class) => {
		this.router.navigateByUrl(`classes/${selectedClass.id}`)
	}

	public renderRowExpansion = (entity: Class) => {
		return `<ul class="class-table-list"><li>Type: ${entity.formattedType()}</li><li>Opens: ${entity.getCurrentStatus(
			this.configService.config.timezone,
		)}</li></ul>`
	}

	private readonly getRowColor = (entity: Class): string => {
		switch (entity.getCurrentStatus(this.configService.config.timezone)) {
			case 'Closed':
				return '#FF0000'
			case 'Now':
				return '#008000'
			default:
				return '#000000'
		}
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 1020) {
			this.tableEntities = this.desktopTableEntities
			this.displayColumns = [
				'category',
				'class',
				'type',
				'age',
				'opens',
				'amount',
			]
		} else if (windowWidth >= 700) {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns = ['class', 'type', 'age', 'opens', 'amount']
		} else {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns = ['class', 'age', 'amount']
		}
	}
}
