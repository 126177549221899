import { Component, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import CartItem from '@account/CartItem'
import { CartService } from '@account/Services/CartService'
import Performer from '@account/Performer'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { CdkTableFooter } from '@ui/Components/Table/CdkTable.Component'
import { formatCurrency } from '@ui/utils'

@Component({
	selector: 'checkout-items',
	templateUrl: './CheckoutItems.Component.html',
	styleUrls: ['../Checkout.Component.css'],
})
export class CheckoutItemsComponent extends Table<CartItem> implements OnInit {
	public displayColumns: string[] = ['class', 'performers', 'price']

	public cartTotal = 0

	public footer: CdkTableFooter<CartItem>

	constructor(
		private readonly cartService: CartService,
		private readonly systemConfigService: SystemConfigService,
	) {
		super()
	}

	public ngOnInit() {
		this.tableEntities = [
			{
				header: 'Class',
				columnDef: 'class',
				accessorCallback(entity: CartItem): string | number {
					return entity.chosenClass.name
				},
			},
			{
				header: 'Perfomers',
				columnDef: 'performers',
				accessorCallback(entity: CartItem): string {
					return entity.performers
						.map(
							(performer: Performer): string =>
								`${performer.first_name} ${performer.last_name}`,
						)
						.join('<br />')
				},
			},
			{
				header: 'Amount',
				columnDef: 'price',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
		]

		this.footer = {
			label: 'Total',
			value: formatCurrency(
				this.cartService.cart.getTotal(),
				this.systemConfigService.config.currency,
				this.systemConfigService.config.locale,
				this.systemConfigService.config.currencyDecimalPlaces,
			),
		}

		this.setTableData(this.cartService.cart.items)

		this.cartTotal = this.cartService.cart.getTotal()
	}
}
