import { Component, OnInit } from '@angular/core'
import { ClassService } from '@account/Services/ClassService'
import Class, {
	TRIO_QUARTET,
	DUET,
	GROUP,
	MINI_GROUP,
	SOLO,
} from '@account/Class'
import Pagination from '@ui/Pagination'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { SelectItem } from '@ui/Components/Form/Select/Select.Component'
import HttpError from '@ui/HttpError'

@Component({
	selector: 'classes-component',
	templateUrl: './Classes.Component.html',
	styleUrls: ['./Classes.Component.css'],
})
export class ClassesComponent implements OnInit {
	public classes: Class[] = []

	public types: SelectItem[] = [
		{ label: 'Select...', value: undefined },
		{ label: 'Solo', value: SOLO },
		{ label: 'Duet', value: DUET },
		{ label: 'Trio/Quartet', value: TRIO_QUARTET },
		{ label: 'Mini Group', value: MINI_GROUP },
		{ label: 'Group', value: GROUP },
	]

	constructor(public classService: ClassService) {}

	public ngOnInit() {
		this.classService.setLocalStorageKey('a-classes-page').resetFilter()

		this.getClasses(false)
	}

	public setPaginator = (paginator: Pagination) => {
		this.classService.paginator = paginator
		this.getClasses(false)
	}

	public filterNumber = async (number: InputValue) => {
		this.classService.filterable.class_number = number as string

		await this.getClasses()
	}

	public filterClassName = async (input: InputValue) => {
		this.classService.filterable.name = input as string

		await this.getClasses()
	}

	public filterCategoryName = async (input: InputValue) => {
		this.classService.filterable.category.name = input as string

		await this.getClasses()
	}

	public filterClassType = async (input: InputValue) => {
		this.classService.filterable.type = input as string

		await this.getClasses()
	}

	private readonly getClasses = async (resetPaginator = true) => {
		try {
			if (resetPaginator) {
				this.classService.paginatorInit(true)
			}

			this.classes = await this.classService.filter()
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
