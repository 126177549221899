<div>
	<div class="app-container">
		<section id="main">
			<div id="splash-index" *ngIf="isHomepage">
				<div class="overlay"></div>
				<nav-component></nav-component>
				<router-outlet></router-outlet>
			</div>
			<div [ngStyle]="{ 'min-height': '100vh' }" *ngIf="!isHomepage">
				<nav-component></nav-component>
				<router-outlet></router-outlet>
			</div>
		</section>
		<footer>
			<div class="footer-content">
				<div *ngIf="systemConfigService.config.support.phone">
					Phone: {{ systemConfigService.config.support.phone }}
				</div>
				<div *ngIf="systemConfigService.config.support.email">
					Email: <a href="mailto:{{ systemConfigService.config.support.email }}">{{ systemConfigService.config.support.email }}</a>
				</div>
			</div>
			<div class="social-footer">
				<ul class="list-inline">
					<li class="list-inline-item" *ngIf="systemConfigService.config.socials.twitter"><a href="https://twitter.com/{{ systemConfigService.config.socials.twitter }}" target="_blank" rel="noreferrer" title="Twitter" alt="Twitter"><i class="fab fa-twitter"></i></a></li>
					<li class="list-inline-item" *ngIf="systemConfigService.config.socials.facebook"><a href="https://www.facebook.com/{{ systemConfigService.config.socials.facebook }}" target="_blank" rel="noreferrer" title="Facebook" alt="Facebook"><i class="fab fa-facebook"></i></a></li>
					<li class="list-inline-item" *ngIf="systemConfigService.config.socials.instagram"><a href="https://www.instagram.com/{{ systemConfigService.config.socials.instagram }}" target="_blank" rel="noreferrer" title="Google plus" alt="Instagram"><i class="fab fa-instagram"></i></a></li>
				</ul>
			</div>
			<div class="footer-legal">Content &copy; {{ systemConfigService.config.name }} {{ year }}</div>
		</footer>
	</div>
</div>

