import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { CartService } from '@account/Services/CartService'

@Injectable()
export class CheckoutGuard implements CanActivate {
	constructor(public cartService: CartService, public router: Router) {}

	public canActivate(): boolean {
		if (this.cartService.cart.items.length === 0) {
			this.router.navigateByUrl('/cart')

			return false
		}

		return true
	}
}
