<h4 [ngStyle]="{ color: '#00acd4' }">Account Management</h4>
<form [formGroup]="form" (ngSubmit)="confirmChallenge(useRecoveryCode ? undefined : code.value, useRecoveryCode ? code.value : undefined)">
	<div>
		<text-input
			#code
			type="text"
			formControlName="code"
			autocomplete="one-time-code"
			[placeHolder]="useRecoveryCode ? 'Recovery Code' : 'Code'"
			[error]="error"
			[form]="form"
			[styles]="{ width: '100%' }"
		></text-input>
		<div class="auth-links">
			<a *ngIf="!useRecoveryCode" (click)="switchToRecovery()" [ngStyle]="{ cursor: 'pointer' }">Use Recovery code</a>
			<a *ngIf="useRecoveryCode" (click)="switchToCode()" [ngStyle]="{ cursor: 'pointer' }">Use 2FA code</a>
		</div>
	</div>
	<div>
		<flat-button [styles]="{ width: '100%' }" color="primary">Confirm Code</flat-button>
	</div>
</form>
