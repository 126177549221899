import { Component, HostListener, Input, OnInit } from '@angular/core'
import Performer from '@account/Performer'
import { Table } from '@ui/Table'
import Class, { SOLO } from '@account/Class'
import { ClassService } from '@account/Services/ClassService'
import { Router } from '@angular/router'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import Pagination from '@ui/Pagination'
import HttpError from '@ui/HttpError'
import CartItem from '@account/CartItem'
import { CartService } from '@account/Services/CartService'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'

@Component({
	selector: 'performer-classes',
	templateUrl: './PerformerClasses.Component.html',
	styleUrls: ['./PerformerClasses.Component.css'],
})
export class PerformerClassesComponent extends Table<Class> implements OnInit {
	@Input() private readonly performer: Performer

	public displayColumns: string[] = []

	public successMessage = ''

	constructor(
		public classService: ClassService,
		private readonly router: Router,
		private readonly configService: SystemConfigService,
		private readonly cartService: CartService,
	) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.classService.clearLocalStorageKey().resetFilter()

		this.classService.filterable.type = SOLO

		this.classService.filterable.youngest_dob = this.performer.dob.toJSON()

		this.tableEntities = [
			{
				header: 'Category',
				columnDef: 'category',
				accessorCallback(entity: Class): string | number {
					return entity.category.name
				},
			},
			{
				header: 'Class',
				columnDef: 'class',
				accessorKey: 'name',
			},
			{
				header: 'Type',
				columnDef: 'type',
				accessorCallback(entity: Class): string {
					return `${entity.type.charAt(0).toUpperCase()}${entity.type.slice(1)}`
				},
			},
			{
				header: 'Amount',
				columnDef: 'amount',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.configService.config.currency,
					locale: this.configService.config.locale,
					currencyPrecision: this.configService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Entry Age',
				columnDef: 'age',
				accessorCallback(entity: Class): string {
					return entity.getAgeRange()
				},
			},
			{
				header: 'Opens',
				columnDef: 'opens',
				accessorCallback: (entity: Class): string => {
					return entity.getCurrentStatus(this.configService.config.timezone)
				},
			},
			{
				header: 'Add to Cart',
				columnDef: 'add_to_cart',
				type: 'action',
				action: {
					title: 'Add to Cart',
					color: 'primary',
					output: async (entity: Class): Promise<void> => {
						const cartItem = new CartItem(
							undefined,
							entity,
							[this.performer],
							entity.amount,
							entity.vat,
						)

						try {
							await this.cartService.addItem(cartItem)

							this.successMessage = `${entity.name} successfully added to the cart`

							setTimeout(() => (this.successMessage = ''), 6000)
						} catch (error) {
							if (!(error instanceof HttpError)) {
								throw error
							}
						}
					},
				},
			},
		]

		this.getClasses(false)

		this.handleTable(window.innerWidth)
	}

	public viewClass = (selectedClass: Class) => {
		this.router.navigateByUrl(`classes/${selectedClass.id}`)
	}

	public setPaginator = async (paginator: Pagination) => {
		this.classService.setPaginator(paginator)
		await this.getClasses(false)
	}

	public filterCategoryName = async (input: InputValue) => {
		this.classService.filterable.category.name = input as string

		await this.getClasses()
	}

	public filterClassName = async (input: InputValue) => {
		this.classService.filterable.name = input as string

		await this.getClasses()
	}

	private readonly getClasses = async (resetPaginator = false) => {
		try {
			if (resetPaginator) {
				this.classService.paginatorInit(true)
			}

			const classes = await this.classService.filter({
				dob: this.performer.dob.toJSON(),
			})

			this.setTableData(classes)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 1020) {
			this.displayColumns = [
				'category',
				'class',
				'type',
				'age',
				'opens',
				'amount',
				'add_to_cart',
			]
		} else {
			this.displayColumns = ['class', 'amount', 'add_to_cart']
		}
	}
}
