import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
	CheckboxControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
	selector: 'form-checkbox',
	templateUrl: './Checkbox.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CheckboxComponent,
			multi: true,
		},
	],
})
export class CheckboxComponent extends CheckboxControlValueAccessor {
	@Input() public checked: boolean

	@Input() public readonly form: FormGroup

	@Input() public readonly id: string

	@Input() public readonly error: string

	@Input() public readonly formControlName: string

	@Input() public readonly prefix: string

	@Input() public readonly label: string

	@Input() public readonly suffix: string

	@Input() public readonly disabled: boolean

	@Input() public readonly styles: { [style: string]: string | number }

	@Output() private readonly checkedChange = new EventEmitter<boolean>()

	public onTouched: () => void

	public onChange: (value: boolean) => void

	public registerOnChange(fn: (value: boolean) => void): void {
		this.onChange = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public handleChange = (change: MatCheckboxChange): void => {
		this.checked = change.checked
		this.checkedChange.emit(this.checked)
	}
}
