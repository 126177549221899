import { Component, Input, Output, EventEmitter } from '@angular/core'
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { FormErrors } from '@ui/HttpError'

export type InputValue = string | boolean | number

@Component({
	selector: 'text-input',
	templateUrl: './TextInput.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextInputComponent,
			multi: true,
		},
	],
})
export class TextInputComponent implements ControlValueAccessor {
	@Input() public value: InputValue

	@Input() public styles: { [key: string]: string | number } = { width: '100%' }

	@Input() public form: FormGroup

	@Input() public placeHolder: string

	@Input() public autocomplete: string

	@Input() public inputId: string

	@Input() public error: string | FormErrors

	@Input() public formControlName: string

	@Input() public prefix: string

	@Input() public suffix: string

	@Input() public prefixIcon: string

	@Input() public suffixIcon: string

	@Input() public disabled: boolean

	@Input() public type: 'text' | 'password' | 'email' = 'text'

	@Input() public disableShowPassword: boolean = false

	@Output() public valueChange = new EventEmitter<InputValue>()

	@Output() public blurChange = new EventEmitter<InputValue>()

	@Output() public keyUpChange = new EventEmitter<InputValue>()

	public onTouched: () => void

	public onChange: (value: InputValue) => void

	public hide = true

	public registerOnChange(fn: (value: InputValue) => void): void {
		this.onChange = fn

		if (this.formControlName && this.form.get(this.formControlName)) {
			this.form
				.get(this.formControlName)
				.valueChanges.subscribe((value) => this.writeValue(value))
		}
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public writeValue(value: InputValue): void {
		this.value = value
		this.valueChange.emit(value)
	}

	public basicOnChange = (value: InputValue) => {
		this.writeValue(value)
	}

	public blurOnChange = (target: EventTarget) => {
		this.blurChange.emit((target as HTMLInputElement).value)
	}
}
