<account-page title="Checkout">
	<div>
		<div class="checkout-steps">
			<div class="items">
				<checkout-items></checkout-items>
				<div [ngStyle]="{ 'margin-top': '20px' }">
					<raised-button [styles]="{ width: '100%' }" *ngIf="step === 2" color="primary" (click)="goToStep(1)">Change billing address</raised-button>
				</div>
			</div>
			<div class="filters address" *ngIf="step === 1" [ngStyle]="{ 'padding-bottom': '15px' }">
				<checkout-address (addressSelected)="assignBillingAddress($event)"></checkout-address>
			</div>
			<div class="filters payment" *ngIf="step === 2" [ngStyle]="{ 'padding-bottom': '15px' }">
				<h4>Billing Address</h4>
				<span>{{ cartService.cart.billingAddress.address1 }}<br /></span>
				<span *ngIf="cartService.cart.billingAddress.address2">{{ cartService.cart.billingAddress.address2 }}<br /></span>
				<span>{{ cartService.cart.billingAddress.city }}<br /></span>
				<span>{{ cartService.cart.billingAddress.county }}<br /></span>
				<span>{{ cartService.cart.billingAddress.postcode }}<br /></span>
				<checkout-payment-form [billingAddress]="cartService.cart.billingAddress"></checkout-payment-form>
			</div>
		</div>
	</div>
</account-page>
