<text-filter
	*ngIf="filter.type === 'text' && filter.userHasAccess"
	[(text)]="filter.value"
	[placeHolder]="filter.placeholder"
	(textChange)="handleChange($event)"
></text-filter>

<div [ngStyle]="{ width: '180px' }" *ngIf="filter.type === 'toggle' && filter.userHasAccess">
	<h3 [ngStyle]="{ 'margin-top': 0, 'font-weight': 100 }">
		<i class="{{ filter.icon }}"></i>&nbsp;{{ filter.placeholder }}
	</h3>
	<div
		[hidden]="!filter.service.loading"
		[ngStyle]="{float: 'left', 'text-align': 'left', 'font-size': '16pt', 'margin-bottom': '10px'}"
	>
		<img src="/images/loading.gif" [ngStyle]="{ height: '30px' }" />
	</div>
	<div [hidden]="filter.service.loading">
		<slide-toggle color="warn" (valueChange)="handleChange($event)"></slide-toggle>
	</div>
</div>
