import { Component, Input } from '@angular/core'

@Component({
	selector: 'card-component',
	templateUrl: './Card.Component.html',
	styleUrls: ['./Card.Component.css'],
})
export class CardComponent {
	@Input() public styles: { [key: string]: string | number }
}
