import { Component, Input, OnInit } from '@angular/core'
import Class from '@account/Class'
import { PerformerDobFilter } from '@account/Services/PerformerService'
import Performer from '@account/Performer'
import { PermissionsService } from '@account/Services/PermissionsService'
import Account from '@account/Account'
import { Table } from '@ui/Table'
import CartItem from '@account/CartItem'
import { createCartItem } from '@account/utils'
import { CartService } from '@account/Services/CartService'
import HttpError from '@ui/HttpError'

@Component({
	selector: 'add-to-cart',
	templateUrl: './AddToCart.Component.html',
})
export class AddToCartComponent extends Table<Performer> implements OnInit {
	@Input() public readonly class: Class

	public dobFilter: PerformerDobFilter

	public cartItem: CartItem

	public user: Account

	public errors: string[] = []

	public success: string

	constructor(
		private readonly permissionsService: PermissionsService,
		private readonly cartService: CartService,
	) {
		super()
		this.user = this.permissionsService.account
	}

	public ngOnInit() {
		this.dobFilter = {
			from: this.class.eldest_dob,
			to: this.class.youngest_dob,
		}

		this.cartItem = createCartItem()

		this.cartItem.chosenClass = this.class

		this.displayColumns = ['name', 'remove']

		this.tableEntities = [
			{
				columnDef: 'name',
				header: 'Name',
				accessorKeys: ['first_name', 'last_name'],
			},
			{
				columnDef: 'remove',
				header: 'Remove',
				type: 'action',
				action: {
					title: 'Remove',
					color: 'warn',
					output: (entity: Performer) => {
						this.cartItem.performers = this.cartItem.performers.filter(
							(performer: Performer) => {
								return performer.id !== entity.id
							},
						)

						this.setTableData(this.cartItem.performers)
					},
				},
			},
		]

		this.setTableData([])
	}

	public performerSelected = (performer: Performer) => {
		if (this.cartItem.performers.length === 1) {
			return
		}

		this.cartItem.performers.push(performer)

		this.setTableData(this.cartItem.performers)
	}

	public addToCart = async () => {
		this.errors = []

		try {
			await this.cartService.addItem(this.cartItem)

			this.cartItem = createCartItem()

			this.cartItem.chosenClass = this.class

			this.setTableData([])

			this.success = 'Successfully added to cart'
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					const errors = error.getFormErrors()

					this.errors = Object.keys(errors).map((key: string): string => {
						return errors[key] as string
					})
				}
			} else {
				throw error
			}
		}
	}

	public errorTracker = (key: number): number => key
}
