import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import Performer from '@account/Performer'
import { createPerformer } from '@account/utils'
import School from '@account/School'
import { SchoolsService } from '@account/Services/SchoolsService'

export interface PerformerDobFilter {
	from: string
	to: string
}

@Injectable()
export class PerformerService extends BaseService<Performer> {
	public filterable: Performer

	protected endpoint = '/api/account/performers'

	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
		private readonly schoolService: SchoolsService,
	) {
		super(httpClient, paginationService)
		this.filterable = createPerformer()
	}

	public find = async (id: number): Promise<Performer> => {
		const response = await this.get({ url: `/api/account/performers/${id}` })

		return this.mapToObject(response)
	}

	public create = async (performer: Performer): Promise<Performer> => {
		const response = await this.post('/api/account/performers', {
			performer,
		})

		return this.mapToObject(response)
	}

	public update = async (performer: Performer): Promise<Performer> => {
		const response = await this.put(`/api/account/performers/${performer.id}`, {
			performer,
		})

		return this.mapToObject(response)
	}

	public deletePerformer = async (performer: Performer): Promise<void> => {
		await this.delete(`/api/account/performers/${performer.id}`)
	}

	public mapToObject = (json): Performer => {
		let schools: School[] = []

		if (json.schools) {
			schools = json.schools.map((jsonSchool): School => {
				return this.schoolService.mapToObject(jsonSchool)
			})
		}

		return new Performer(
			json.id,
			json.first_name,
			json.last_name,
			json.school,
			new Date(json.dob),
			json.gender,
			json.tos_accepted,
			json.created_at,
			schools,
			json.account_relationship,
			json.uuid,
		)
	}
}
