import { Component, HostListener, OnInit } from '@angular/core'
import { CartService } from '@account/Services/CartService'
import { Table } from '@ui/Table'
import CartItem from '@account/CartItem'
import {
	CdkTableFooter,
	CdkTableRow,
} from '@ui/Components/Table/CdkTable.Component'
import Performer from '@account/Performer'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { Router } from '@angular/router'
import HttpError from '@ui/HttpError'
import { PermissionsService } from '@account/Services/PermissionsService'
import { formatCurrency } from '@ui/utils'

@Component({
	selector: 'cart-component',
	templateUrl: './Cart.Component.html',
	styleUrls: ['../Checkout/Checkout.Component.css'],
})
export class CartComponent extends Table<CartItem> implements OnInit {
	public displayColumns: string[] = []

	public tableEntities: Array<CdkTableRow<CartItem>> = []

	public footer: CdkTableFooter<CartItem>

	private desktopTableEntities: Array<CdkTableRow<CartItem>> = []

	private mobileTableEntities: Array<CdkTableRow<CartItem>> = []

	constructor(
		public cartService: CartService,
		public permissionsService: PermissionsService,
		private readonly systemConfigService: SystemConfigService,
		private readonly router: Router,
	) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.desktopTableEntities = [
			{
				header: 'Class',
				columnDef: 'class',
				accessorCallback(entity: CartItem): string | number {
					return entity.chosenClass.name
				},
			},
			{
				header: 'Perfomers',
				columnDef: 'performers',
				accessorCallback(entity: CartItem): string {
					return entity.performers
						.map(
							(performer: Performer): string =>
								`${performer.first_name} ${performer.last_name}`,
						)
						.join('<br />')
				},
			},
			{
				header: 'Subtotal',
				columnDef: 'price_ex',
				accessorCallback(entity: CartItem): string | number {
					return entity.amount - entity.vat
				},
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'VAT',
				columnDef: 'vat',
				accessorKey: 'vat',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Total',
				columnDef: 'price_inc',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Remove',
				columnDef: 'remove',
				type: 'action',
				action: {
					title: 'Remove',
					color: 'warn',
					output: async (entity: CartItem): Promise<void> => {
						try {
							const cart = await this.cartService.removeItem(entity)

							this.setTableData(cart.items)

							this.footer = {
								label: 'Total',
								value: formatCurrency(
									this.cartService.cart.getTotal(),
									this.systemConfigService.config.currency,
									this.systemConfigService.config.locale,
									this.systemConfigService.config.currencyDecimalPlaces,
								),
							}
						} catch (error) {
							if (!(error instanceof HttpError)) {
								throw error
							}
						}
					},
				},
			},
		]

		this.mobileTableEntities = [
			{
				header: 'Class',
				columnDef: 'class',
				accessorCallback(entity: CartItem): string | number {
					return entity.chosenClass.name
				},
			},
			{
				header: 'Remove',
				columnDef: 'remove',
				type: 'action',
				action: {
					title: 'Remove',
					color: 'warn',
					output: async (entity: CartItem): Promise<void> => {
						try {
							const cart = await this.cartService.removeItem(entity)

							this.setTableData(cart.items)

							this.footer = {
								label: 'Total',
								value: formatCurrency(
									this.cartService.cart.getTotal(),
									this.systemConfigService.config.currency,
									this.systemConfigService.config.locale,
									this.systemConfigService.config.currencyDecimalPlaces,
								),
							}
						} catch (error) {
							if (!(error instanceof HttpError)) {
								throw error
							}
						}
					},
				},
			},
			{
				columnDef: 'expansion',
				type: 'expansion',
				expansion: {
					renderHtml: (entity: CartItem) => {
						if (this.systemConfigService.config.vat.enabled) {
							return `<ul><li>Performers: ${entity.performers
								.map(
									(performer: Performer): string =>
										`${performer.first_name} ${performer.last_name}`,
								)
								.join(', ')}</li><li>Price Ex: ${formatCurrency(
								entity.amount - entity.vat,
								this.systemConfigService.config.currency,
								this.systemConfigService.config.locale,
								this.systemConfigService.config.currencyDecimalPlaces,
							)}</li><li>VAT: ${formatCurrency(
								entity.vat,
								this.systemConfigService.config.currency,
								this.systemConfigService.config.locale,
								this.systemConfigService.config.currencyDecimalPlaces,
							)}</li><li>Price Inc: ${formatCurrency(
								entity.amount,
								this.systemConfigService.config.currency,
								this.systemConfigService.config.locale,
								this.systemConfigService.config.currencyDecimalPlaces,
							)}</li></ul>`
						} else {
							return `<ul><li>Performers: ${entity.performers
								.map(
									(performer: Performer): string =>
										`${performer.first_name} ${performer.last_name}`,
								)
								.join(', ')}</li><li>Price: ${formatCurrency(
								entity.amount,
								this.systemConfigService.config.currency,
								this.systemConfigService.config.locale,
								this.systemConfigService.config.currencyDecimalPlaces,
							)}</li></ul>`
						}
					},
				},
			},
		]

		this.footer = {
			label: 'Total',
			value: formatCurrency(
				this.cartService.cart.getTotal(),
				this.systemConfigService.config.currency,
				this.systemConfigService.config.locale,
				this.systemConfigService.config.currencyDecimalPlaces,
			),
		}

		this.handleTable(window.innerWidth)

		this.setTableData(this.cartService.cart.items)
	}

	public goToClass = async (item: CartItem) => {
		await this.router.navigateByUrl(`/classes/${item.chosenClass.id}`)
	}

	public initiateCheckout = async () => {
		if (this.cartService.cart.items.length === 0) {
			return
		}

		await this.router.navigateByUrl('/checkout')
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 1200) {
			this.tableEntities = this.desktopTableEntities
			this.displayColumns = this.systemConfigService.config.vat.enabled
				? ['class', 'performers', 'price_ex', 'vat', 'price_inc', 'remove']
				: ['class', 'performers', 'price_inc', 'remove']
		} else {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns = ['class', 'remove']
		}
	}
}
