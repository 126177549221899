<div *ngIf="user && class && cartItem">
	<div class="filters">
		<performer-autocomplete
			[dobFilter]="dobFilter"
			(performerSelected)="performerSelected($event)"
		></performer-autocomplete>
	</div>
	<cdk-table-component
		[displayColumns]="displayColumns"
		[entities]="tableEntities"
		[dataSource]="dataSource"
	></cdk-table-component>
	<flat-button color="primary" (click)="addToCart()" [styles]="{ 'margin-top': '20px', width: '100%' }">Add to cart</flat-button>
	<div class="cart-error" *ngIf="errors.length">
		<ul>
			<li *ngFor="let error of errors; trackBy: errorTracker;">{{ error }}</li>
		</ul>
	</div>
	<div class="cart-success" *ngIf="success">
		{{ success }}
	</div>
</div>
