import { Component, Input } from '@angular/core'
import Performer from '@account/Performer'
import School from '@account/School'
import ClassCategory from '@account/ClassCategory'
import { SchoolSelected } from '@account/Components/Schools/Select/SchoolsRadioList.Component'
import HttpError from '@ui/HttpError'
import { PerformerService } from '@account/Services/PerformerService'

@Component({
	selector: 'performer-schools-component',
	templateUrl: './PerformerSchools.Component.html',
})
export class PerformerSchoolsComponent {
	@Input() public performer: Performer

	@Input() private readonly schoolUpdated: (
		performer: Performer,
	) => void | Promise<void>

	public categorySchool: School

	constructor(private readonly performerService: PerformerService) {}

	public categorySelected = (category: ClassCategory) => {
		this.categorySchool = this.performer.schools.find(
			(school: School): boolean => {
				return school.pivot.class_category_id === category.id
			},
		)
	}

	public selectSchoolOnCat = async (event: SchoolSelected): Promise<void> => {
		const eventSchool = event.school

		if (eventSchool) {
			eventSchool.pivot = {
				school_id: event.school.id,
				class_category_id: event.category.id,
				performer_id: this.performer.id,
			}

			if (this.categorySchool) {
				this.performer.schools = this.performer.schools.map(
					(school: School) => {
						if (event.category.id === school.pivot.class_category_id) {
							return eventSchool
						}

						return school
					},
				)
			} else {
				this.performer.schools.push(eventSchool)
			}
		} else {
			this.performer.schools = this.performer.schools.filter(
				(school: School): boolean => {
					return event.category.id !== school.pivot.class_category_id
				},
			)
		}

		try {
			this.performer = await this.performerService.update(this.performer)

			this.categorySchool = event.school
		} catch (error) {
			if (error instanceof HttpError) {
				this.categorySchool = undefined
				this.schoolUpdated(this.performer)
			} else {
				throw error
			}
		}
	}
}
