import {
	HttpClient,
	HttpEventType,
	HttpHeaders,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http'
import Pagination from '@ui/Pagination'
import { PaginationService } from '@ui/Services/PaginationService'
import * as qs from 'qs'
import Filterable from '@ui/Filterable'
import { ServiceContract } from '@ui/Services/ServiceContract'
import FileUpload from '@ui/FileUpload'
import HttpError from '@ui/HttpError'
import QueryModifiers from '@ui/QueryModifiers'
import { Injectable } from '@angular/core'

export interface Query {
	[key: string]: boolean | string | number | Query | object
}

@Injectable()
export default class BaseService<T extends Filterable>
	implements ServiceContract<T>
{
	public paginator: Pagination
	public queryModifiers: QueryModifiers
	public filterable: T
	public loading = false
	protected endpoint: string
	protected headers: HttpHeaders = new HttpHeaders({
		'X-Requested-With': 'XMLHttpRequest',
	})
	private localStorageKey: string

	constructor(
		protected http: HttpClient,
		protected paginationService: PaginationService,
	) {
		this.paginatorInit()

		this.queryModifiers = new QueryModifiers({})
	}

	public resetFilter() {
		this.loading = false
		this.paginatorInit()

		const filter = localStorage.getItem(`${this.localStorageKey}-filter`)

		this.filterable = filter
			? this.mapToObject(JSON.parse(filter))
			: (this.filterable.reset() as T)
	}

	public storeFilter = () => {
		if (!this.localStorageKey) {
			return
		}

		localStorage.setItem(
			`${this.localStorageKey}-filter`,
			JSON.stringify(this.filterable, (k, v) => (v === undefined ? null : v)),
		)
	}

	public paginatorInit = (bypassStorage = false) => {
		this.paginator = this.paginationService.newUp(
			this.localStorageKey,
			bypassStorage,
		)

		let queryModifiers = JSON.parse(
			localStorage.getItem(`${this.localStorageKey}-query-modifiers`),
		)

		if (queryModifiers) {
			queryModifiers = new QueryModifiers(queryModifiers.orderBys)
		}

		this.queryModifiers = queryModifiers ? queryModifiers : this.queryModifiers
	}

	public setPaginator = (paginator: Pagination) => {
		if (this.localStorageKey) {
			localStorage.setItem(
				`${this.localStorageKey}-paginator`,
				JSON.stringify({
					...paginator,
					data: [],
					pages: [],
				}),
			)
		}

		this.paginator = paginator
	}

	public setQueryModifiers = (queryModifiers: QueryModifiers) => {
		if (this.localStorageKey) {
			localStorage.setItem(
				`${this.localStorageKey}-query-modifiers`,
				JSON.stringify(queryModifiers),
			)
		}

		this.queryModifiers = queryModifiers
	}

	public setLocalStorageKey = (key: string): BaseService<T> => {
		this.localStorageKey = key

		return this
	}

	public clearLocalStorageKey = (): BaseService<T> => {
		this.localStorageKey = undefined

		return this
	}

	public mapToObject = (data: object): T => {
		throw new Error(`Method not implemented: ${JSON.stringify(data)}`)
	}

	public filter = async (query: Query = {}): Promise<T[]> => {
		this.loading = true

		const { per_page, current_page } = this.paginator

		const response: any = await this.get({
			url: this.endpoint,
			filter: this.filterable,
			query: {
				...query,
				page: current_page,
				per_page,
				orderBy: this.queryModifiers.orderBys,
			},
		})

		this.setPaginator(this.paginationService.mapToObject(response))

		this.loading = false

		this.storeFilter()

		return response.data.map((json) => this.mapToObject(json))
	}

	protected get = async (params: {
		url: string
		query?: NonNullable<unknown>
		filter?: Filterable
	}): Promise<any> => {
		this.loading = true

		let endpoint = params.url

		let queryBegan = false

		if (params.query) {
			endpoint = `${endpoint}?${this.queryToQueryString(params.query)}`

			queryBegan = true
		}

		if (params.filter) {
			endpoint = `${endpoint}${
				queryBegan ? '&' : '?'
			}${this.mapObjectToQueryParams(params.filter)}`
		}

		const response = await this.http
			.get(endpoint, {
				headers: this.headers,
			})
			.toPromise()

		this.loading = false

		return response
	}

	protected post = async (url: string, body: any): Promise<any> => {
		this.loading = true

		const response = await this.http
			.post(url, body, {
				headers: this.headers,
			})
			.toPromise()

		this.loading = false

		return response
	}

	protected put = async (url: string, body: any): Promise<any> => {
		this.loading = true

		const response = await this.http
			.put(url, body, {
				headers: this.headers,
			})
			.toPromise()

		this.loading = false

		return response
	}

	protected delete = async (url: string): Promise<any> => {
		this.loading = true

		const response = await this.http
			.delete(url, {
				headers: this.headers,
			})
			.toPromise()

		this.loading = false

		return response
	}

	protected upload = (
		fileUpload: FileUpload,
		callback: (FileUpload) => void,
	): FileUpload => {
		this.loading = true

		const form = new FormData()

		form.append('upload', fileUpload.getFile(), fileUpload.getFileName())

		const req = new HttpRequest('POST', fileUpload.getEndpoint(), form, {
			reportProgress: true,
		})

		this.http.request(req).subscribe(
			(event: any) => {
				if (event.type === HttpEventType.UploadProgress) {
					fileUpload.setProgress((event.loaded / event.total) * 100)
				} else if (event instanceof HttpResponse) {
					fileUpload.setResponse(event)
					callback(fileUpload)
					this.loading = false
				}
			},
			(error: HttpError) => {
				fileUpload.setResponse(error)
				callback(fileUpload)
				this.loading = false
			},
		)

		return fileUpload
	}

	protected mapObjectToQueryParams = (filterModel: Filterable) => {
		const modifiedFilter = {
			[filterModel.getFilterName()]: filterModel.toFilter(),
		}

		return qs.stringify(modifiedFilter)
	}

	private readonly queryToQueryString = (query: any) => {
		return qs.stringify(query)
	}
}
