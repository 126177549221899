<mat-sidenav-container [ngStyle]="{'min-height': '100vh'}">
	<mat-sidenav
		#nav
		[ngStyle]="{left: 0, top: 0, 'background-color': '#000000'}"
		[mode]="mode">
		<div class="sidebar-outer side-nav" (window:resize)="resizeWindow()">
			<div class="header">
				<a [routerLink]="['/']" (click)="closeNav()">{{ appName }}</a>
			</div>
			<ul class="navigation">
				<a [routerLink]="['/']" (click)="closeNav()">
					<li>{{ user.first_name }} {{ user.last_name }} <i class="fas fa-sign-in-alt"></i></li>
				</a>
				<site-nav-link
					*ngFor="let link of links; trackBy: linkTracker"
					[router]="router"
					[link]="link"
					[user]="user"
					(closeNav)="closeNav()"
				></site-nav-link>
				<a (click)="logout()"><li>Log Out <i class="fas fa-lock"></i></li></a>
			</ul>
		</div>
	</mat-sidenav>
	<div>
		<nav class="navbar navbar-default" [ngStyle]="{display: windowSize > maxWindowSize ? 'none' : 'block', 'max-height': '115px'}">
			<div class="container-fluid">
				<div class="navbar-header">
					<a #navToggle class="navbar-brand"
					   (click)="toggleNav()">
						<div class="navbar-brand-inner">
							<img *ngIf="windowSize <= maxWindowSize" src="/images/logo.png">
						</div>
					</a>
				</div>
			</div>
		</nav>
		<div *ngIf="loggingOut"><app-loader [height]="'100px'" [loading]="loggingOut"></app-loader></div>
		<router-outlet *ngIf="!loggingOut"></router-outlet>
	</div>
</mat-sidenav-container>
