import { Component, Input, Output, EventEmitter } from '@angular/core'
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import { FormErrors } from '@ui/HttpError'

@Component({
	selector: 'text-area',
	templateUrl: './TextArea.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextAreaComponent,
			multi: true,
		},
	],
})
export class TextAreaComponent implements ControlValueAccessor {
	@Input() public value: InputValue

	@Input() public rows: number

	@Input() public form: FormGroup

	@Input() public placeHolder: string

	@Input() public inputId: string

	@Input() public error: string | FormErrors

	@Input() public formControlName: string

	@Input() public prefix: string

	@Input() public suffix: string

	@Input() public disabled: boolean

	@Input() public styles: { [style: string]: string | number } = {
		width: '100%',
	}

	@Output() public valueChange = new EventEmitter<InputValue>()

	@Output() public blurChange = new EventEmitter<InputValue>()

	@Output() public keyUpChange = new EventEmitter<InputValue>()

	public onTouched: () => void

	public onChange: (value: string) => void

	public registerOnChange(fn: (value: InputValue) => void): void {
		this.onChange = fn
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn
	}

	public writeValue(value: InputValue): void {
		this.value = value
		this.valueChange.emit(this.value)
	}

	public basicOnChange = (value: InputValue) => {
		this.writeValue(value)
	}

	public blurOnChange = (target: EventTarget) => {
		this.blurChange.emit((target as HTMLInputElement).value)
	}
}
