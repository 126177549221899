<table class="table">
	<tbody>
		<tr>
			<td>Category</td>
			<td>{{ class.category.name }}</td>
		</tr>
		<tr>
			<td>Description</td>
			<td>{{ class.description }}</td>
		</tr>
		<tr>
			<td>Type</td>
			<td>{{ formattedType }}</td>
		</tr>
		<tr>
			<td>Entry Type</td>
			<td>{{ formattedEntryType }}</td>
		</tr>
		<tr>
			<td>Age</td>
			<td>{{ formattedMinAge }}</td>
		</tr>
		<tr>
			<td>Opens</td>
			<td>{{ formattedState }}</td>
		</tr>
		<tr *ngIf="class.starts">
			<td>Starts</td>
			<td><date-time [date]="class.starts" [includeTime]="true"></date-time></td>
		</tr>
		<tr *ngIf="class.ends">
			<td>Ends</td>
			<td><date-time [date]="class.ends" [includeTime]="true"></date-time></td>
		</tr>
		<tr *ngIf="class.terms">
			<td>Terms</td>
			<td>{{ class.terms }}</td>
		</tr>
		<tr>
			<td>Entry cost</td>
			<td><client-currency [value]="class.amount"></client-currency></td>
		</tr>
	</tbody>
</table>
