import { Component, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import WaitingList from '@account/WaitingList'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ConfirmDeleteDialogComponent } from '@account/Dialogs/ConfirmDeleteDialog.Component'
import { WaitingListService } from '@account/Services/WaitingListService'
import HttpError from '@ui/HttpError'
import Pagination from '@ui/Pagination'

@Component({
	selector: 'waiting-lists',
	templateUrl: './WaitingLists.Component.html',
})
export class WaitingListsComponent
	extends Table<WaitingList>
	implements OnInit
{
	public displayColumns: string[] = ['class', 'created', 'delete']

	private confirmDeleteDialogComponentMatDialogRef: MatDialogRef<ConfirmDeleteDialogComponent>

	private waitingLists: WaitingList[] = []

	constructor(
		public waitingListService: WaitingListService,
		private readonly dialog: MatDialog,
	) {
		super()
	}

	public ngOnInit() {
		this.tableEntities = [
			{
				columnDef: 'class',
				header: 'Class',
				accessorCallback(entity: WaitingList): string | number {
					return entity.performanceClass.name
				},
			},
			{
				columnDef: 'created',
				header: 'Created',
				accessorKey: 'created_at',
				type: 'date',
			},
			{
				columnDef: 'delete',
				header: 'Delete',
				type: 'action',
				action: {
					color: 'warn',
					title: 'Delete',
					output: this.deleteList,
				},
			},
		]

		this.fetchLists()
	}

	public setPaginator = async (paginator: Pagination) => {
		this.waitingListService.setPaginator(paginator)

		await this.fetchLists()
	}

	private readonly fetchLists = async () => {
		try {
			this.waitingLists = await this.waitingListService.filter()

			this.setTableData(this.waitingLists)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	private readonly deleteList = (list: WaitingList): void => {
		this.confirmDeleteDialogComponentMatDialogRef = this.dialog.open(
			ConfirmDeleteDialogComponent,
		)

		this.confirmDeleteDialogComponentMatDialogRef
			.afterClosed()
			.subscribe(async (confirm: boolean) => {
				if (confirm) {
					try {
						await this.waitingListService.deleteList(list)

						this.waitingLists = this.waitingLists.filter(
							(filter: WaitingList) => {
								return list.id !== filter.id
							},
						)

						this.setTableData(this.waitingLists)
					} catch (error) {
						if (!(error instanceof HttpError)) {
							throw error
						}
					}
				}
			})
	}
}
