<account-page title="Entry {{ entry.id }}" *ngIf="entry">
	<form *ngIf="form" [formGroup]="form">
		<table class="table table-hover">
			<tbody>
			<tr>
				<td>Class Number</td>
				<td>{{ entry.class_reference }}</td>
			</tr>
			<tr>
				<td>Class</td>
				<td>{{ entry.performance_class.name }}</td>
			</tr>
			<tr>
				<td>Category</td>
				<td>{{ entry.performance_class.category.name }}</td>
			</tr>
			<tr>
				<td>Performers</td>
				<td><div [innerHTML]="performers"></div></td>
			</tr>
			<tr>
				<td>School</td>
				<td>{{ entry.school.name }}</td>
			</tr>
			<tr>
				<td>Performance</td>
				<td><date-time [date]="entry.performance_class.starts"></date-time></td>
			</tr>
			<tr>
				<td>Age</td>
				<td>{{ classEntryAge }}</td>
			</tr>
			<tr>
				<td>Class Terms</td>
				<td>{{ entry.performance_class.terms }}</td>
			</tr>
			<tr>
				<td>Notes</td>
				<td>
					<text-area
						formControlName="notes"
						placeHolder="Notes"
						[form]="form"
						[error]="formErrors.notes"
						[rows]=10
					></text-area>
				</td>
			</tr>
			<tr>
				<td>Uploads</td>
				<td>
					<audio-player
						*ngIf="entry.upload"
						[audioItems]="[entry.upload]"
					></audio-player>
					<file-uploader
						*ngIf="entry.performance_class.type === 'solo'"
						buttonText="Upload Music"
						[fileUploaded]="uploaded"
						[fileSelected]="handleUpload"
					></file-uploader>
				</td>
			</tr>
			</tbody>
		</table>
	</form>
</account-page>
