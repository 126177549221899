<div [ngStyle]="{ color: color }">
	<div *ngIf="entityAccessor.type === 'date' || entityAccessor.type === 'datetime'">
		<date-time-component-inner
			[date]="currentValue"
			[includeTime]="entityAccessor.type === 'datetime'"
			[timezone]="entityAccessor.timezone"
		></date-time-component-inner>
	</div>
	<div *ngIf="entityAccessor.type === 'time'">
		<time-component-inner [timezone]="entityAccessor.timezone" [date]="currentValue"></time-component-inner>
	</div>
	<div *ngIf="entityAccessor.type === 'currency'">
		<base-currency-component
			[currency]="entityAccessor.currency.currency"
			[value]="currentValue"
			[locale]="entityAccessor.currency.locale"
			[precision]="entityAccessor.currency.currencyPrecision"
		></base-currency-component>
	</div>
	<div *ngIf="entityAccessor.type === 'action' && !hidden">
		<default-button [color]="actionColor" (click)="handleActionClick($event, entityAccessor.action, entity)">
			{{ actionTitle }}
		</default-button>
	</div>
	<div *ngIf="!entityAccessor.type" [innerHTML]="currentValue"></div>
	<div>
		<ng-template ngxAdHost></ng-template>
	</div>
</div>
