import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
	MAT_NATIVE_DATE_FORMATS,
	MatDateFormats,
	provideNativeDateAdapter,
} from '@angular/material/core'
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms'

export interface ReturnDates {
	from: Date
	to: Date
}

const DATE_FORMAT: MatDateFormats = {
	...MAT_NATIVE_DATE_FORMATS,
	display: {
		...MAT_NATIVE_DATE_FORMATS.display,
		dateInput: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		} as Intl.DateTimeFormatOptions,
	},
}

@Component({
	selector: 'date-range-picker',
	templateUrl: './DateRangePicker.Component.html',
	providers: [
		provideNativeDateAdapter(DATE_FORMAT),
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateRangePickerComponent,
			multi: true,
		},
	],
})
export class DateRangePickerComponent implements OnInit {
	@Input() public from: Date

	@Input() public to: Date

	@Output() public readonly selectedDates = new EventEmitter<ReturnDates>()

	public form: FormGroup

	public ngOnInit() {
		this.form = new FormGroup({
			start: new FormControl(this.from),
			end: new FormControl(this.to),
		})
	}

	public handleChange = () => {
		if (!this.form.value.start || !this.form.value.end) {
			return
		}

		this.selectedDates.emit({
			from: this.form.value.start,
			to: this.form.value.end,
		})
	}
}
