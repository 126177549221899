import { Component, Input } from '@angular/core'
import { ServiceContract } from '@ui/Services/ServiceContract'
import HttpError from '@ui/HttpError'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import Filterable from '@ui/Filterable'

export interface ListFilter<T extends Filterable> {
	type: 'text' | 'datetime' | 'toggle'
	placeholder?: string
	value: InputValue
	service: ServiceContract<T>
	userHasAccess: boolean
	icon?: string
	filter(value: InputValue): Promise<void>
}

@Component({
	selector: 'list-filter',
	templateUrl: './ListFilter.Component.html',
})
export class ListFilterComponent {
	@Input() public filter: ListFilter<any>

	public handleChange = async (value: InputValue | boolean): Promise<void> => {
		try {
			await this.filter.filter(value)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
