<card-component>
	<card-content>
		<div class="list-flex">
			<div class="flex-item">
				<i class="{{ editable ? 'fas fa-lock-open' : 'fas fa-lock' }}"></i> <span *ngIf="editable">{{ minsEditable }} minutes</span>
				<form [formGroup]="form" (ngSubmit)="updatePerformer(firstName.value, lastName.value, school.value, dob.value, gender.value, relationship.value)">
					<text-input
						#firstName
						[form]="form"
						formControlName="first_name"
						placeHolder="First Name"
						[error]="formErrors.first_name"
					></text-input>
					<text-input
						#lastName
						[form]="form"
						formControlName="last_name"
						placeHolder="Last Name"
						[error]="formErrors.last_name"
					></text-input>
					<text-input
						#school
						[form]="form"
						formControlName="school"
						placeHolder="Academic School"
						[error]="formErrors.school"
					></text-input>
					<pick-a-date
						#dob
						[form]="form"
						formControlName="dob"
						placeHolder="DOB"
						[error]="formErrors.dob"
					></pick-a-date>
					<select-component
						#gender
						[form]="form"
						formControlName="gender"
						[items]="genderItems"
						placeholder="Gender"
						[error]="formErrors.gender"
					></select-component>
					<select-component
						#relationship
						[form]="form"
						formControlName="account_relationship"
						[items]="relationshipItems"
						placeholder="Relationship With Performer"
						[error]="formErrors.account_relationship"
					></select-component>
					<raised-button color="primary" [styles]="{ width: '100%' }">Update Performer</raised-button>
				</form>
			</div>
			<div class="flex-item">
				<h3>QR Code</h3>
				<div [ngStyle]="{ margin: 'auto', width: '300px' }">
					<qr-code-generator [data]="performer.uuid" name="{{ performer.first_name }} {{ performer.last_name }}"></qr-code-generator>
				</div>
			</div>
		</div>
	</card-content>
</card-component>
