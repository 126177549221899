<form [formGroup]="form" (ngSubmit)="register(firstName.value, lastName.value, email.value, address1.value, address2.value, city.value, county.value, postcode.value, contactNumber.value)">
	<div>
		<text-input
			#firstName
			placeHolder="First Name"
			formControlName="first_name"
			[form]="form"
			[error]="formErrors.first_name"
			[styles]="{ width: '100%' }"
		></text-input>
	</div>
	<div>
		<text-input
			#lastName
			placeHolder="Last Name"
			formControlName="last_name"
			[form]="form"
			[error]="formErrors.last_name"
			[styles]="{ width: '100%' }"
		></text-input>
	</div>
	<div>
		<text-input
			#email
			placeHolder="Email"
			formControlName="email"
			[form]="form"
			[error]="formErrors.email"
			[styles]="{ width: '100%' }"
		></text-input>
	</div>
	<div>
		<text-input
			#contactNumber
			placeHolder="Contact Number"
			formControlName="contact_number"
			[form]="form"
			[error]="formErrors.contact_number"
			[styles]="{ width: '100%' }"
		></text-input>
	</div>
	<div>
		<text-input
			#address1
			placeHolder="Address Line 1"
			formControlName="address1"
			[form]="form"
			[error]="formErrors.address1"
		></text-input>
	</div>
	<div>
		<text-input
			#address2
			placeHolder="Address Line 2"
			formControlName="address2"
			[form]="form"
			[error]="formErrors.address2"
		></text-input>
	</div>
	<div>
		<text-input
			#city
			placeHolder="City"
			formControlName="city"
			[form]="form"
			[error]="formErrors.city"
		></text-input>
	</div>
	<div>
		<text-input
			#county
			placeHolder="County"
			formControlName="county"
			[form]="form"
			[error]="formErrors.county"
		></text-input>
	</div>
	<div>
		<text-input
			#postcode
			placeHolder="Postcode"
			formControlName="postcode"
			[form]="form"
			[error]="formErrors.postcode"
		></text-input>
	</div>
	<div>
		<flat-button [styles]="{ width: '100%' }" color="primary">Register</flat-button>
	</div>
</form>
