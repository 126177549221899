<div
	[ngStyle]="{
		display: 'flex',
		'justify-content': 'space-between',
		'flex-direction': 'flex-start',
		'align-content': 'flex-start'
	}"
>
	<div
		[ngStyle]="{
			order: 1,
			width: '100%'
		}"
	>
		<mat-paginator
			[length]="paginator.total"
			[pageSize]="paginator.per_page"
			[pageSizeOptions]="pageSizeOptions"
			[pageIndex]="paginator.current_page - 1"
			(page)="handleChange($event)"
			[disabled]="requesting"
		>
		</mat-paginator>
	</div>
</div>
