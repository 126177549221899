import { Component, Input } from '@angular/core'
import {
	FormValidationComponent,
	ValidationMessages,
} from '@ui/Components/Form/FormValidation.Component'
import HttpError, { FormErrors } from '@ui/HttpError'
import { FormBuilder } from '@angular/forms'
import { AccountService } from '@account/Services/AccountService'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'

@Component({
	selector: 'two-factor-challenge',
	templateUrl: './TwoFactorChallenge.Component.html',
	styleUrls: ['../../Auth.css'],
})
export class TwoFactorChallengeComponent extends FormValidationComponent {
	@Input() private readonly loginSuccessful: () => void | Promise<void>

	public formErrors: FormErrors = {}

	public validationMessages: ValidationMessages = {}

	public useRecoveryCode = false

	public error: string | FormErrors = ''

	constructor(
		protected formBuilder: FormBuilder,
		private readonly accountService: AccountService,
	) {
		super(formBuilder)
	}

	public confirmChallenge = async (
		code: InputValue,
		recoveryCode: InputValue,
	): Promise<void> => {
		try {
			await this.accountService.confirmTwoFactor(
				code ? (code as string) : undefined,
				recoveryCode ? (recoveryCode as string) : undefined,
			)

			this.loginSuccessful()
		} catch (error) {
			if (error instanceof HttpError) {
				if (error.isUnprocessableEntity()) {
					const errors = error.handleFormErrors(this.formErrors, '')

					this.error = this.useRecoveryCode ? errors.recovery_code : errors.code

					this.markInvalidFields()

					return
				}
			}
		}
	}

	public switchToRecovery = () => {
		this.useRecoveryCode = true
	}

	public switchToCode = () => {
		this.useRecoveryCode = false
	}

	protected buildForm() {
		this.form = this.formBuilder.group({
			code: ['', []],
			recovery_code: ['', []],
		})
	}
}
