import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import {
	CanMakePaymentResult,
	loadStripe,
	Stripe,
	StripeElements,
	PaymentRequest,
	StripePaymentRequestButtonElement,
} from '@stripe/stripe-js'
import { CartService } from '@account/Services/CartService'
import HttpError from '@ui/HttpError'
import { OrderService } from '@account/Services/OrderService'
import { Router } from '@angular/router'
import Order from '@account/Order'
import { MatSnackBar } from '@angular/material/snack-bar'
import Address, { BILLING } from '@account/Address'
import { AddressService } from '@account/Services/AddressService'
import Cart from '@account/Cart'

@Component({
	selector: 'cart-quick-pay',
	templateUrl: './CartQuickPay.Component.html',
})
export class CartQuickPayComponent implements AfterViewInit, OnChanges {
	@Input() private readonly cart: Cart

	@ViewChild('quickPay') private readonly quickPay: ElementRef

	private stripe: Stripe

	private elements: StripeElements

	private paymentRequest: PaymentRequest

	private buttonElement: StripePaymentRequestButtonElement

	constructor(
		private readonly systemConfigService: SystemConfigService,
		private readonly cartService: CartService,
		private readonly orderService: OrderService,
		private readonly billingAddressService: AddressService,
		private readonly router: Router,
		private readonly snackbar: MatSnackBar,
	) {}

	public ngAfterViewInit() {
		this.setupElements()
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.paymentRequest && changes.cart) {
			if (
				changes.cart.previousValue.getTotal() !==
				changes.cart.currentValue.getTotal()
			) {
				this.paymentRequest.update({
					total: {
						label: this.getLabel(),
						amount: this.cart.getTotal(),
					},
				})
			}
		}
	}

	private readonly setupElements = async () => {
		if (!this.quickPay.nativeElement) {
			return
		}

		this.stripe = await loadStripe(this.systemConfigService.config.keys.stripe)

		this.elements = this.stripe.elements()

		this.paymentRequest = this.stripe.paymentRequest({
			country: this.systemConfigService.config.country,
			currency: this.systemConfigService.config.currency.toLowerCase(),
			total: {
				label: this.getLabel(),
				amount: this.cart.getTotal(),
			},
			requestPayerName: true,
			requestPayerEmail: true,
		})

		this.buttonElement = this.elements.create('paymentRequestButton', {
			paymentRequest: this.paymentRequest,
		})

		// Check the availability of the Payment Request API first.
		this.paymentRequest
			.canMakePayment()
			.then((result: CanMakePaymentResult) => {
				if (result) {
					this.handleIntent()
				} else {
					this.quickPay.nativeElement.style.display = 'none'
				}
			})
	}

	private readonly handleIntent = async () => {
		if (this.cart.getTotal() >= 30) {
			this.buttonElement.mount(this.quickPay.nativeElement)

			this.paymentRequest.on('paymentmethod', async (ev) => {
				let clientSecret: string

				let order: Order

				try {
					const billingAddress = await this.billingAddressService.create(
						new Address(
							undefined,
							ev.paymentMethod.billing_details.address.line1,
							ev.paymentMethod.billing_details.address.line2,
							ev.paymentMethod.billing_details.address.city,
							ev.paymentMethod.billing_details.address.state ??
								ev.paymentMethod.billing_details.address.country,
							ev.paymentMethod.billing_details.address.postal_code,
							BILLING,
						),
					)

					await this.cartService.associateBillingAddress(billingAddress)

					order = await this.orderService.create(this.cart, undefined, true)

					clientSecret = order.client_secret
				} catch (error) {
					if (!(error instanceof HttpError)) {
						throw error
					}
				}

				if (clientSecret) {
					// Confirm the PaymentIntent without handling potential next actions (yet).
					const { paymentIntent, error: confirmError } =
						await this.stripe.confirmCardPayment(
							clientSecret,
							{ payment_method: ev.paymentMethod.id },
							{ handleActions: false },
						)

					if (confirmError) {
						ev.complete('fail')

						this.snackbar.open(confirmError.message)
					} else {
						ev.complete('success')

						if (paymentIntent.status === 'requires_action') {
							const { error } = await this.stripe.confirmCardPayment(
								clientSecret,
							)

							if (error) {
								this.snackbar.open(error.message)
							} else {
								await this.router.navigateByUrl(`/orders/${order.id}`)
							}
						} else {
							await this.router.navigateByUrl(`/orders/${order.id}`)
						}
					}
				}
			})
		}
	}

	private readonly getLabel = (): string => {
		return this.systemConfigService.config.name
	}
}
