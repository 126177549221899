import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import Account from '@account/Account'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import { Meta } from '@angular/platform-browser'
import Address from '@account/Address'
import { TwoFactorError } from '@account/Errors/TwoFactorError'

@Injectable()
export class AccountService extends BaseService<Account> {
	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
		private readonly meta: Meta,
	) {
		super(httpClient, paginationService)
	}

	public create = async (
		account: Account,
		recaptcha: string,
	): Promise<Account> => {
		const response = await this.post('/api/account/register', {
			account,
			recaptcha,
			'csrf-token': this.meta.getTag('name=csrf-token').content,
		})

		return this.mapToObject(response)
	}

	public login = async (email: string, password: string): Promise<boolean> => {
		const response = await this.post('/api/account/login', {
			email,
			password,
			'csrf-token': this.meta.getTag('name=csrf-token').content,
		})

		if (response.two_factor) {
			throw new TwoFactorError('2FA required')
		}

		return true
	}

	public confirmTwoFactor = async (
		code?: string,
		recoveryCode?: string,
	): Promise<void> => {
		await this.post('/api/account/two-factor-challenge', {
			code: recoveryCode ? undefined : code,
			recovery_code: recoveryCode,
		})
	}

	public logout = async (): Promise<void> => {
		localStorage.clear()

		await this.post('/api/account/logout', {})
	}

	public sendResetPasswordEmail = async (email: string): Promise<void> => {
		await this.post('/api/account/forgot-password', { email })
	}

	public resetPassword = async (
		token: string,
		email: string,
		password: string,
		confirmation: string,
	): Promise<void> => {
		await this.post('/api/account/reset-password', {
			email,
			password,
			password_confirmation: confirmation,
			token,
			'csrf-token': this.meta.getTag('name=csrf-token').content,
		})
	}

	public update = async (account: Account): Promise<Account> => {
		const response = await this.put('/api/account/me', { account })

		return this.mapToObject(response)
	}

	public confirm = async (): Promise<void> => {
		await this.post('/api/account/confirm', {})
	}

	public mapToObject = (json): Account => {
		return new Account(
			json.id,
			json.first_name,
			json.last_name,
			json.email,
			new Address(
				json.address.id,
				json.address.address1,
				json.address.address2,
				json.address.city,
				json.address.county,
				json.address.postcode,
				json.address.type,
			),
			json.contact_number,
			Boolean(json.two_factor_confirmed_at),
			json.confirmed_at,
		)
	}
}
