import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core'
import { SelectionChange, SelectionModel } from '@angular/cdk/collections'

@Component({
	selector: 'table-cell-checkbox',
	templateUrl: './TableCellCheckbox.Component.html',
})
export class TableCellCheckboxComponent implements OnInit, OnChanges {
	@Input() public instance: any

	@Input() public disabled: boolean

	@Output() public toggle = new EventEmitter<any>()

	@Input() private readonly selection: SelectionModel<any>

	public selected = false

	public ngOnInit() {
		this.selection.changed.subscribe((change: SelectionChange<any>) => {
			this.selected = change.source.isSelected(this.instance)
		})
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (typeof changes.instance !== 'undefined') {
			this.selected = this.selection.isSelected(changes.instance.currentValue)
		}
	}
}
