import { Component, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import ClassCategory from '@account/ClassCategory'
import { CategoryService } from '@account/Services/CategoryService'
import Pagination from '@ui/Pagination'
import { Router } from '@angular/router'
import { InputValue } from '@ui/Components/Form/TextInput/TextInput.Component'
import HttpError from '@ui/HttpError'

@Component({
	selector: 'class-categories',
	templateUrl: './Categories.Component.html',
})
export class CategoriesComponent
	extends Table<ClassCategory>
	implements OnInit
{
	public displayColumns = ['name', 'description']

	private categories: ClassCategory[] = []

	constructor(
		public categoryService: CategoryService,
		private readonly router: Router,
	) {
		super()
	}

	public ngOnInit() {
		this.categoryService.setLocalStorageKey('a-categories-page').resetFilter()

		this.tableEntities = [
			{
				columnDef: 'name',
				header: 'Name',
				accessorKey: 'name',
			},
			{
				columnDef: 'description',
				header: 'Description',
				accessorKey: 'description',
			},
		]

		this.fetchCategories(false)
	}

	public filterName = async (value: InputValue) => {
		this.categoryService.filterable.name = value as string

		await this.fetchCategories()
	}

	public setPaginator = async (paginator: Pagination) => {
		this.categoryService.paginator = paginator

		await this.fetchCategories(false)
	}

	public viewCategory = async (category: ClassCategory) => {
		await this.router.navigateByUrl(`/categories/${category.id}`)
	}

	private readonly fetchCategories = async (resetPaginator = true) => {
		try {
			if (resetPaginator) {
				this.categoryService.paginatorInit(true)
			}

			this.categories = await this.categoryService.filter()

			this.setTableData(this.categories)
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}
}
