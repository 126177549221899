<div class="list-flex">
	<div [ngStyle]="{width: '100%', clear: 'both', padding: '10px'}">
		<vip-paginator
			[paginator]="paginator"
			(pageChanged)="setPaginator($event)"
			[requesting]="requesting"
		></vip-paginator>
	</div>
	<div class="dash-flex" *ngIf="items">
		<div [ngStyle]="{order: 1}">
			<div class="list-flex">
				<card-component [ngStyle]="{order: 1}" class="flex-filter">
					<card-content>
						<div [ngStyle]="{width: '100%', display: 'block'}">
							<h1 class="count"><i [className]="totalIcon"></i> {{ paginator.total }}</h1>
						</div>
						<div [ngStyle]="{width: '100%', display: 'block'}">
							<list-filter
								*ngFor="let filter of filters; trackBy: filterTracker"
								[filter]="filter"
							></list-filter>
						</div>
						<div [ngStyle]="{margin: '10px 0', width: '100%', display: 'block'}" *ngIf="actions">
							<list-action
								*ngFor="let action of actions; trackBy: actionTracker"
								[action]="action"
							></list-action>
						</div>
						<div [ngStyle]="{margin: '10px 0', width: '100%', display: 'block'}" *ngIf="queryModifiers">
							<query-modifiers
								[queryModifiers]="queryModifiers"
								(queryModifiersChange)="setQueryModifiers($event)"
								[model]="model"
							></query-modifiers>
						</div>
					</card-content>
				</card-component>
			</div>
		</div>
		<div [ngStyle]="{order: 2}">
			<div class="list-flex">
				<div *ngFor="let item of items; trackBy: itemTracker;" class="flex-item">
					<list-item
						[item]="item"
						[actions]="itemActions"
						[descriptionParams]="descriptionParams"
						[deleteSelection]="deleteSelection"
						(deleteSelectionChecked)="handleToggle($event)"
					></list-item>
				</div>
			</div>
		</div>
	</div>
</div>
