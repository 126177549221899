import { BehaviorSubject } from 'rxjs'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import HttpError from '@ui/HttpError'

export class ApiDatabaseMocker<T> {
	public dataChange: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([])

	public get data(): T[] {
		return this.dataChange.value
	}

	public assignData(data: T[]) {
		this.dataChange.next([])
		this.data.slice()
		this.dataChange.next(data)
	}

	public reorderData = async (
		event: CdkDragDrop<T>,
		callback?: (items: Array<T>) => Promise<Array<T>>,
	): Promise<void> => {
		let items = this.dataChange.value

		moveItemInArray(items, event.previousIndex, event.currentIndex)

		if (callback) {
			try {
				items = await callback(items)
			} catch (error) {
				if (!(error instanceof HttpError)) {
					throw error
				}

				return
			}
		}

		this.dataChange.next([])

		this.data.slice()

		this.dataChange.next(items)
	}
}
