import { Component, HostListener, Input, OnInit } from '@angular/core'
import { Table } from '@ui/Table'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import Order, { Payment } from '@account/Order'
import { CdkTableRow } from '@ui/Components/Table/CdkTable.Component'
import { mapCardBrandToLabel } from '@account/utils'

@Component({
	selector: 'order-payments',
	templateUrl: './OrderPayments.Component.html',
})
export class OrderPaymentsComponent extends Table<Payment> implements OnInit {
	@Input() private readonly order: Order

	public displayColumns: string[] = []

	public tableEntities: Array<CdkTableRow<Payment>> = []

	private desktopTableEntities: Array<CdkTableRow<Payment>> = []

	private mobileTableEntities: Array<CdkTableRow<Payment>> = []

	constructor(private readonly systemConfigService: SystemConfigService) {
		super()
	}

	@HostListener('window:resize', ['$event']) private readonly onResize = (
		event,
	) => {
		this.handleTable(event.target.innerWidth)
	}

	public ngOnInit() {
		this.desktopTableEntities = [
			{
				header: 'Payment',
				columnDef: 'info',
				accessorCallback(entity: Payment): string {
					return entity.card_brand
						? `${mapCardBrandToLabel(entity.card_brand)} ending ${
								entity.card_last4
						  }`
						: 'No payment method'
				},
			},
			{
				header: 'Amount',
				columnDef: 'amount',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				header: 'Status',
				columnDef: 'status',
				accessorCallback(entity: Payment): string {
					return entity.failure_reason ?? 'Successful'
				},
			},
		]

		this.mobileTableEntities = [
			{
				header: 'Payment',
				columnDef: 'info',
				accessorCallback(entity: Payment): string {
					return entity.card_brand
						? `${mapCardBrandToLabel(entity.card_brand)} ending ${
								entity.card_last4
						  }`
						: 'No payment method'
				},
			},
			{
				header: 'Amount',
				columnDef: 'amount',
				accessorKey: 'amount',
				type: 'currency',
				currency: {
					currency: this.systemConfigService.config.currency,
					locale: this.systemConfigService.config.locale,
					currencyPrecision:
						this.systemConfigService.config.currencyDecimalPlaces,
				},
			},
			{
				type: 'expansion',
				columnDef: 'expandable',
				expansion: {
					renderHtml: (entity: Payment): string => {
						return `Status: ${entity.failure_reason ?? 'Successful'}`
					},
				},
			},
		]

		this.handleTable(window.innerWidth)

		this.setTableData(this.order.payments)
	}

	private readonly handleTable = (windowWidth: number) => {
		if (windowWidth >= 800) {
			this.tableEntities = this.desktopTableEntities
			this.displayColumns = ['info', 'amount', 'status']
		} else {
			this.tableEntities = this.mobileTableEntities
			this.displayColumns = ['info', 'amount']
		}
	}
}
