import { Component, Input, ViewChild } from '@angular/core'
import FileUpload from '@ui/FileUpload'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
	FileUploadDialogComponent,
	FileUploaded,
} from '@ui/Dialogs/FileUpload/FileUploadDialog.Component'
import { ThemePalette } from '@angular/material/core'

export type FileSelected = (file: FileUpload) => FileUpload

@Component({
	selector: 'file-uploader',
	templateUrl: './FileUploader.Component.html',
})
export class FileUploaderComponent {
	@Input() public buttonText: string

	@Input() public buttonColor: ThemePalette = 'primary'

	@Input() public showIcon: boolean

	@Input() private readonly fileSelected: FileSelected

	@Input() private readonly fileUploaded: FileUploaded

	@ViewChild('fileUploader', { static: false }) private readonly fileUploader

	private fileUploadDialogComponentMatDialogRef: MatDialogRef<FileUploadDialogComponent>

	constructor(private readonly dialog: MatDialog) {}

	public selectFile = () => {
		this.fileUploader.nativeElement.click()
	}

	public handleFileSelected = () => {
		const fileUpload = this.fileSelected(
			new FileUpload(this.fileUploader.nativeElement.files[0]),
		)

		this.fileUploadDialogComponentMatDialogRef = this.dialog.open(
			FileUploadDialogComponent,
		)

		this.fileUploadDialogComponentMatDialogRef.componentInstance.fileUpload =
			fileUpload

		this.fileUploadDialogComponentMatDialogRef.componentInstance.fileUploaded =
			this.fileUploaded

		this.fileUploadDialogComponentMatDialogRef.afterClosed().subscribe(() => {
			this.fileUploader.nativeElement.value = ''
		})
	}
}
