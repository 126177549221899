import { Component } from '@angular/core'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { PermissionsService } from '@account/Services/PermissionsService'
import { NavigationEnd, Router } from '@angular/router'
import { AccountService } from '@account/Services/AccountService'
import { CartService } from '@account/Services/CartService'

@Component({
	selector: 'nav-component',
	templateUrl: './Nav.Component.html',
})
export class NavComponent {
	public activeRoute: string

	constructor(
		public systemConfigService: SystemConfigService,
		public permissionsService: PermissionsService,
		public cartService: CartService,
		private readonly router: Router,
		private readonly accountService: AccountService,
	) {
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const split = event.url.split('/')
				this.activeRoute = split[1]
			}
		})
	}

	public logout = async (): Promise<void> => {
		window.localStorage.clear()

		await this.accountService.logout()

		window.location.reload()
	}
}
