import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { SystemConfigService } from '@account/Services/SystemConfigService'
import { Location } from '@angular/common'

@Injectable()
export class OrderGuard implements CanActivate {
	constructor(
		public systemConfigService: SystemConfigService,
		public location: Location,
	) {}

	public canActivate(): boolean {
		if (!this.systemConfigService.config.ordersEnabled) {
			this.location.back()

			return false
		}

		return true
	}
}
