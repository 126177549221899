<account-page title="Entries">
	<div class="flex-filters">
		<div class="flex-filter-item">
			<select-component
				placeholder="Year"
				[items]="years"
				[value]="starts"
				(valueChange)="chooseYear($event)"
			></select-component>
		</div>
	</div>
	<entries-table
		[orderItemService]="orderItemService"
		[setPaginator]="setOrderItemPaginator"
		[items]="orderItems"
		[includeCancelled]="true"
		[handleCancelFilter]="filterCancelledEntries"
	></entries-table>
</account-page>
