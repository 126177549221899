import { Component, Input, OnChanges } from '@angular/core'
import ListItem from '@ui/ListItem'

export interface ListItemAction {
	actionText: string
	actionIconClass: string
	actionColor: 'warn' | 'primary'
	userCanPerformAction: boolean
	action(item: ListItem): void | Promise<void>
	hidden?(item: ListItem): boolean
}

@Component({
	selector: 'list-item-action',
	templateUrl: 'ListItemAction.Component.html',
})
export class ListItemActionComponent implements OnChanges {
	@Input() public item: ListItem

	@Input() public itemAction: ListItemAction

	public hidden = false

	public ngOnChanges(): void {
		if (this.itemAction.hidden) {
			this.hidden = this.itemAction.hidden(this.item)
		}
	}
}
