<div>
	<mat-dialog-content>
		You need to confirm your password to perform this action.
		Once confirmed please retry the action.
		<br /><br />
		<form [formGroup]="form">
			<text-input [form]="form" placeHolder="Password" type="password" formControlName="password" [error]="formErrors.password"></text-input>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions [ngStyle]="{ display: 'block' }">
		<div [ngStyle]="{ float: 'right' }">
			<button mat-button color="warn" (click)="closeDialog()">Cancel</button>
			<button mat-button color="primary" (click)="confirmPassword()">Confirm Password</button>
		</div>
	</mat-dialog-actions>
</div>
