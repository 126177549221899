<account-page *ngIf="category" [title]="category.name" [subtitle]="category.description">
	<div>
		<vip-paginator
			[paginator]="classService.paginator"
			[requesting]="classService.loading"
			(pageChanged)="setPaginator($event)"
		></vip-paginator>
	</div>
	<div>
		<div>
			<div class="filters flex-filters">
				<div class="flex-filter-item">
					<text-filter
						prefixIcon="search"
						placeHolder="Class"
						[text]="classService.filterable.name"
						(textChange)="filterName($event)"
					></text-filter>
				</div>
				<div class="flex-filter-item">
					<text-filter
						prefixIcon="search"
						placeHolder="Class Number"
						[text]="classService.filterable.class_number"
						(textChange)="filterNumber($event)"
					></text-filter>
				</div>
			</div>
		</div>
	</div>
	<div>
		<classes-table [classes]="classes"></classes-table>
	</div>
</account-page>
