import { Component, Input, OnChanges } from '@angular/core'
import { FileSelected } from '@ui/Components/FileUploader/FileUploader.Component'
import FileUpload from '@ui/FileUpload'
import { ThemePalette } from '@angular/material/core'

export interface ListUpload {
	fileSelected: FileSelected
	fileUploaded?(file: FileUpload): void
}

export interface ListAction {
	actionText: string
	actionColor: ThemePalette
	type?: 'upload'
	upload?: ListUpload
	action?(): void | Promise<void>
	hidden?(): boolean
}

@Component({
	selector: 'list-action',
	templateUrl: 'ListAction.Component.html',
})
export class ListActionComponent implements OnChanges {
	@Input() public action: ListAction

	public hidden = false

	public showUploader = false

	public ngOnChanges(): void {
		if (this.action.hidden) {
			this.hidden = this.action.hidden()
		}

		this.showUploader = this.action.type === 'upload'
	}

	public handleAction = () => {
		if (this.action.action) {
			this.action.action()
		}
	}
}
