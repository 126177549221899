import { Injectable } from '@angular/core'
import BaseService from '@ui/Services/BaseService'
import { HttpClient } from '@angular/common/http'
import { PaginationService } from '@ui/Services/PaginationService'
import Cart from '@account/Cart'
import Order, { Payment } from '@account/Order'
import { AccountService } from '@account/Services/AccountService'
import OrderItem from '@account/OrderItem'
import { createOrder } from '@account/utils'
import { OrderItemService } from '@account/Services/OrderItemService'
import { AddressService } from '@account/Services/AddressService'

@Injectable()
export class OrderService extends BaseService<Order> {
	public filterable: Order

	protected endpoint = '/api/account/orders'

	constructor(
		protected httpClient: HttpClient,
		protected paginationService: PaginationService,
		private readonly accountService: AccountService,
		private readonly orderItemService: OrderItemService,
		private readonly addressService: AddressService,
	) {
		super(httpClient, paginationService)

		this.filterable = createOrder()
	}

	public find = async (id: number): Promise<Order> => {
		const response = await this.get({ url: `/api/account/orders/${id}` })

		return this.mapToObject(response)
	}

	public create = async (
		cart: Cart,
		token: string,
		quickPay: boolean = undefined,
		saveCard = false,
	): Promise<Order> => {
		const response = await this.post('/api/account/orders', {
			order: {
				cart,
			},
			token,
			quickPay,
			saveCard,
		})

		return this.mapToObject(response)
	}

	public mapToObject = (json): Order => {
		let items: OrderItem[] = []

		if (json.items) {
			items = json.items.map((jsonItem): OrderItem => {
				return this.orderItemService.mapToObject(jsonItem)
			})
		}

		let payments: Payment[] = []

		if (json.payments) {
			payments = json.payments.map(
				(jsonPayment): Payment => ({
					card_brand: jsonPayment.card_brand,
					card_last4: jsonPayment.card_last4,
					card_expiry: jsonPayment.card_expiry,
					amount: jsonPayment.amount,
					amount_refunded: jsonPayment.amount_refunded,
					failure_reason: jsonPayment.failure_reason,
				}),
			)
		}

		return new Order(
			json.id,
			json.total,
			json.account ? this.accountService.mapToObject(json.account) : undefined,
			items,
			json.client_secret,
			payments,
			json.created_at,
			json.status,
			json.billing_address
				? this.addressService.mapToObject(json.billing_address)
				: undefined,
			json.vat,
			json.parent_id,
		)
	}
}
