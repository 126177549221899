<card-component>
	<card-title [title]="title" [image]="image"></card-title>
	<card-content>
		<div [innerHTML]="description" class="item-description"></div>
	</card-content>
	<card-actions>
		<div>
			<list-item-action
				*ngFor="let action of actions; trackBy: actionTracker;"
				[item]="item"
				[itemAction]="action"
			></list-item-action>
		</div>
		<div [ngStyle]="{ float: 'right' }" *ngIf="deleteSelection">
			<table-cell-checkbox
				[instance]="item"
				[selection]="deleteSelection"
				(toggle)="handleToggle($event)"
			></table-cell-checkbox>
		</div>
	</card-actions>
</card-component>
