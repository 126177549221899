import { AudioPlayable } from '@ui/AudioPlayable'

export default class Upload implements AudioPlayable {
	constructor(
		public id: number,
		public mime_type: string,
		public remote_path: string,
		public remote_service: string,
		public entity_type: string,
		public entity_id: number,
		public public_url: string,
	) {}

	public getMimeType = (): string => {
		return this.mime_type
	}

	public getPublicUrl = (): string => {
		return this.public_url
	}
}
