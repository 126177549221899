import { Component } from '@angular/core'
import { CartService } from '@account/Services/CartService'
import Address from '@account/Address'
import HttpError from '@ui/HttpError'

@Component({
	selector: 'cart-checkout',
	templateUrl: './Checkout.Component.html',
	styleUrls: ['./Checkout.Component.css'],
})
export class CheckoutComponent {
	public step = 1

	constructor(public cartService: CartService) {}

	public assignBillingAddress = async (event: Address) => {
		try {
			await this.cartService.associateBillingAddress(event)

			this.step = 2
		} catch (error) {
			if (!(error instanceof HttpError)) {
				throw error
			}
		}
	}

	public goToStep = (step: number) => (this.step = step)
}
