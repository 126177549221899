import { Routes, RouterModule } from '@angular/router'
import { IndexComponent } from '@account/Components/Index/IndexComponent'
import { PageNotFoundComponent } from '@account/Components/PageNotFound'
import { RegisterComponent } from '@account/Components/Auth/Register/Register.Component'
import { LoginComponent } from '@account/Components/Auth/Login/Login.Component'
import { ResetPasswordComponent } from '@account/Components/Auth/ResetPassword/ResetPassword.Component'
import { LoggedOutGuard } from '@account/Services/Guards/LoggedOutGuard'
import { SubRouterComponent } from '@account/Components/SubRouter.Component'
import { LoggedInGuard } from '@account/Services/Guards/LoggedInGuard'
import { DashboardComponent } from '@account/Components/Auth/Dashboard/Dashboard.Component'
import { ClassesComponent } from '@account/Components/Classes/Classes.Component'
import { ClassComponent } from '@account/Components/Classes/Class/Class.Component'
import { CategoriesComponent } from '@account/Components/Categories/Categories.Component'
import { CategoryComponent } from '@account/Components/Categories/Category/Category.Component'
import { CartComponent } from '@account/Components/Cart/Cart.Component'
import { CheckoutComponent } from '@account/Components/Checkout/Checkout.Component'
import { CheckoutGuard } from '@account/Services/Guards/CheckoutGuard'
import { PerformersComponent } from '@account/Components/Performers/Performers.Component'
import { PerformerComponent } from '@account/Components/Performers/Performer/Performer.Component'
import { OrderComponent } from '@account/Components/Orders/Order/Order.Component'
import { OrdersComponent } from '@account/Components/Orders/Orders.Component'
import { ForgottenPasswordComponent } from '@account/Components/Auth/ForgottenPassword/ForgottenPassword.Component'
import { EntriesComponent } from '@account/Components/Entries/Entries.Component'
import { CartGuard } from '@account/Services/Guards/CartGuard'
import { OrderGuard } from '@account/Services/Guards/OrderGuard'
import { EntryComponent } from '@account/Components/Entries/Entry/Entry.Component'
import { WaitingListsComponent } from '@account/Components/WaitingLists/WaitingLists.Component'
import { WaitingListGuard } from '@account/Services/Guards/WaitingListGuard'
import { PaymentMethodsComponent } from '@account/Components/PaymentMethods/PaymentMethods.Component'
import { AddressesComponent } from '@account/Components/Addresses/Addresses.Component'
import { SecurityComponent } from '@account/Components/Security/Security.Component'

const appRoutes: Routes = [
	{
		path: '',
		component: IndexComponent,
	},
	{
		path: 'classes',
		component: SubRouterComponent,
		children: [
			{
				path: '',
				component: ClassesComponent,
			},
			{
				path: ':id',
				component: ClassComponent,
			},
		],
	},
	{
		path: 'categories',
		component: SubRouterComponent,
		children: [
			{
				path: '',
				component: CategoriesComponent,
			},
			{
				path: ':id',
				component: CategoryComponent,
			},
		],
	},
	{
		path: 'cart',
		component: CartComponent,
		canActivate: [LoggedInGuard, CartGuard],
	},
	{
		path: 'checkout',
		component: CheckoutComponent,
		canActivate: [LoggedInGuard, CheckoutGuard],
	},
	{
		path: 'entries',
		component: SubRouterComponent,
		canActivate: [LoggedInGuard],
		children: [
			{
				path: '',
				component: EntriesComponent,
			},
			{
				path: ':id',
				component: EntryComponent,
			},
		],
	},
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LoggedOutGuard],
	},
	{
		path: 'register',
		component: RegisterComponent,
		canActivate: [LoggedOutGuard],
	},
	{
		path: 'reset-password/:token',
		component: ResetPasswordComponent,
		canActivate: [LoggedOutGuard],
	},
	{
		path: 'performers',
		component: SubRouterComponent,
		canActivate: [LoggedInGuard],
		children: [
			{
				path: '',
				component: PerformersComponent,
			},
			{
				path: ':id',
				component: PerformerComponent,
			},
		],
	},
	{
		path: 'orders',
		component: SubRouterComponent,
		canActivate: [LoggedInGuard, OrderGuard],
		children: [
			{
				path: '',
				component: OrdersComponent,
			},
			{
				path: ':id',
				component: OrderComponent,
			},
		],
	},
	{
		path: 'forgotten-password',
		component: ForgottenPasswordComponent,
		canActivate: [LoggedOutGuard],
	},
	{
		path: 'my-account',
		component: SubRouterComponent,
		canActivate: [LoggedInGuard],
		children: [
			{
				path: '',
				component: DashboardComponent,
			},
			{
				path: 'waiting-lists',
				component: WaitingListsComponent,
				canActivate: [LoggedInGuard, WaitingListGuard],
			},
			{
				path: 'security',
				component: SecurityComponent,
				canActivate: [LoggedInGuard],
			},
		],
	},
	{
		path: 'payment-methods',
		component: PaymentMethodsComponent,
		canActivate: [LoggedInGuard],
	},
	{
		path: 'addresses',
		component: AddressesComponent,
		canActivate: [LoggedInGuard],
	},
	{
		path: '**',
		component: PageNotFoundComponent,
	},
]

export const appRoutingProviders: any[] = []

export const routing = RouterModule.forRoot(appRoutes)
